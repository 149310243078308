import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { useEffect, useState } from 'react'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { getAllGames, saveNewGame, updateOrCreateDocument } from '../../firebase'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { useSnackbar } from 'notistack'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router'
import SkeletonLoader from '../../components/atoms/SkeletionLoader/SkeletonLoader'
import GameHelper from '../../helpers/GameHelper/GameHelper'

const AddGamePage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const style = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
      padding: BreakpointsHelper.isMobile() ? '15px' : '15px 50px',
    }
  }
  const history = useHistory()
  const [formData, setFormData] = useState({ elements: [] })
  const [updateGamesLists, setUpdateGamesLists] = useState(false)
  const [dataToPDF, setDataToPDF] = useState(null)
  const [countGames, setCountGames] = useState(null)
  const handleYourListGames = () => {
    LocalStorageHelper.set('yourGames', dataToPDF)
    history.push('/listYourGames')
  }
  const [formDataDelete, setFormDataDelete] = useState({ elements: [] })
  const [formDataToAdd, setFormDataToAdd] = useState({ elements: [] })
  const listToAdd = (e) => {
    updateOrCreateDocument('gamesToAdd', null, e).then(() => {
      enqueueSnackbar('Dodano prośbę o dodanie nowej gry.', { variant: 'success' })
    })
  }
  const deleteGame = async (e) => {
    const games = await getAllGames()
    const name = e?.name
    if (name?.length > 0) {
      let tempWho = []
      const tempDeleteWho = []
      let tempGame = {}
      games.map(game => {
        if (game?.name === name) {
          tempWho = game?.who
          tempGame = game
        }
        return true
      })
      const exist = !!tempWho.find(el => el === LocalStorageHelper.get('user')?.name)
      if (exist) {
        tempWho.map(el => {
          if (el !== LocalStorageHelper.get('user').name) {
            tempDeleteWho.push(el)
          }
          return true
        })
      }
      tempGame.who = tempDeleteWho
      saveNewGame(tempGame, tempGame?.id).then((res) => {
        if (res) {
          enqueueSnackbar(`Usunięcie gry: ${tempGame?.name}`, { variant: 'success' })
          setTimeout(() => setUpdateGamesLists(!updateGamesLists), 300)
        } else enqueueSnackbar('Problem z usunięciem gry.', { variant: 'error' })
      })
    }
  }
    const addGame = async (e) => await GameHelper.addGameToYourStack(e, enqueueSnackbar, setUpdateGamesLists, updateGamesLists)
    const generateGames = async () => {
      const tempGames = await getAllGames()
      setCountGames(tempGames?.length)
      const tempSetGames = []
      const gamesPossibleToAdd = []
      tempGames?.forEach(el => {
        if (el?.name) {
          const persons = el?.who
            let yourPersonExist = false
            persons?.forEach(person => {
              if (LocalStorageHelper.get('user').name.trim() === 'Planszówki Koszalin') {
                if (person.trim() === LocalStorageHelper.get('user').name.trim() || person.trim() === 'Jan Szymański') {
                  yourPersonExist = true
                }
              } else {
                if (person.trim() === LocalStorageHelper.get('user').name.trim()) {
                  yourPersonExist = true
                }
              }
            })
            if (!persons || persons?.length === 0 || !yourPersonExist) {
              gamesPossibleToAdd.push({ value: el?.id, label: el?.name })
            } else {
              tempSetGames.push({ value: el?.name, label: el?.name })
            }
          }
      })
      setDataToPDF(tempSetGames)
      setFormData({
        elements: [
          {
            name: 'name',
            label: 'Nazwa gry',
            type: 'autocomplete',
            options: gamesPossibleToAdd,
            validationString: 'string',
            required: false,
          },
          {
            type: 'button',
            value: 'Dodaj grę'
          },
        ]
      })
      setFormDataDelete({
        elements: [
          {
            name: 'name',
            label: 'Nazwa gry',
            type: 'autocomplete',
            options: tempSetGames,
            validationString: 'string',
            required: false,
          },
          {
            type: 'button',
            value: 'Usuń grę'
          },
        ]
      })
      setFormDataToAdd({
        elements: [
          {
            name: 'name',
            label: 'Nazwa gry',
            type: 'text',
            validationString: 'string',
            required: true,
          },
          {
            type: 'button',
            value: 'Dodaj grę'
          },
        ]
      })
    }
    useEffect(() => {
      generateGames()
    }, [updateGamesLists])
    return (
      <div style={style.root}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '22px',
                  fontWeight: '600',
                }}
              >
                Liczba dostępnych gier {dataToPDF?.length} na {countGames}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
          <Button
            variant="contained"
            onClick={() => handleYourListGames()}
          >
            Wydrukuj listę gier
          </Button>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Typography>
                Dodaj grę do swojego profilu
              </Typography>
              <SkeletonLoader variable={formData}>
                <FormGenerator data={formData} submit={(e) => addGame(e)} />
              </SkeletonLoader>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Typography>
                Usuń grę ze swojego profilu
              </Typography>
              <SkeletonLoader variable={formDataDelete}>
                <FormGenerator data={formDataDelete} submit={(e) => deleteGame(e)} />
              </SkeletonLoader>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Typography>
                Brakuje gry na liście, zgłoś grę do dodania
              </Typography>
              <SkeletonLoader variable={formDataToAdd}>
                <FormGenerator data={formDataToAdd} submit={(e) => listToAdd(e)} />
              </SkeletonLoader>
            </Box>
          </Grid>
        </Grid>
      </div>
    )
  }

export default AddGamePage
