import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import {
  deletePersonFromEvent,
  deletePersonFromTournament,
  deleteUserInEvent,
  deleteUserInTournament
} from '../../../firebase'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import GoogleService from '../../../services/GoogleService/GoogleService'
import Gamers from '../../atoms/Gamers/Gamers'
import {
  CasinoOutlined,
  EditOutlined,
  LockOutlined,
  MeetingRoomOutlined,
  PeopleOutlined
} from '@mui/icons-material'
import GoogleCalendarIcon from '../../../assets/images/icons/calendar.png'
import GoogleMapsIcon from '../../../assets/images/icons/maps.png'
import Dialog from '../../atoms/Dialog/Dialog'
import QuestionnaireGames from '../QuestionnaireGames/QuestionnaireGames'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import ArrayHelper from '../../../helpers/ArrayHelper/ArrayHelper'
import EventHelper from '../../../helpers/EventHelper/EventHelper'
import QRCode from 'react-qr-code'

const ShowEvent = (props) => {
  const { events, isLoading, handleDelete, handleEdit, id, games, setIsLoading, tournament } = props
  const t = LocalStorageHelper.get('translation')
  const [data, setData] = useState([])
  const [openGames, setOpenGames] = useState(false)
  const [openDataUsers, setOpenDataUsers] = useState(false)
  const [users, setUsers] = useState(false)
  const handleDeletePerson = (person) => {
    setOpenDataUsers(false)
    if (data?.tournament) {
      deletePersonFromTournament(id, person?.name).then(() => {
        setIsLoading(true)
        const tempData = data
        tempData.users = ArrayHelper.deleteObjectFromArrayByValue(tempData.users, 'name', person.name)
        const actualSeats = tempData.seat
        tempData.seat = actualSeats - 1
        setData(tempData)
        setIsLoading(false)
      })
    } else {
      deletePersonFromEvent(id, person?.name).then(() => {
        setIsLoading(true)
        const tempData = data
        tempData.users = ArrayHelper.deleteObjectFromArrayByValue(tempData.users, 'name', person.name)
        const actualSeats = tempData.seat
        tempData.seat = actualSeats - 1
        setData(tempData)
        setIsLoading(false)
      })
    }
  }
  const handleLocationEvent = async () => window.open(`https://maps.google.com/?q=${events.data.type === 'home' ? events.data.address + ', Koszalin' : 'Koszalińska Biblioteka Publiczna, Plac Polonii 1, Koszalin'}`, '_blank')
  const style = {
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
    },
    basicInformation: {
      background: '#121a44',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px 30px',
      borderRadius: '20px',
      marginBottom: '20px',
      color: 'white',
    },
    previewImage: {
      width: '100%',
    },
    text: {
      marginLeft: '10px',
    },
    title: {
      fontSize: BreakpointsHelper.isMobile() ? '36px' : '48px',
      fontWeight: '900',
      lineHeight: '42px',
      textTransform: 'uppercase',
      marginBottom: '20px',
    },
    organizer: {
      fontSize: BreakpointsHelper.isMobile() ? '20px' : '25px',
      fontWeight: '600',
      lineHeight: '30px',
      textTransform: 'uppercase',
      marginBottom: '35px',
    },
    chip: {
      padding: '5px 15px',
      borderRadius: '15px',
      fontSize: BreakpointsHelper.isMobile() ? '18px' : '22px',
      fontWeight: '400',
      background: '#2a3270',
    },
    description: {
      fontSize: '20px',
      fontWeight: '400',
      wordWrap: 'break-word',
      background: 'transparent !important',
    },
    icons: {
      height: '25px',
    },
  }
  const addToCalendar = () => {
    return GoogleService.setEventInCalendar(
      events?.data?.type === 'library'
        ? 'Bibliotekogranie'
        : 'Spotkanie planszowkowe',
      events?.data?.type === 'library'
        ? 'Koszalińska Biblioteka Publiczna, Plac Polonii 1, Koszalin'
        : events?.data?.address,
      new Date(events?.data?.date.split('.')[2], events?.data?.date.split('.')[1] - 1, events?.data?.date.split('.')[0], events?.data?.hours?.start?.split(':')[0], events?.data?.hours?.start?.split(':')[1]), new Date(events?.data?.date.split('.')[2], events?.data?.date.split('.')[1] - 1, events?.data?.date.split('.')[0], events?.data?.hours?.end?.split(':')[0], events?.data?.hours?.end?.split(':')[1]))
  }
  const getInfoOrganizer = () => {
    let info = null
    const users = data?.users
    users?.map(user => {
      if (user?.email === data?.whoCreated) info = user
      return true
    })
    return (
      <>
        {t.globals.components.ShowEvent.organizer}:
        <br/>
        {info?.name} | {info?.phone}
      </>
    )
  }
  const renderDialogUsersData = () => {
    return (
      <>
        {
          users?.map((el, index) => {
            return (
              <Box
                key={index}
                sx={{ borderBottom: '1px solid black', padding: '10px 0' }}
              >
                <Grid container spacing={4}>
                  <Grid item md={3} xs={12}>
                    <Typography sx={{ lineHeight: '32px', fontSize: '18px' }}>
                      {el?.name || t.globals.components.ShowEvent.noData}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography sx={{ lineHeight: '32px', fontSize: '18px' }}>
                      {el?.email || t.globals.components.ShowEvent.noData}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography sx={{ lineHeight: '32px', fontSize: '18px' }}>
                      {
                        el?.phone?.length > 0
                        ? (
                          <a href={`tel:${el?.phone?.replace(' ', '')?.trim()}`}>
                            {el?.phone}
                          </a>
                        )
                        : t.globals.components.ShowEvent.noData
                      }
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => handleDeletePerson(el)}
                    >
                      {t.globals.components.ShowEvent.deleteFromEvent}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )
          })
        }
      </>
    )
  }
  const handleUsersData = () => {
    setOpenDataUsers(true)
    setUsers(data?.users)
  }
  useEffect(() => {
    if (events) {
      setData(events?.data)
    }
  }, [events])
  return (
    <>
      {
        isLoading
          ? (
            <div style={style.loader}>
              <CircularProgress/>
            </div>
          )
          : (
            <>
              <div style={style.basicInformation}>
                <Grid container spacing={4}>
                  <Grid item md={3}>
                    <img src={data?.background} alt="preveiw image" style={style.previewImage} />
                  </Grid>
                  <Grid item md={9}>
                    <div style={style.title}>
                      {data?.name}
                    </div>
                    <div style={style.organizer}>{getInfoOrganizer()}</div>
                    <Grid container spacing={4}>
                      <Grid item>
                        <span style={style.chip}><b>{t.globals.components.ShowEvent.data}: </b>{`${data?.date}, (${data?.day})`}</span>
                      </Grid>
                      <Grid item>
                        <span style={style.chip}><b>{t.globals.components.ShowEvent.time}: </b>{`od ${data?.hours?.start} do ${data?.hours?.end}`}</span>
                      </Grid>
                      <Grid item>
                        <span style={style.chip}><b>{t.globals.components.ShowEvent.address}: </b>{`${data?.type === 'home' ? data?.address : 'Koszalińska Biblioteka Publiczna, Plac Polonii 1, Koszalin'}`}</span>
                      </Grid>
                      <Grid item>
                        <span style={style.chip}><b>{t.globals.components.ShowEvent.seats}: </b>{`${data?.seat} / ${data?.maxSeat}`}</span>
                      </Grid>
                      <Grid item>
                        <span style={style.chip}><b>{t.globals.components.ShowEvent.child}: </b>{`${data?.child ? t.globals.components.ShowEvent.yes : t.globals.components.ShowEvent.no}`}</span>
                      </Grid>
                    </Grid>
                    <Box sx={{ marginTop: '30px' }}>
                      <Grid container spacing={4}>
                        <Grid item md={4} xs={12}>
                          <Button variant="contained" onClick={() => setOpenGames(true)}>
                            <CasinoOutlined />
                            <span style={style.text}>{t.globals.components.ShowEvent.games}</span>
                          </Button>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Button variant="contained" onClick={() => handleLocationEvent()}>
                            <img src={GoogleMapsIcon} alt="google calendar" style={style.icons} />
                            <span style={style.text}>{t.globals.components.ShowEvent.goTo}</span>
                          </Button>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Button variant="contained" onClick={() => addToCalendar()}>
                            <img src={GoogleCalendarIcon} alt="google calendar" style={style.icons} />
                            <span style={style.text}>{t.globals.components.ShowEvent.calendar}</span>
                          </Button>
                        </Grid>
                        {
                          events?.data?.whoCreated === LocalStorageHelper.get('user')?.email && (
                            <Grid item md={4} xs={12}>
                              <Button variant="contained" onClick={(e) => handleEdit(e)}>
                                <EditOutlined />
                                <span style={style.text}>{t.globals.components.ShowEvent.editEvent}</span>
                              </Button>
                            </Grid>
                          )
                        }
                        <Grid item md={4} xs={12}>
                          <Button variant="contained" onClick={() => data?.tournament ? deleteUserInTournament(id) : deleteUserInEvent(id)}>
                            <MeetingRoomOutlined />
                            <span style={style.text}>{t.globals.components.ShowEvent.goOut}</span>
                          </Button>
                        </Grid>
                        {
                          events?.data?.whoCreated === LocalStorageHelper.get('user').email && (
                            <Grid item md={4} xs={12}>
                              <Button variant="contained" onClick={(e) => handleUsersData(e)}>
                                <PeopleOutlined />
                                <span style={style.text}>{t.globals.components.ShowEvent.previewDataUsers}</span>
                              </Button>
                            </Grid>
                          )
                        }
                        {
                          events?.data?.whoCreated === LocalStorageHelper.get('user').email && (
                            <Grid item md={4} xs={12}>
                              <Button variant="contained" onClick={(e) => handleDelete(e)}>
                                <LockOutlined />
                                <span style={style.text}>{t.globals.components.ShowEvent.delete}</span>
                              </Button>
                            </Grid>
                          )
                        }
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </div>
              {
                tournament && (
                  <Box sx={{ marginBottom: '40px', display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      sx={{
                        marginTop: '40px',
                        fontSize: BreakpointsHelper.isMobile() ? '30px' : '38px',
                        lineHeight: '35px',
                        fontWeight: '900',
                        marginBottom: '20px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t.globals.components.ShowEvent.ticket}
                    </Typography>
                    <QRCode value={window.location.pathname.replace('previewEvent', 'beOnTournament') + '/' + LocalStorageHelper.get('user').email} />
                    <Button
                      variant="contained"
                      sx={{ maxWidth: '200px', marginBottom: '20px', marginTop: '20px' }}
                      onClick={() => window.open(`/showTicket${window.location.pathname}`, '_self')}
                    >
                      Wydrukuj kod QR
                    </Button>
                  </Box>
                )
              }
              <Box sx={{ marginBottom: '40px' }}>
                <Typography
                  sx={{
                    marginTop: '40px',
                    fontSize: BreakpointsHelper.isMobile() ? '30px' : '38px',
                    lineHeight: '35px',
                    fontWeight: '900',
                    textTransform: 'uppercase',
                  }}
                >
                  {t.globals.components.ShowEvent.description}
                </Typography>
                {
                  events?.data?.description && (
                    <div className="editorText" style={style.description} dangerouslySetInnerHTML={{ __html: events.data.description }} />
                  )
                }
              </Box>
              <Box sx={{ marginBottom: '40px' }}>
                <Box
                  sx={{
                    marginTop: '40px',
                    fontSize: BreakpointsHelper.isMobile() ? '30px' : '38px',
                    lineHeight: '35px',
                    fontWeight: '900',
                    textTransform: 'uppercase',
                  }}
                >
                  {t.globals.components.ShowEvent.gamers}
                  <Gamers users={data?.users} />
                </Box>
              </Box>
              {
                openGames && (
                  <Dialog open={openGames} title={t.globals.components.ShowEvent.showGames} content={<QuestionnaireGames noOpen callback={() => setOpenGames(false)} games={games} />} onClose={() => setOpenGames(false)} />
                )
              }
              {
                openDataUsers && (
                  <Dialog open={openDataUsers} title={t.globals.components.ShowEvent.previewDataUsers} content={data && renderDialogUsersData()} onClose={() => setOpenDataUsers(false)} />
                )
              }
            </>
          )
      }
    </>
  )
}

export default ShowEvent
