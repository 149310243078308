import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { setAllPostVisible } from '../../../firebase'

const TabPanel = (props) => {
  const { children, value, index, noPadding, ...other } = props
  return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{
				  paddingTop: 3,
				  paddingBottom: 3,
				  paddingLeft: 0,
				  paddingRight: 0,
				}}>
					{children}
				</Box>
			)}
		</div>
  )
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const BasicTabs = ({ data, setValues }) => {
	const [tempData, setTempData] = useState(data)
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
		if (newValue === 1) {
			const id = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1].replace('%20', ' ')
			if (id?.search('.') > 0) {
				setAllPostVisible(id).then(() => {
					if (tempData?.length > 0) {
						tempData[1]?.checkCallBack().then(() => true)
					}
				})
			}
		}
		if (setValues) setValues(newValue)
		setValue(newValue)
  }
	const [isVisible] = useState([])
	const checkData = async (el, index) => {
		const data = el?.checkData
		if (data) {
			let string = ''
			await data?.then(res => {
				if (res) {
					string = '*'
				}
			})
			isVisible[index] = string
		} else {
			isVisible[index] = ''
		}
	}
	const handleLabel = (el, index) => {
		if (isVisible[index] === '*') {
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{el?.name}
					<PriorityHighIcon sx={{ color: 'orange' }}/>
				</div>
			)
		}
		return el?.name
	}
	useEffect(() => {
		if (data) {
			setTempData(data)
		}
	}, [data])
  return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					scrollButtons="auto"
					variant="scrollable"
					aria-label="scrollable auto tabs example"
				>
					{ tempData?.map((el, index) => {
						checkData(el, index).then(() => true)
						return (
							<Tab
								key={index}
								label={handleLabel(el, index)}
								{...a11yProps(index)}
							/>
						)
					}) }
				</Tabs>
			</Box>
			{
				tempData?.map((el, index) => (
					<TabPanel key={index} value={value} index={index} noPadding={el.noPadding}>
						<Box>
							{el?.value() || ''}
						</Box>
					</TabPanel>
				))
			}
		</Box>
  )
}

export default BasicTabs
