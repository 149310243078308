import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import jasiek from '../../../assets/images/contact/jasiek.jpg'
import asia from '../../../assets/images/contact/asia.jpg'
import dominik from '../../../assets/images/contact/dominik.jpg'
import ania from '../../../assets/images/contact/ania.jpg'
import kamil from '../../../assets/images/contact/kamil.jpg'
import maciek from '../../../assets/images/contact/maciek.jpg'
import krzysiek from '../../../assets/images/contact/krzysiek.jpg'
import mikolaj from '../../../assets/images/contact/mikolaj.jpg'
import klaudia from '../../../assets/images/contact/klaudia.jpg'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import Carousel from '../../atoms/Carousel/Carousel'

const Contacts = () => {
  const t = LocalStorageHelper.get('translation')
  const style = {
    image: {
      margin: '0 auto 20px auto',
      width: '150px',
      height: '150px',
      maxWidth: '100%',
      objectFit: 'cover',
      borderRadius: '20px',
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    organizer: {
      fontSize: '20px',
      fontWeight: '500',
      textTransform: 'uppercase',
      position: 'relative',
      marginBottom: '10px',
      marginTop: '20px',
    },
    contactText: {
      fontSize: BreakpointsHelper.isMobile() ? '16px' : '22px'
    }
  }
  const data = [
    {
      name: 'Jan Szymański',
      image: jasiek,
      position: 'Założyciel Planszówki Koszalin',
      messenger: 'https://m.me/janszymanskisz',
    },
    // {
    //   name: 'Joanna Szymańska',
    //   image: asia,
    //   position: 'Organizator',
    //   messenger: 'https://m.me/100007697087905',
    // },
    // {
    //   name: 'Dominik Woźniak',
    //   image: dominik,
    //   position: 'Wolontariusz',
    //   messenger: 'https://m.me/dominik.wozniak.334',
    // },
    // {
    //   name: 'Anna Kurzec',
    //   image: ania,
    //   position: 'Wolontariusz',
    //   messenger: 'https://m.me/anna.kurzec',
    // },
    // {
    //   name: 'Kamil Piątkowski',
    //   image: kamil,
    //   position: 'Wolontariusz',
    //   messenger: 'https://m.me/kamil.piatkowski.14',
    // },
    // {
    //   name: 'Maciej Orlikowski',
    //   image: maciek,
    //   position: 'Wolontariusz',
    //   messenger: 'https://m.me/maciej.orlikowski.338',
    // },
    // {
    //   name: 'Krzysiek Rudnicki',
    //   image: krzysiek,
    //   position: 'Wolontariusz',
    //   messenger: 'https://m.me/maciej.orlikowski.338',
    // },
    // {
    //   name: 'Klaudia Flaum',
    //   image: klaudia,
    //   position: 'Wolontariusz',
    //   messenger: 'https://www.facebook.com/celthien',
    // },
    // {
    //   name: 'Mikołaj Flaum',
    //   image: mikolaj,
    //   position: 'Wolontariusz',
    //   messenger: 'https://www.facebook.com/profile.php?id=100003402126194',
    // },
  ]
  const generatePerson = (el) => {
    return (
      <Grid container spacing={4}>
        <Grid item>
          <img src={el.image} alt={el.name} style={style.image}/>
        </Grid>
        <Grid item>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography sx={{
              fontWeight: '600',
              fontSize: '20px'
            }}>{el.name}</Typography>
            <Typography>{el.position}</Typography>
            {
              el?.phone?.length > 0 && (
                <a href={`tel:${el.phone}`} style={{ marginBottom: BreakpointsHelper.isMobile() ? '5px' : '0' }}>
                  {t.contactPage.call}
                </a>
              )
            }
            <a href={el.messenger} target="_blank" rel="noreferrer">{t.contactPage.talk}</a>
          </Box>
        </Grid>
      </Grid>
    )
  }
  return (
    <Box sx={{ marginTop: '20px' }}>
    <Box sx={{ marginBottom: '50px' }}>
        <Typography sx={{ fontSize: BreakpointsHelper.isMobile() ? '16px' : '22px', marginBottom: '20px' }}>
          Zapraszamy do wzięcia udziału w naszych pasjonujących spotkaniach gier planszowych w Koszalinie! Czekają na Ciebie niezapomniane chwile pełne radości, strategii i emocji. Niezależnie od tego, czy jesteś nowicjuszem czy doświadczonym graczem, nasza przyjazna społeczność serdecznie Cię przywita. Dołącz do nas, aby poznać nowe gry, zawrzeć przyjaźnie i spędzić czas w fascynującym towarzystwie pasjonatów planszówek. Skontaktuj się z nami, aby dowiedzieć się więcej i już niedługo z nami zasiąść do stołu przy planszowej rozgrywce. Nie przegap tej wspaniałej okazji do przeżycia fantastycznej zabawy!
        </Typography>
        {
          BreakpointsHelper.isMobile()
            ? (
              <Carousel
                data={data}
                slide={(el) => (
                  <Box sx={style.box}>
                    {generatePerson(el)}
                  </Box>
                )}
              />
            )
            : (
              <Grid container spacing={4}>
                {
                  data?.map((el, index) => {
                    return (
                      <Grid key={index} item md={12} xs={12}>
                        <Box sx={style.box}>
                          {generatePerson(el)}
                        </Box>
                      </Grid>
                    )
                  })
                }
              </Grid>
            )
        }
        <Typography sx={style.contactText}>
          <a style={{ textDecoration: 'none' }} href="mailto:kontakt@planszowki-koszalin.pl" target="_blank" title="Napisz email" rel="noreferrer"><b>Email:</b> kontakt@planszowki-koszalin.pl</a>
        </Typography>
        <Typography sx={style.contactText}>
          <a style={{ textDecoration: 'none' }} href="tel:509609439" target="_blank" title="Zadzwoń" rel="noreferrer"><b>Telefon:</b> 509 609 439</a>
        </Typography>
        <Typography sx={style.contactText}>
          <a style={{ textDecoration: 'none' }} href="https://www.facebook.com/planszowkiKoszalin" target="_blank" title="Facebook" rel="noreferrer"><b>Facebook:</b> https://www.facebook.com/planszowkiKoszalin</a>
        </Typography>
      <Typography sx={style.contactText}>
          <a style={{ textDecoration: 'none' }} href="https://www.youtube.com/@planszowkikoszalin8836" target="_blank" title="Youtube" rel="noreferrer"><b>Youtube:</b> https://www.youtube.com/@planszowkikoszalin8836</a>
      </Typography>
        <Typography sx={style.contactText}>
          <a style={{ textDecoration: 'none' }} href="https://www.instagram.com/planszowkikoszalin2022/" target="_blank" title="Instagram" rel="noreferrer"><b>Instagram:</b> https://www.instagram.com/planszowkikoszalin2022/</a>
        </Typography>
    </Box>
    </Box>
  )
}

export default Contacts
