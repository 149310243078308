import DateHelper from '../../helpers/DateHelper/DateHelper'

export default {
  setEventInCalendar: (name = 'Spotkanie planszowkowe', location, startTime, endTime) => {
    const gapi = window.gapi
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const API_KEY = process.env.REACT_APP_API_KEY
    const DISCOVERY_DOCS = [process.env.REACT_APP_GOOGLE_DISCOVERY_DOCS]
    const SCOPES = process.env.REACT_APP_GOOGLE_SCOPES
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      gapi.client.load('calendar', 'v3', () => {})
      gapi.auth2.getAuthInstance().signIn().then(() => {
        const getEndDate = (date) => {
          const endTimeTemp = DateHelper.getFormattedDateByFormat(endTime, 'yyyy-MM-DDTHH:mm:ss.SSSZ')
          const endHourTemp = DateHelper.getFormattedDateByFormat(endTime, 'HH:mm')
          const addDay = new Date(endTime)
         addDay.setDate(addDay.getDate() + 1)
          if (endHourTemp === '00:00') return DateHelper.getFormattedDateByFormat(addDay, 'yyyy-MM-DDTHH:mm:ss.SSSZ')
          else return endTimeTemp
        }
        const event = {
          summary: name,
          location: location,
          start: {
            dateTime: `${DateHelper.getFormattedDateByFormat(startTime, 'yyyy-MM-DDTHH:mm:ss.SSSZ')}`,
          },
          end: {
            dateTime: getEndDate(),
          }
        }
        const request = gapi.client.calendar.events.insert({
          calendarId: 'primary',
          resource: event
        })
        request.execute((res) => window.open(res.htmlLink, '_blank'))
      })
    })
  }
}
