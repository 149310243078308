import Box from '@mui/material/Box'
import Product from '../../atoms/Product/Product'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'

const ShopList = ({ data }) => {
  const style = {
    root: {
      padding: '50px',
      width: 'calc(100% - 160px)',
    },
    h2: {
      fontSize: '32px',
      fontWeight: '900',
      marginBottom: '30px',
      color: '#121a44',
    },
    h2All: {
      fontSize: '32px',
      fontWeight: '900',
      marginBottom: '30px',
      color: '#121a44',
      marginTop: '60px',
    }
  }
  return (
    <Box sx={style.root}>
      <Typography variant="h2" sx={style.h2}>Nowości</Typography>
      <Grid container spacing={2}>
        {
          data?.map((el, index) => {
            if (el?.news) {
            return <Product key={index} data={el} />
          } else {
              return ''
            }
          })
        }
      </Grid>
      <Typography variant="h2" sx={style.h2All}>Wszystkie dostępne</Typography>
      <Grid container spacing={2}>
        {
          data?.map((el, index) => {
            return (
              <Product key={index} data={el} />
            )
          })
        }
      </Grid>
    </Box>
  )
}

export default ShopList
