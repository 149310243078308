import { useEffect } from 'react'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Camera from '../../components/atoms/Camera/Camera'
import UserHelper from '../../helpers/UserHelper/UserHelper'

const SuperAdminPage = () => {
  useEffect(() => {
    if (LocalStorageHelper.get('user')?.email.trim() !== 'jasieksz1992@gmail.com') {
      UserHelper.logout()
    }
  }, [])
  return (
    <Box sx={{ width: 'calc(100% - 50px)', padding: '25px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography sx={{ marginBottom: '20px' }}>Wykrywanie elementów kamery</Typography>
          <Camera />
        </Grid>
      </Grid>
    </Box>
  )
}

export default SuperAdminPage
