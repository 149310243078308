export default {
  clear: (array) => array.splice(0, array.length),
  deleteDuplicate: (array) => array.filter((item, index, array) => array.indexOf(item) === index),
  deleteObjectFromArrayByValue: (array, key, value) => array?.map(el => el[key] !== value && el),
  sortArrayBy: (array, filed) => {
    if (filed === 'id') {
      return array.sort((a, b) => {
        const convertedA = new Date(
          a[filed].split(' ')[0].replace(',', '').split('.')[2],
          a[filed].split(' ')[0].replace(',', '').split('.')[1],
          a[filed].split(' ')[0].replace(',', '').split('.')[0],
          a[filed].split(' ')[1].split(':')[0],
          a[filed].split(' ')[1].split(':')[1],
        )
        const convertedB = new Date(
          b[filed].split(' ')[0].replace(',', '').split('.')[2],
          b[filed].split(' ')[0].replace(',', '').split('.')[1],
          b[filed].split(' ')[0].replace(',', '').split('.')[0],
          b[filed].split(' ')[1].split(':')[0],
          b[filed].split(' ')[1].split(':')[1],
        )
        return convertedB - convertedA
      })
    }
    return null
  }
}
