import React from 'react'
import { Grid } from '@mui/material'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const Row = ({ left, right, revertMobile, background, id }) => {
  const style = {
    root: {
      padding: BreakpointsHelper.isMobile() ? '30px 15px' : '100px 50px',
	    display: 'flex',
      background: background && 'rgb(247, 249, 252)',
}
  }
  return (
		<Grid id={id} container style={style.root} spacing={8}>
			{
				BreakpointsHelper.isMobile()
				  ? revertMobile
				    ? (
					<>
						<Grid item md={6} xs={12}>
							{right}
						</Grid>
						<Grid item md={6} xs={12} sx={{ paddingTop: BreakpointsHelper.isMobile() && ' 30px !important' }}>
							{left}
						</Grid>
					</>
				      )
				    : (
					<>
						<Grid item md={6} xs={12}>
							{left}
						</Grid>
						<Grid item md={6} xs={12} sx={{ paddingTop: BreakpointsHelper.isMobile() && ' 30px !important' }}>
							{right}
						</Grid>
					</>
				      )
				  : (
					<>
						<Grid item md={6}>
							{left}
						</Grid>
						<Grid item md={6}>
							{right}
						</Grid>
					</>
				    )
			}
		</Grid>
  )
}

export default Row
