import StringHelper from '../StringHelper/StringHelper'
import AddressHelper from './AddressHelper'

export default {
  handleRemoveNumbersAndCharts: (address, street) => {
    if (street) return street
    else {
      const adr = StringHelper.toOnlyLetters(address).replace('/', '')
      if (adr.search(',') > 0) return adr.split(',')[0]
      else return adr
    }
  },
  shortVersion: (type, address, street) => {
    if (type === 'library') {
      return 'Koszalińska Biblioteka Publiczna'
    } else if (address.toLowerCase() === 'juliana fałata 11g') {
      return 'Restauracja Green Sharing Style'
    } else {
      return AddressHelper.handleRemoveNumbersAndCharts(StringHelper.toCapitalize(address.toLowerCase().replace('ul.', '').replace('.', '').trim()), street)
    }
  }
}
