import React from 'react'
import defaultImage from '../../../assets/images/no-data.jpg'
import kbp from '../../../assets/images/logo_kbp.png'
import mum from '../../../assets/images/partners/mwmk.png'
import { GradeOutlined } from '@mui/icons-material'

const GameBox = ({ image, name, who, onClick, noOpen, publishingHouse, line }) => {
  const style = {
    root: {
      display: 'flex',
      flexDirection: line ? 'row' : 'column',
      position: 'relative',
      cursor: noOpen ? 'auto' : 'pointer',
      border: '2px solid #20225e',
    },
    rootChild: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      cursor: 'pointer',
    },
    img: {
      objectFit: 'contain',
      width: line ? '300px' : '80%',
      height: '180px',
      margin: '0 auto',
      padding: '10px',
    },
    data: {
      background: '#20225e',
      padding: '15px',
      position: 'relative',
      display: 'flex',
      flex: line && 1,
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    title: {
      color: 'white',
      fontSize: 17,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    info: {
      fontSize: 18,
      fontWeight: 700,
      color: 'white',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
    infoSmall: {
      fontSize: 16,
      marginTop: 3,
      marginBottom: 5,
      fontWeight: 700,
      color: 'white',
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
    infoCat: {
      fontSize: 15,
      fontWeight: 600,
      color: '#edac2d',
      lineHeight: '15px',
      textTransform: 'uppercase',
    },
    companies: {
      position: 'absolute',
      top: 10,
      right: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    addLike: {
      position: 'absolute',
      top: 10,
      left: 10,
      color: 'rgb(18, 26, 68)',
      background: 'rgba(255,255,255, 0.95)',
      padding: '10px',
      borderRadius: '50%',
      zIndex: 999,
    },
    logoCompany: {
      width: 30,
      background: 'rgba(255,255,255, 0.95)',
      padding: '10px',
      borderRadius: '50%',
      marginLeft: '5px',
    },
  }
  const checkName = (name) => {
    let tempName = false
    who?.map((el) => {
      if (el === name) tempName = true
      return true
    })
    return tempName
  }
  return (
    <div style={style.root} onClick={() => onClick()}>
      <img className="game" src={image || defaultImage} alt={name} style={style.img} loading="lazy" />
      <div style={style.data}>
        <div style={style.infoCat}>
          {publishingHouse || 'Brak wydawcy'}
        </div>
        <div style={style.title}>
          {name}
        </div>
      </div>
      <div style={style.companies}>
        { checkName('Koszalińska Biblioteka Publiczna') && <img src={kbp} alt={name} style={style.logoCompany}/> }
        { checkName('Stowarzyszenie Mama w Mieście Koszalin') && <img src={mum} alt={name} style={style.logoCompany}/> }
      </div>
    </div>
  )
}

export default GameBox
