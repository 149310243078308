import React, { useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { Facebook, Google } from '@mui/icons-material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import UserHelper from '../../helpers/UserHelper/UserHelper'
import { useSnackbar } from 'notistack'
import { loginWithFacebook, loginWithGoogle } from '../../firebase'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import CookiesHelper from '../../helpers/CookiesHelper/CookiesHelper'
import GTMSerivce from '../../services/GTMService/GTMSerivce'

const LoginPage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const { enqueueSnackbar } = useSnackbar()
  const disableButtons = false
  const formData = {
        elements: [
          {
            name: 'email',
            type: 'email',
            focus: true,
            label: t.globals.inputs.email.label,
            value: CookiesHelper.get('saveEmail') || '',
            validationType: 'string',
          },
          {
            name: 'password',
            type: 'password',
            label: t.globals.inputs.password.label,
            value: CookiesHelper.get('savePassword') || '',
            helperText: t.globals.inputs.password.helperText,
            validationType: 'string',
          },
          {
            type: 'button',
            value: t.loginPage.buttons.login,
          }
        ],
  }
  const style = {
    root: {
      background: 'white',
      padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '25px',
      fontWeight: 700,
      maxWidth: BreakpointsHelper.isMobile() ? 'none' : '320px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
	    textAlign: 'center',
    },
    desc: {
      maxWidth: '320px',
      fontSize: BreakpointsHelper.isMobile() ? '17px' : '18px',
      fontWeight: BreakpointsHelper.isMobile() ? '400' : '300',
      textTransform: 'none',
      textAlign: 'center',
    },
    icon: {
      marginRight: '5px',
    },
  }
  const createData = async (res) => {
    const data = {
      accessToken: res?.res?.accessToken,
      uid: res?.res?.uid,
      email: res?.res?.email,
      photoURL: res?.res?.photoURL,
      role: res?.database?.role || 'user',
      phone: res?.database?.phone,
      name: res?.database?.name,
      apartment: res?.database?.apartment,
      street: res?.database?.street,
      notSend: res?.database?.notSend,
      house: res?.database?.house,
      defaultLang: res?.database?.defaultLang,
    }
    LocalStorageHelper.set('user', data)
    return data
  }
  const handleLogin = (e) => {
    console.log(e)
    UserHelper.login(e).then(res => {
      if (res) {
        console.log(res?.res?.emailVerified)
	      if (!CookiesHelper.get('saveEmail') && !CookiesHelper.get('savePassword')) {
		      CookiesHelper.set('saveEmail', e.email)
		      CookiesHelper.set('savePassword', e.password)
	      }
	      if (res?.res?.emailVerified) {
          createData(res).then(res => {
            if (res) {
              GTMSerivce.login(e)
              setTimeout(() => {
                props.location.history.push('/events')
              }, 1000)
            }
          })
        }
        if (!res?.res?.emailVerified) {
          enqueueSnackbar(t.loginPage.toasts.notActive, { variant: 'warning' })
        }
      } else {
        enqueueSnackbar(t.loginPage.toasts.error, { variant: 'error' })
      }
    }).catch((error) => {
      console.log(error)
      enqueueSnackbar(t.loginPage.toasts.wrongData, { variant: 'error' })
    })
  }
  const handleLoginGoogle = () => {
    loginWithGoogle().then(res => {
	    if (res) {
        createData(res.user).then(() => {})
        props.location.history.push('/dashboard')
	    } else enqueueSnackbar(t.loginPage.toasts.error, { variant: 'error' })
    })
  }
  const handleLoginFacebook = () => {
	  loginWithFacebook().then(res => {
      if (res) {
        if (res === 'error-social') enqueueSnackbar('Posiadasz inne konto z tym adresem email, spróbuj użyć innego logowania.', { variant: 'warning' })
        else {
	        createData(res.user).then(() => {})
	        props.location.history.push('/dashboard')
        }
		  } else enqueueSnackbar(t.loginPage.toasts.wrongData, { variant: 'error' })
	  }).catch(() => {
      enqueueSnackbar(t.loginPage.toasts.wrongData, { variant: 'error' })
    })
  }
  useEffect(() => {
    if (LocalStorageHelper.get('user')) {
      const savePassword = CookiesHelper.get('savePassword')
      const saveEmail = CookiesHelper.get('saveEmail')
      if (saveEmail && savePassword) {
        props.location.history.push('/events')
      }
    }
  }, [])
  return (
		<div style={style.root}>
      <Button
        sx={{ position: 'absolute', top: '20px', left: '20px' }}
        variant="outlined"
        onClick={() => props.location.history.push('/home')}
      >
        {t.loginPage.buttons.goHome}
      </Button>
      {t.loginPage.title}
			<div style={style.desc}>
        {t.loginPage.description}
			</div>
			<FormGenerator data={formData} submit={(e) => handleLogin(e)} {...props}/>
      {
          disableButtons && (
              <>
                  <Button
                      sx={{ marginBottom: '10px' }}
                      fullWidth
                      variant="contained"
                      onClick={handleLoginFacebook}
                  >
                      <Facebook style={style.icon} />
                      <Typography>
                          <b>Zaloguj się przez Facebooka</b>
                      </Typography>
                  </Button>
                  <Button
                      sx={{ marginBottom: '10px' }}
                      fullWidth
                      variant="contained"
                      onClick={handleLoginGoogle}
                  >
                      <Google style={style.icon} />
                      <Typography>
                          <b>Zaloguj się przez Google</b>
                      </Typography>
                  </Button>
              </>
          )
      }
      <Button
          sx={{ marginBottom: '10px' }}
          fullWidth
          variant="outlined"
          onClick={() => props?.location?.history?.push('/register')}
      >
        {t.loginPage.buttons.register}
      </Button>
			<Typography
				sx={{ marginBottom: '20px', marginTop: '20px', textTransform: 'lowercase', fontWeight: '700' }}
			>
        {t.loginPage.buttons.remember}
			</Typography>
			<Button
				sx={{ marginBottom: '10px' }}
				fullWidth
				variant="outlined"
				onClick={() => props?.location?.history?.push('/reset')}
			>
        {t.loginPage.buttons.reset}
			</Button>
		</div>
  )
}

export default LoginPage
