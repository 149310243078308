import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const Discount = ({ data }) => {
  const { company, descriptionDiscount, url, logo, discount, user } = data
  const style = {
    root: {
      boxShadow: '2px 2px 5px 2px rgba(0,0,0,.15)',
      padding: '20px',
      width: 'calc(100% - 40px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: BreakpointsHelper.isMobile() ? '215px' : '325px',
    },
    company: {
      fontWeight: '600',
      fontSize: '20px',
    },
    description: {
      fontWeight: '400',
      fontSize: '18px',
    },
    url: {
      fontWeight: '400',
      fontSize: '18px',
    },
    img: {
      fitContent: 'contains',
      maxWidth: '100%',
      maxHeight: '50px',
      height: 'auto',
      marginBottom: '20px',
    },
    imgBox: {
      display: 'flex',
      alignItems: 'center',
    }
  }
  return (
    <Grid item md={2} xs={12}>
      <Box sx={style.root}>
        <Box sx={style.imgBox}>
          <img src={logo} alt={company} style={style.img} />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
          <Typography sx={style.company}>{company}</Typography>
          <Typography sx={style.description}>{descriptionDiscount},<br/> <span dangerouslySetInnerHTML={{ __html: discount }}/></Typography>
          <Typography sx={style.url}><a href={url}>Link do zniżki</a></Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default Discount
