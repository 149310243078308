import React, { useEffect, useState } from 'react'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import {
	createGame,
	getAllDataFromPath,
	getAllEvents,
	getAllGames,
	getAllTournaments,
	getAllUsers
} from '../../firebase'
import BasicTable from '../../components/atoms/Table/Table'
import Typography from '@mui/material/Typography'
import Tabs from '../../components/molecules/Tabs/Tabs'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { QrReader } from 'react-qr-reader'
import Box from '@mui/material/Box'
import './AdminPage.scss'
import { useSnackbar } from 'notistack'
import ArrayHelper from '../../helpers/ArrayHelper/ArrayHelper'
import NotificationService from '../../services/NotificationService/NotificationService'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import DateHelper from '../../helpers/DateHelper/DateHelper'
import PartnersEnum from '../../enums/PartnersEnum/PartnersEnum'
import Cooperators from '../../components/organisms/Cooperators/Cooperators'

const AdminPage = (props) => {
	const addGame = new URLSearchParams(props.location.history.location.search).get('addGame')
	const { enqueueSnackbar } = useSnackbar()
	const [users, setUsers] = useState(null)
	const [dataTabs, setDataTabs] = useState([])
	const [events, setEvents] = useState(null)
	const style = {
		root: {
			width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 170px)',
			padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px',
		},
	}
	const onScan = (e) => {
		const scan = e?.text
		if (scan?.length > 0) {
			const email = scan?.split('/')[4]
			if (email) {
				checkIsEmailInEvent(scan, email)
			}
		}
	}
	const handleAddNewGame = (e) => {
		createGame(e).then((res) => {
			if (res) {
				window.open('/admin?addGame=true', '_self')
			}
		})
	}
	const getAllPublishHouses = () => {
		const dataOptions = []
		getAllGames().then((res) => {
			if (res) {
				const allPublishHouses = []
				res?.map(el => {
					if (el?.publishingHouse?.length > 0) {
						allPublishHouses.push(el?.publishingHouse)
					}
					return true
				})
				const noDuplications = ArrayHelper.deleteDuplicate(allPublishHouses)
				noDuplications.map(el => {
					dataOptions.push({
						value: el,
						label: el
					})
					return true
				})
			}
		})
		return dataOptions
	}
	const updateUsers = () => {
		getAllUsers().then((res) => {
			if (res) {
				const tempData = []
				res?.map(el => {
					const element = el?.data
					tempData.push({
						uid: element?.uid,
						email: element?.email,
						name: element?.name,
						phone: element?.phone,
						role: element?.role,
					})
					return true
				})
				setUsers(tempData)
			}
		})
	}
	const generateTabs = () => {
		const allTabs = []
		if (LocalStorageHelper.get('user')?.role === 'superadmin') {
			allTabs.push({
				name: 'Użytkownicy',
				value: () => (
					<>
						{ users && <BasicTable data={users} collection={'users'} callback={() => updateUsers()} /> }
					</>
				),
			})
		}
		allTabs.push({
			name: 'Skanowanie biletów',
			value: () => (
				<Grid container>
					<Grid item md={6} xs={12}>
						<QrReader
							delay={1000}
							className="qrReader"
							facingMode="rear"
							style={{ width: '100%', height: 'auto', padding: '0 !important' }}
							onResult={(e) => onScan(e)}
							onError={() => true}
							constraints={{ facingMode: 'environment' }}
						/>
					</Grid>
				</Grid>
			),
		})
		if (LocalStorageHelper.get('user')?.role === 'admin' || LocalStorageHelper.get('user')?.role === 'superadmin') {
			allTabs.push({
				name: 'Wysyłane emaile',
				value: () => (
					<Grid container>
						<Grid item md={6} xs={12}>
							<Typography sx={{ fontSize: '18px', marginBottom: '20px', fontWeight: '600', textTransform: 'uppercase' }}>
								Email do wydawnictw, bądź nowych partnerów
							</Typography>
							<Typography sx={{
								fontSize: '18px',
								marginBottom: '20px'
							}}>
								Szanowni Państwo,
								<br/><br/>
								Nazywam się Jan Szymański i reprezentuję grupę Planszówki Koszalin oraz aplikację służącą do
								organizowania spotkań przy grach planszowych. Działamy w Koszalinie i okolicach, prowadząc regularne
								spotkania od ponad 2 lat. Aktualnie jest w naszej grupie już {users?.length} graczy.
								Dzięki wsparciu naszych partnerów oraz mediów lokalnych, takich jak gazeta,
								telewizja i radio, udało nam się stworzyć dynamiczną społeczność miłośników planszówek.
								<br/><br/>
								Poszukujemy dodatkowych sponsorów oraz partnerów, którzy mogliby wesprzeć naszą inicjatywę.
								Jeżeli nie są Państwo zainteresowani bezpośrednim sponsorowaniem, z przyjemnością rozważymy inne formy
								współpracy. Możemy np. przetestować Państwa gry i napisać recenzję, co również pomoże w promocji
								Waszych produktów. Zachęcamy do przesyłania do nas nowych tytułów do przetestowania, przy tak licznej
								ilości graczy na pewno dokładnie tytuł zostanie przetestowany.
								<br/><br/>
								Po zaakceptowaniu propozycji partnerstwa oferujemy:
								<br/><br/>
								• Post na Facebooku i Instagramie z informacją o nowym partnerze, logo oraz krótkim opisem.
								<br/>
								• Umieszczenie logo z linkiem do strony internetowej na naszej aplikacji webowej oraz mobilnej (Google
								Play).
								<br/>
								• Widoczność logo na materiałach reklamowych (plakaty, bannery, rollupy, ulotki).
								<br/><br/>
								Więcej informacji o naszej działalności można znaleźć na naszej stronie internetowej
								https://planszowki-koszalin.pl. W razie pytań, prosimy o kontakt pod numerem telefonu 509 609 439.
								Ew. adres do wysyłki gier: ul. Holenderska 57/22, 75-430 Koszalin.
								<br/><br/>
								Pozdrawiam serdecznie,<br/>
								Jan Szymański, Założyciel Planszówki Koszalin
							</Typography>
						</Grid>
						<Grid item md={6} xs={12}>
						</Grid>
						</Grid>
				),
			})
			allTabs.push({
				name: 'Pytania do wywiadu',
				value: () => (
					<Typography sx={{ fontSize: '18px', marginBottom: '20px' }}>
						• Od kiedy grasz z nami w planszówki?<br/>
						• Skąd dowiedziałeś się o grupie Planszówki Koszalin?<br/>
						• Czy masz jakieś swoje planszówki?<br/>
						• W co lubisz grać najbardziej?<br/>
						• Jak myślisz, co powinien umieć planszówkowy gracz, by stać się lepszym graczem?<br/>
						• Czy lubisz łatwe tytuły, czy wolisz coś cięższego?<br/>
						• Jakie masz oczekiwania wobec gier planszowych poznając nowy tytuł?<br/>
						• Czy czas gra role w wyborze gry do grania?<br/>
						• Jakie typu posty byłyby dla Ciebie najważniejsze u nas na Facebooku?<br/>
						• Jakie nasze wydarzenia najbardziej ci się podobają? Na jakich byłaś/eś?<br/>
						• Czy wcześniej miałaś/eś coś do czynienia z planszówkami?<br/>
						• Czy poznajesz zasady danej gry przed graniem, czy wolisz jak ktoś ci wytłumaczy zasady?<br/>
						• Czy chciałabyś/chciałbyś coś zmienić w spotkaniach?<br/>
						• Jak podoba Ci się nasza aplikacja? Czy często korzystasz?<br/>
						• Czy masz jakieś sugestie co zmienić w aplikacji, by korzystało się z niej lepiej?<br/>
						• Czy to, że posiadamy jako partnerów wydawnictwa planszówkowe, wizerunkowo wygląda dla Ciebie lepiej?<br/>
						• Co ci się podoba w grach planszowych?<br/>
						• Gry planszowe, czy gry komputerowe?<br/>
						• Czy kupujesz gry planszowe nowe, czy używane?<br/>
					</Typography>
				)
			})
			if (LocalStorageHelper.get('user')?.role === 'superadmin') {
				allTabs.push({
					name: 'Dane kontaktowe',
					value: () => (
						<Grid container spacing={4}>
							<Cooperators />
						</Grid>
					)
				})
				allTabs.push({
					name: 'Powiadomienia',
					value: () => {
						const tempFormData = []
						events.forEach(el => {
							const dayToEvent = DateHelper.getDaysToEvent(el)
							if (dayToEvent > 0) {
								tempFormData.push({ name: `${el?.data?.name}, ${el?.data?.date}`, value: el?.id })
							}
						})
						const formData = {
							elements: [
								{
									type: 'text',
									name: 'notificationText',
									label: 'Treść powiadomienia',
								},
								{
									type: 'select',
									name: 'notificationEvent',
									items: tempFormData,
									label: 'Wybierz dostępny event',
								},
								{
									type: 'button',
									value: 'Wyślij powiadomienie',
								},
							],
						}
						const handleSendToUsersFromEvent = (e) => {
							const phoneList = []
							const tempFormData = []
							events.forEach(el => {
								const dayToEvent = DateHelper.getDaysToEvent(el)
								if (dayToEvent > 0) {
									tempFormData.push(el)
								}
							})
							let activeEventData = null
							tempFormData?.forEach(el => {
								if (el?.id === e?.notificationEvent) {
									activeEventData = el
								}
							})
							activeEventData?.data?.users?.forEach(el => {
								const tempPhone = el?.phone?.toString()?.replace(' ', '')?.trim().replace(' ', '')
								if (tempPhone?.length >= 9) {
									phoneList.push(tempPhone)
								}
							})
							const phoneExistInList = (phone) => {
								return phoneList.includes(phone)
							}
							getAllDataFromPath('users').then((res) => {
								if (res) {
									const numbers = []
									res?.forEach(el => {
										if (el?.data?.phone) {
											if (!el?.data?.notSend) {
												const withNoSpaces = el?.data?.phone.toString().trim().replace(' ', '').replace(' ', '')
												if (withNoSpaces.length >= 9) {
													if (phoneExistInList(withNoSpaces)) {
														numbers.push(withNoSpaces)
													}
												}
											}
										}
									})
									NotificationService.sendToUsersFromEvent(e, numbers)
								}
							})
						}
						return (
							<Box>
								<Grid container spacing={4}>
									<Grid item md={4} xs={12}>
										<Typography sx={{ marginBottom: '20px', fontSize: '20px' }}>Powiadomienia do konkretnego wydarzenia</Typography>
										<FormGenerator data={formData} submit={(e) => handleSendToUsersFromEvent(e)} />
									</Grid>
									<Grid item md={4} xs={12}>
										<Typography sx={{ marginBottom: '20px', fontSize: '20px' }}>Powiadomienia od wszystkich o nowych wydarzeniach</Typography>
										<Button
											variant="contained"
											onClick={() => NotificationService.sendToAllUsers('Nowe wydarzenia na Planszówki Koszalin, https://planszowki-koszalin.pl')}
										>
											Nowe wydarzenia (do wszystkich)
										</Button>
									</Grid>
								</Grid>
							</Box>
						)
					}
				})
			}
			allTabs.push({
				name: 'Dodanie gry',
				value: () => {
					const formData = {
						elements: [
							{
								name: 'name',
								label: 'Nazwa gry',
								type: 'text',
								validateType: 'string',
							},
							{
								name: 'publishingHouse',
								label: 'Wydawnictwo',
								type: 'autocomplete',
								options: getAllPublishHouses(),
								validateType: 'string',
							},
							{
								name: 'image',
								label: 'Okładka (url)',
								type: 'text',
								validateType: 'string',
							},
							{
								name: 'minTime',
								label: 'Minimalny czas gry',
								type: 'text',
								validateType: 'string',
							},
							{
								name: 'maxTime',
								label: 'Maksymalny czas gry',
								type: 'text',
								validateType: 'string',
							},
							{
								name: 'minPerson',
								label: 'Liczba graczy minimum',
								type: 'text',
								validateType: 'string',
							},
							{
								name: 'maxPerson',
								label: 'Liczba graczy maksimum',
								type: 'text',
								helperText: 'Jeżeli nie istnieje podajemy wartość 20.',
								validateType: 'string',
							},
							{
								name: 'years',
								label: 'Minimalny wiek',
								type: 'text',
								validateType: 'string',
							},
							{
								name: 'description',
								label: 'Opis',
								type: 'editor',
								validateType: 'string',
							},
							{
								name: 'category',
								label: 'Kategorie',
								type: 'multiSelect',
								items: [
									{ name: 'Strategiczne', value: 'Strategiczne' },
									{ name: 'RPG', value: 'RPG' },
									{ name: 'Rodzinne / Familijne', value: 'Rodzinne / Familijne' },
									{ name: 'Fabularne / Przygodowe', value: 'Fabularne / Przygodowe' },
									{ name: 'Paragrafowe', value: 'Paragrafowe' },
									{ name: 'Karciane', value: 'Karciane' },
									{ name: 'Kościane', value: 'Kościane' },
									{ name: 'Imprezowe / Towarzyskie', value: 'Imprezowe / Towarzyskie' },
									{ name: 'Kooperacyjne', value: 'Kooperacyjne' },
									{ name: 'Ekonomiczne', value: 'Ekonomiczne' },
									{ name: 'Dla dzieci / Edukacyjne', value: 'Dla dzieci / Edukacyjne' },
									{ name: 'Dodatki', value: 'Dodatki' },
									{ name: 'Inne', value: 'Inne' },
								],
								helperText: (
									<Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '20px' }}>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Strategiczne:</b> Istnieje system turowy oraz jedna z wykorzystanych mechanik: układanie kafelek, przydzielanie pracowników, budowanie talii lub podboje i konflikty.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>RPG:</b> Gra oparta na rozwijaniu postaci przez gracza. Musi zawierać kartę postaci.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Rodzinne / Familijne:</b> Powyżej 2 osób oraz max do 2 godzin gry. Zakres wiekowy 6-12 lat. Proste zasady, szybka rozgrywka, dla całej rodziny.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Fabularne / Przygodowe:</b> Pojawia się rozwiązywanie zagadek. Niezbędne elementy to: opowiadanie historii i eksploracja terenów.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Paragrafowe:</b> Gry książkowe polegające na czytaniu opisów i dokonywaniu wyborów.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Karciane:</b> Gry bez planszy, w których mechanika oparta jest na kartach.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Kościane:</b> Gry, w których główną mechaniką jest rzut kością.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Imprezowe / Towarzyskie:</b> Wyróżnia je wysoki poziom losowości. Celem jest integracja grupy graczy. Skupiają się na wykorzystaniu zręczności,szybkości i spostrzegawczości.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Kooperacyjne:</b> Wymagana jest współpraca między graczami. Może wystąpić motyw zdrajcy.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Ekonomiczne:</b> Gry, w których istnieją pieniądze i/lub zasoby, dzięki którym możemy handlować.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Dla dzieci / Edukacyjne:</b> Wspierające rozwój, proste, krótkie gry.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Dodatki:</b> Dodatki do gier, wymagają wersji podstawowej gry.
										</Typography>
										<Typography sx={{ marginBottom: '5px', marginTop: '5px' }}>
											<b>Inne:</b> Nie przypisane do powyższych kategorii.
										</Typography>
									</Box>
								),
								validateType: 'string',
							},
							{
								name: 'tutorial',
								label: 'Filmik z instrukcją (link do Youtube)',
								type: 'text',
								required: false,
								validateType: 'string',
							},
							{
								value: 'Utwórz nową grę',
								type: 'button',
							},
						],
					}
					return (
						<Box sx={{ width: BreakpointsHelper.isMobile() ? '100%' : '50%' }}>
							<FormGenerator fullWidth data={formData} submit={(e) => handleAddNewGame(e)} />
						</Box>
					)
				},
			})
		}
		setDataTabs(allTabs)
	}
	const checkIsEmailInEvent = (scan, email) => {
		let isVerified = false
		const allElementsEvents = getAllTournaments(email)
		allElementsEvents?.then((res) => {
			if (res) {
				const id = scan.split('/')[2].replace('%20', ' ')
				let data = null
				res?.map(el => {
						if (el?.id === id) {
							data = el?.data
						}
					return true
				})
				data?.users?.map(el => {
					if (el?.email === email) {
						isVerified = true
						if (data?.hours?.start && data?.hours?.end) {
							data.users.find(element => element?.email === email).ver = true
						}
					}
					return el
				})
				if (!isVerified) {
					enqueueSnackbar('Nie zweryfikowano użytkownika poprawnie.', { variant: 'error' })
				} else {
					// const dataUser = data.users.find(el => el.email === email)
					enqueueSnackbar('Zweryfikowano użytkownika poprawnie.', { variant: 'success' })
					setTimeout(() => window.open('/admin', '_self'), 1000)
				}
			}
		})
		return isVerified
	}
	useEffect(() => {
		if (events && users) {
			generateTabs()
		}
	}, [users, events])
	useEffect(() => {
		updateUsers()
		if (addGame) {
			enqueueSnackbar('Dodano grę poprawnie', { variant: 'success' })
		}
	}, [])
	useEffect(() => {
		const allElementsEvents = getAllEvents()
		allElementsEvents.then((res) => {
			if (res) {
				res?.forEach((ev) => {
					const date = ev.data.date
					const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD/MM/YYYY')
					ev.dayToEvent = DateHelper.getNumberOfDays(`${dateToday.split('/')[1]}/${dateToday.split('/')[0]}/${dateToday.split('/')[2]}`, `${date.split('.')[1]}/${date.split('.')[0]}/${date.split('.')[2]}`)
				})
				res.sort((x, y) => x.dayToEvent - y.dayToEvent)
				setEvents(res)
			}
		})
	}, [])
	return (
		<>
			<div style={style.root}>
				{ dataTabs?.length > 0 && <Tabs data={dataTabs}/> }
			</div>
		</>
	)
}

export default AdminPage
