export default {
	OK: {
		number: 200,
		name: 'Żądanie do serwera zostało zrealizowane pomyślnie',
	},
	MOVED_PERMANENTLY: {
		number: 301,
		name: 'Zasób dostępny pod tym adresem został na stałe przeniesiony i tam należy szukać go w przyszłości',
	},
	FOUND: {
		number: 302,
		name: 'Zasób został tymczasowo przeniesiony na inny adres. W przyszłości należy szukać zasobu pod dotychczasowym zapytaniem',
	},
	BAD_REQUEST: {
		number: 400,
		name: 'Błędne zapytanie',
	},
	UNAUTHORIZED: {
		number: 401,
		name: 'Dostęp wymaga autoryzacji',
	},
	PAYMENT_REQUIRED: {
		number: 402,
		name: 'Wymagana opłata',
	},
	FORBIDDEN: {
		number: 403,
		name: 'Dostęp został zabroniony',
	},
	NOT_FOUND: {
		number: 404,
		name: 'Nie znaleziono pliku na serwerze',
	},
	METHOD_NOT_ALLOWED: {
		number: 405,
		name: 'Metoda niedozwolona',
	},
	NOT_ACCEPTABLE: {
		number: 406,
		name: 'Nie można zwrócić odpowiedzi na dane zapytanie',
	},
	PROXY_AUTHENTICATION_REQUIRED: {
		number: 407,
		name: 'Wymagana autoryzacja',
	},
	REQUEST_TIME_OUT: {
		number: 408,
		name: 'Przekroczono czas oczekiwania',
	},
	CONFLICT: {
		number: 409,
		name: 'Konflikt statusów pomiędzy zasobami',
	},
	GONE: {
		number: 410,
		name: 'Zasób, którego żądasz został usunięty',
	},
	LENGTH_REQUIRED: {
		number: 411,
		name: 'Odmowa realizacji, ze względu na brak Content-Lenght',
	},
	PRECONDITION_FAILED: {
		number: 412,
		name: 'Nie spełniono warunków',
	},
	REQUEST_ENTITY_TOO_LARGE: {
		number: 413,
		name: 'Za długie zapytanie',
	},
	REQUEST_URL_TOO_LARGE: {
		number: 414,
		name: 'Adres URL zapytania jest za długi',
	},
	UNSUPPORTED_MEDIA_TYPE: {
		number: 415,
		name: 'Niezrozumiały komunikat dla serwera',
	},
	REQUESTED_RANGE_NOT_SATISFIABLE: {
		number: 416,
		name: 'Żądany zakres nie jest obsługiwany',
	},
	EXPECTATION_FAILED: {
		number: 417,
		name: 'Niepowodzenie w zwracaniu zawartości',
	},
	NOT_IMPLEMENTED: {
		number: 500,
		name: 'Wewnętrzny błąd serwera',
	},
	BAD_GATEWAY: {
		number: 501,
		name: ' Zapytanie nie zostało zrozumiane przez serwer. Nie zwrócono żądanej zawartości',
	},
	SERVICE_UNAVAILABLE: {
		number: 502,
		name: 'Błąd bramy, zwrócona wartość przez serwer jest niepoprawna',
	},
	GATEWAY_TIME_OUT: {
		number: 503,
		name: 'Przeciążenie lub brak dostępności serwera',
	},
	HTTP_VERSION_NOT_SUPPORTED: {
		number: 504,
		name: ' Przekroczony czas oczekiwania na odpowiedz serwera',
	},
	INTERNAL_SERVER_ERROR: {
		number: 505,
		name: 'Ta wersji HTTP nie jest obsługiwana przez serwer',
	},
}
