import classModule from './Wizard.module.scss'
import classnames from 'classnames'

const Wizard = () => {
  return (
    <div className={classModule.wizardRoot}>
      <div className={classModule.scene}>
        <div className={classModule.objects}>
          <div className={classModule.square}/>
          <div className={classModule.circle}/>
          <div className={classModule.triangle}/>
        </div>
        <div className={classModule.wizard}>
          <div className={classModule.body}/>
          <div className={classModule.rightArm}>
            <div className={classModule.rightHand}/>
          </div>
          <div className={classModule.leftArm}>
            <div className={classModule.leftHand}/>
          </div>
          <div className={classModule.head}>
            <div className={classModule.beard}/>
            <div className={classModule.face}>
              <div className={classModule.adds}/>
            </div>
            <div className={classModule.hat}>
              <div className={classModule.hatOfTheHat}/>
              <div className={classnames(classModule.fourPointStar, classModule.first)}/>
              <div className={classnames(classModule.fourPointStar, classModule.second)}/>
              <div className={classnames(classModule.fourPointStar, classModule.third)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wizard
