import React, { useEffect, useState } from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import logo from '../../../assets/images/logo.png'
import logoVertical from '../../../assets/images/logo_vertical.png'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import ChangeLang from '../../organisms/ChangeLang/ChangeLang'
import {
  Badge,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Tooltip
} from '@mui/material'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'
import useStore from '../../../Zustand'
import Typography from '@mui/material/Typography'
import ProductHelper from '../../../helpers/ProductHelper/ProductHelper'
import { IoTrashOutline } from 'react-icons/io5'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router'

const NavigationPreview = ({ location }) => {
  const t = LocalStorageHelper.get('translation')
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [groupCart, setGroupCart] = useState([])
  const [allPrice, setAllPrice] = useState([])
  const cart = useStore((state) => state?.cart)
  useEffect(() => {
    if (cart) {
      const temp = {}
      cart?.forEach((el) => {
        if (temp[el.id]) {
          temp[el.id].number++
        } else {
          temp[el.id] = { ...el, number: 1 }
        }
      })
      const tempArray = Object.values(temp)
      setGroupCart(tempArray)
      let allPrice = 0
      tempArray?.forEach((el) => {
        allPrice += (el?.number || 1) * Number(el?.priceData?.specialPrice || el?.priceData?.price)
      })
      setAllPrice(allPrice)
    }
  }, [cart])
  const RemoveFromCart = useStore((state) => state?.RemoveFromCart)
  const [state, setState] = React.useState({ right: false })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setState({ ...state, [anchor]: open })
  }
  const handleDelete = (e, el) => {
    e.preventDefault()
    RemoveFromCart(el?.id)
    enqueueSnackbar('Usunięto produkt poprawnie.', { variant: 'success' })
  }
  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      sx={{
        width: 400,
        height: '100%',
        padding: '20px',
      }}
    >
      <List sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
        <Box>
          {
            groupCart?.length > 0
              ? groupCart?.map((el, index) => {
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemButton>
                      <ListItemIcon sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <img src={el?.image} alt={el?.name} style={{ width: '100px', paddingRight: '30px' }} onClick={() => history.push(`/previewProduct/${el?.id}`)} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }} onClick={() => history.push(`/previewProduct/${el?.id}`)}>
                          <Typography sx={{ fontSize: '18px', fontWeight: '900', marginTop: '10px' }}>{el?.name}</Typography>
                          <Typography sx={{ fontSize: '22px', marginTop: '10px' }}>{el?.number || 1} x {el?.priceData?.specialPrice || el?.priceData?.price} {el?.priceData?.count}</Typography>
                        </Box>
                        <IoTrashOutline
                          style={{
                            position: 'absolute',
                            right: '5px',
                            borderRadius: '3px',
                            bottom: '15px',
                            background: '#b72121',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '15px',
                            height: '15px',
                            padding: '8px',
                          }}
                          onClick={(e) => handleDelete(e, el)}
                        />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                )
              })
              : (
                <Typography>
                  Brak elementów w Koszyku
                </Typography>
              )
          }
        </Box>
        {
          cart?.length > 0 && (
            <Box sx={{ width: '100%' }}>
              <Typography sx={{ fontWeight: '900', marginBottom: '30px', marginTop: '30px', fontSize: '30px' }}>Cena zamówienia: <span style={{ fontWeight: '400' }}>{allPrice.toFixed(2)} zł</span></Typography>
              <Button onClick={() => history.push('/order')} fullWidth variant="contained">Koszyk zakupowy</Button>
            </Box>
          )
        }
      </List>
    </Box>
  )
  const style = {
    nav: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: `${BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 100px)'}`,
      margin: '0 auto',
      height: '100px',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: BreakpointsHelper.isMobile() ? 15 : 50,
      paddingLeft: BreakpointsHelper.isMobile() ? 15 : 50,
      zIndex: 999,
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    },
    logo: {
      cursor: 'pointer',
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
    },
    logoOpen: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '35px',
      position: 'relative',
      left: '-5px',
      bottom: '-3px',
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
    },
    burgerOpen: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      right: '-5px',
      bottom: '-2px',
      marginBottom: '30px',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      textAlign: 'center',
      padding: '5px 10px',
      lineHeight: '100%',
      fontSize: '16px',
    },
    linkButton: {
      marginRight: '10px',
      padding: '0',
      lineHeight: '100%',
    },
    cart: {
      background: 'red',
      width: '25px',
      height: '25px',
      color: 'white',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      right: '-10px',
      top: '-10px',
    }
  }
  const openLink = (link) => location.history.push(link)
  return (
    <div style={style.nav} className="navigation">
      <img onClick={() => window.open('/home', '_self')} src={BreakpointsHelper.isMobile() ? logoVertical : logo}
           alt={'logo'} style={style.logo}/>
      {
        (!BreakpointsHelper.isMobile() && !ProductHelper.isShop()) && (
            <Box sx={{ display: 'flex' }}>
              <Button sx={style.linkButton}>
                <a href="#about" style={style.link}>O nas</a>
              </Button>
              <Button sx={style.linkButton}>
                <a href="#app" style={style.link}>Nasza aplikacja</a>
              </Button>
              <Button sx={style.linkButton}>
                <a href="#partners" style={style.link}>Partnerzy</a>
              </Button>
              <Button sx={style.linkButton}>
                <a href="#where" style={style.link}>Gdzie gramy?</a>
              </Button>
              <Button sx={style.linkButton}>
                <a href="#games" style={style.link}>Rodzaje spotkań</a>
              </Button>
              <Button sx={style.linkButton}>
                <a href="#rules" style={style.link}>Zasady</a>
              </Button>
              <Button sx={style.linkButton}>
                <a href="#contacts" style={style.link}>Kontakt</a>
              </Button>
            </Box>
          )
      }
      {
        BreakpointsHelper.isMobile()
          ? (
            <div style={style.burger} onClick={() => setOpen(!open)}>
              <MenuIcon style={style.menu}/>
            </div>
          )
          : (
            <div style={style.buttons}>
              {
                ProductHelper.isShop() && (
                  <>
                    <Button
                      onClick={() => openLink('/shop')}
                      variant="contained"
                      sx={{
                        margin: 0,
                        marginRight: '20px',
                        height: '37px',
                        lineHeight: '100%'
                      }}
                    >
                      Sklep
                    </Button>
                    <Button
                      onClick={toggleDrawer('right', true)}
                      variant="contained"
                      sx={{
                        margin: 0,
                        marginRight: '20px',
                        height: '37px',
                        lineHeight: '100%'
                      }}
                    >
                      Koszyk
                      {
                        cart?.length > 0 && (
                          <Badge sx={style.cart}>{cart.length}</Badge>
                        )
                      }
                    </Button>
                    <Drawer
                      anchor={'right'}
                      open={state.right}
                      onClose={toggleDrawer('right', false)}
                    >
                      {list('right')}
                    </Drawer>
                  </>
                )
              }
              <Button
                variant="contained"
                sx={{
                  margin: 0,
                  marginRight: '20px',
                  height: '37px',
                  lineHeight: '100%'
                }}
                onClick={() => openLink('/login')}
              >
                {t?.homePage?.buttonApp}
              </Button>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <ChangeLang noMargin/>
              </Box>
            </div>
          )
      }
      {
        open && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: '999',
              background: 'white',
              top: '0',
              left: '0',
              height: '100vh',
              width: 'calc(100% - 40px)',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <img src={BreakpointsHelper.isMobile() ? logoVertical : logo} alt={'logo'} style={style.logoOpen}/>
              </Grid>
              <Grid item xs={6}>
                <div style={style.burgerOpen} onClick={() => setOpen(!open)}>
                  <MenuIcon style={style.menu}/>
                </div>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              fullWidth
              sx={{ marginBottom: '20px' }}
              onClick={() => openLink('/login')}
            >
              {t?.homePage?.buttonApp}
            </Button>
            <Tooltip title={t.globals.nav.langChange} placement="right">
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <ChangeLang noMargin/>
              </Box>
            </Tooltip>
          </Box>
        )
      }
    </div>
  )
}

export default NavigationPreview
