export default {
  isShop: () => {
    const path = window?.location?.pathname
    return path.search('shop') >= 0 || path.search('previewProduct') >= 0 || path.search('order') >= 0
  },
  openPreviewProduct: (idProduct, history) => {
    if (history) {
      history.push(`/previewProduct/${idProduct}`)
    }
  },
}
