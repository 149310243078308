import React from 'react'
import Contacts from '../../components/organisms/Contacts/Contacts'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const ContactPage = () => {
	const style = {
		root: {
			padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
		},
	}
  return (
	  <>
			<div style={style.root}>
				<Contacts/>
			</div>
	  </>
  )
}

export default ContactPage
