import { Grid, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RoomChat from '../../molecules/RoomChat/RoomChat'
import { useEffect, useState } from 'react'
import { addPostToEvent, getAllEvents, updateOrCreateDocument } from '../../../firebase'
import Button from '@mui/material/Button'
import { SendOutlined } from '@mui/icons-material'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import DateHelper from '../../../helpers/DateHelper/DateHelper'

const RealtimeChat = (props) => {
  const { setNotReadMessages, notReadMessages } = props
  const [events, setEvents] = useState('')
  const [idChatOpen, setIdChatOpen] = useState('')
  const [currentData, setCurrentData] = useState(null)
  const [text, setText] = useState('')
  const style = {
    root: {
      height: 'calc(100% - 44px)',
      display: 'flex',
    },
    chatList: {
      background: '#f5f7fb',
      padding: '20px',
      height: '100%',
    },
    messagesList: {
      background: 'white',
      height: '100%',
    },
    title: {
      fontSize: '20px',
      fontWeight: '600',
      marginBottom: '20px',
    },
    boxRoom: {
      height: 'calc(100% + 44px)',
      display: 'flex',
      flexDirection: 'column',
    },
    titleRoom: {
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '20px',
    },
    bodyRoom: {
      height: 'calc(100vh - 318px)',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      alignItems: 'space-between',
    },
    sendMessage: {
      display: 'flex',
      padding: '20px',
    },
    messageThiers: {
      position: 'relative',
      background: 'rgb(12, 18, 47)',
      color: 'white',
      maxWidth: '320px',
      marginBottom: '20px',
      width: 'auto',
      flexDirection: 'column',
      display: 'inline-flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '10px 15px',
      borderRadius: '5px',
    },
    messageYours: {
      position: 'relative',
      background: '#f5f7fb',
      color: 'rgb(12, 18, 47)',
      maxWidth: '320px',
      marginBottom: '20px',
      width: 'auto',
      flexDirection: 'column',
      display: 'inline-flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '10px 15px',
      borderRadius: '5px',
    },
  }
  const openRoom = (id) => {
    setIdChatOpen(id)
    setCurrentData(events.find(el => el.id === id))
  }
  const getEvents = () => {
    const allElementsEvents = getAllEvents(LocalStorageHelper.get('user').email)
    allElementsEvents.then(async (res) => {
      if (res) {
        const actualEvents = []
        await res.map(el => {
          const date = el?.data?.date
          const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD.MM.YYYY')
          const days = DateHelper.getDaysBetweenTwoDates(new Date(date.split('.')[2], date.split('.')[1], date.split('.')[0]), new Date(dateToday.split('.')[2], dateToday.split('.')[1], dateToday.split('.')[0]))
          const dayToEvent = Number(days) / 60 / 60 / 24 / 1000
          if (dayToEvent >= 0) {
            actualEvents.push(el)
          }
          actualEvents?.map((ev) => {
            const date = ev?.data?.date
            const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD.MM.YYYY')
            const days = DateHelper.getDaysBetweenTwoDates(new Date(date.split('.')[2], date.split('.')[1], date.split('.')[0]), new Date(dateToday.split('.')[2], dateToday.split('.')[1], dateToday.split('.')[0]))
            ev.dayToEvent = Number(days) / 60 / 60 / 24 / 1000
            return true
          })
          actualEvents.sort((x, y) => x.dayToEvent - y.dayToEvent)
          return true
        })
        setEvents(actualEvents)
        setCurrentData(actualEvents[0])
        setIdChatOpen(actualEvents[0].id)
      }
    })
  }
  const handleCreate = () => {
    if (text?.length > 0) {
      addPostToEvent(idChatOpen, text).then((res) => {
        if (res) {
          setText('')
          getEvents()
        }
      })
    }
  }
  useEffect(() => {
    if (idChatOpen?.length > 0 && events?.length > 0) {
      setCurrentData(events.find(el => el.id === idChatOpen))
      const sendData = events.find(el => el.id === idChatOpen)?.data
      sendData?.messages?.map(el => {
        const seePosts = el.seePost
        let postSee = false
        seePosts?.map(person => {
          if (person.email === LocalStorageHelper.get('user').email) postSee = true
          return true
        })
        if (!postSee) el.seePost.push({ email: LocalStorageHelper.get('user').email })
        return true
      })
      setNotReadMessages(0)
      events?.map(el => {
        el.data.messages.map(message => {
          const dataPersonIsExist = message?.seePost?.find(person => person.email === LocalStorageHelper.get('user').email)
          if (!dataPersonIsExist) {
            setNotReadMessages(notReadMessages + 1)
          }
          return true
        })
        return true
      })
      updateOrCreateDocument('events', idChatOpen, sendData).then((res) => res)
    }
  }, [idChatOpen, events])
  useEffect(() => {
    if (events?.length > 0 && idChatOpen?.length > 0 && currentData) {
      const mess = document.querySelectorAll('.Messages')
      Array.from(mess).map(el => {
        el.scrollTop = el.scrollHeight
        return true
      })
    }
  }, [idChatOpen, events, currentData])
  useEffect(() => {
    getEvents()
    const updateEvents = setInterval(() => {
      getEvents()
    }, 5000)
    return () => clearInterval(updateEvents)
  }, [])
  return (
    <Box sx={style.root}>
      <Grid container>
        <Grid item md={4} xs={12}>
          <Box sx={style.chatList}>
            <Typography sx={style.title}>
              Pokoje wydarzeń
            </Typography>
            {
              events?.length > 0
                ? events?.map((event, index) => {
                return (
                  <RoomChat
                    key={index}
                    data={event}
                    currentId={currentData?.id}
                    onClick={() => openRoom(event?.id)}
                  />
                )
              })
              : (
                <Typography>
                  Nie dołączyłeś do żadnych wydarzeń
                </Typography>
              )
            }
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <Box sx={style.messagesList}>
            {
              idChatOpen && currentData && (
                <Box sx={style.boxRoom}>
                  <Box sx={style.titleRoom}>
                    {currentData.data.name}
                  </Box>
                  <Box style={{ border: '1px solid rgba(0, 0, 0, 0.05)', borderBottomWidth: '1px', margin: 0 }}/>
                  <Box sx={style.bodyRoom} className="Messages">
                    {
                      currentData?.data?.messages?.map((el, index) => {
                        if (el.email === LocalStorageHelper.get('user').email) {
                          return (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end' }}>
                              <Box sx={style.messageYours}>
                                <Typography sx={{ width: '100%' }}>
                                  {el.text}
                                </Typography>
                                <Typography sx={{ color: 'rgb(164,168,193)' }}>
                                  {el.date}
                                </Typography>
                                <Box sx={{ background: '#f5f7fb', width: '20px', height: '20px', position: 'absolute', right: '6px', bottom: '-8px', transform: 'rotate(45deg)' }} />
                              </Box>
                              <Typography sx={{ marginBottom: '20px' }}>
                                {el.name}
                              </Typography>
                            </Box>
                          )
                        } else {
                          return (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <Box sx={style.messageThiers}>
                                <Typography sx={{ width: '100%' }}>
                                  {el.text}
                                </Typography>
                                <Typography sx={{ color: 'rgb(164,168,193)' }}>
                                  {el.date}
                                </Typography>
                                <Box sx={{ background: 'rgb(12, 18, 47)', width: '20px', height: '20px', position: 'absolute', left: '6px', bottom: '-8px', transform: 'rotate(45deg)' }} />
                              </Box>
                              <Typography sx={{ marginBottom: '20px' }}>
                                {el.name}
                              </Typography>
                            </Box>
                          )
                        }
                      })
                    }
                  </Box>
                  <Box style={{ border: '1px solid rgba(0, 0, 0, 0.05)', borderBottomWidth: '1px', margin: 0 }}/>
                  <Box sx={style.sendMessage}>
                    <TextField
                      sx={{ flex: 1, marginRight: '20px' }}
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      type="text"
                      variant="outlined"
                      placeholder="Wpisz wiadomość"
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleCreate()}
                    >
                      <SendOutlined />
                    </Button>
                  </Box>
                </Box>
              )
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RealtimeChat
