import * as React from 'react'
import Box from '@mui/material/Box'
import StepperMaterial from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
const Stepper = ({ steps, reset, lastStep }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set())
  const style = {
    box: {
      display: 'flex',
      flexDirection: 'row',
      background: '#f3f3f3',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '10px 20px',
      marginTop: '30px',
      marginBottom: '30px',
    }
  }
  const isStepOptional = (el) => el?.optional || false
  const isStepSkipped = (step) => skipped.has(step)
  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error('Nie możesz pominąć tego kroku, nie jest opcjonalny.')
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }
  const handleReset = () => setActiveStep(0)
  useEffect(() => {
    if (activeStep === steps.length) {
      lastStep && lastStep()
    }
  }, [activeStep, steps])
  return (
    <Box sx={{ width: '100%' }}>
      <StepperMaterial activeStep={activeStep}>
        {steps?.map((el, index) => {
          const stepProps = {}
          const labelProps = {}
          if (isStepOptional(el)) {
            labelProps.optional = <Typography variant="caption">Opcjonalny krok</Typography>
          }
          if (isStepSkipped(index)) stepProps.completed = false
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{el?.label}</StepLabel>
            </Step>
          )
        })}
      </StepperMaterial>
      {activeStep === steps.length
      ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Zamówiłeś gry planszowe. Zamówienie będziesz miał widoczne w swoim profilu gracza.
          </Typography>
          {
            reset && (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            )
          }
        </>
      )
      : (
        <>
          <Box sx={style.box}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Wstecz
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Pomiń
              </Button>
            )}
            {
              activeStep !== steps.length - 1 && (
                <Button disabled={steps?.length === 0} onClick={handleNext}>
                  Następny krok
                </Button>
              )
            }
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {steps[activeStep]?.render}
          </Box>
        </>
      )}
    </Box>
  )
}

export default Stepper
