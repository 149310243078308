import React from 'react'
import PropTypes from 'prop-types'
import './SimpleLayout.scss'
import ChangeLang from '../../components/organisms/ChangeLang/ChangeLang'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'

const SimpleLayout = (props) => {
  const Component = props.component
  const { location } = props
  const style = {
    root: {
      display: 'flex',
      overflow: 'hidden',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
  }
  return (
		<div style={style.root}>
      {
        props.component.name !== 'ErrorsPage' && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid container spacing={4}>
              <Grid item>
                <ChangeLang outSide />
              </Grid>
            </Grid>
          </Box>
        )
      }
			<Component location={location} />
    </div>
  )
}

SimpleLayout.defaultProps = {
  location: false,
}

SimpleLayout.propsType = {
  location: PropTypes.object,
}

export default SimpleLayout
