export default {
  register: (payload) => {
    window.dataLayer.push({
      dataLayer: { event: 'register', ...payload },
    })
  },
  login: (payload) => {
    window.dataLayer.push({
      dataLayer: { event: 'login', ...payload },
    })
  },
  addToEvent: (payload) => {
    window.dataLayer.push({
      dataLayer: { event: 'addToEvent', ...payload },
    })
  }
}
