import { getAllGames, saveNewGame } from '../../firebase'
import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper'

export default {
  addGameToYourStack: async (e, enqueueSnackbar, setUpdateGamesLists = null, updateGamesLists = null) => {
    const games = await getAllGames()
    const name = e?.name
    if (name?.length > 0) {
      let tempWho = []
      let tempGame = {}
      games.map(game => {
        if (game?.name === name) {
          tempWho = game?.who || []
          tempGame = game
        }
        return true
      })
      const exist = !!tempWho.find(el => el === LocalStorageHelper.get('user')?.name)
      if (!exist) {
        tempWho.push(LocalStorageHelper.get('user')?.name)
      }
      tempGame.who = tempWho
      saveNewGame(tempGame, tempGame.id).then((res) => {
        if (res) {
          enqueueSnackbar(`Dodanie gry: ${tempGame?.name}`, { variant: 'success' })
          if (updateGamesLists && setUpdateGamesLists) {
            setTimeout(() => setUpdateGamesLists(!updateGamesLists), 300)
          }
        } else enqueueSnackbar('Problem z dodaniem nowej gry.', { variant: 'error' })
      })
    }
  }
}
