import React from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Grid } from '@mui/material'
import Button from '../../atoms/Forms/Button/Button'
import bannerImage from '../../../assets/images/website/website6.jpg'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Slider = (props) => {
	const t = LocalStorageHelper.get('translation')
	const style = {
		root: {
			background: 'rgb(247, 249, 252)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row',
			position: 'relative',
			top: -20,
			padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
			minHeight: 'calc(100vh - 200px)',
		},
		bigTitle: {
			fontSize: BreakpointsHelper.isMobile() ? '24px' : '50px',
			lineHeight: BreakpointsHelper.isMobile() ? '24px' : '50px',
			fontWeight: '900',
			color: 'rgb(18, 26, 68)',
			textTransform: 'uppercase',
			maxWidth: '560px',
		},
		childTitle: {
			fontSize: BreakpointsHelper.isMobile() ? '24px' : '50px',
			lineHeight: BreakpointsHelper.isMobile() ? '24px' : '50px',
			fontWeight: '900',
			color: '#eca720',
			textTransform: 'uppercase',
		},
		smallTitle: {
			marginTop: '15px',
			fontSize: '22px',
			lineHeight: '22px',
			fontWeight: '400',
			color: 'rgb(18, 26, 68)',
			maxWidth: '560px',
		},
		image: {
			width: '100%',
		},
		item: {
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'center',
			flexDirection: 'column',
		},
		container: {
			display: 'flex',
			flexDirection: BreakpointsHelper.isMobile() ? 'column-reverse' : 'row',
		},
	}
	return (
		<div style={style.root}>
			<Grid container spacing={4} style={style.container}>
				<Grid item md={6} xs={12} style={style.item}>
					<div style={style.bigTitle} className="animate__animated animate__fadeIn animate__slower">
						{t?.homePage?.banner?.bigTitle1} <span style={style.childTitle}>{t?.homePage?.banner?.bigTitle2}</span> {t?.homePage?.banner?.bigTitle3}
					</div>
					<div style={style.smallTitle} className="animate__animated animate__fadeIn animate__slower animate__delay-1s">
						{t?.homePage?.banner?.description}
					</div>
					<Button
						className="animate__animated animate__fadeIn animate__slower animate__delay-2s"
						sx={{ minWidth: BreakpointsHelper.isMobile() ? '0' : '100px', marginTop: '15px' }}
						onClick={() => props.location.history.push('/register')}
					>
						{t?.homePage?.banner?.button}
					</Button>
				</Grid>
				<Grid item md={6} xs={12}>
					<img src={bannerImage} className="animate__animated animate__fadeIn animate__slower" alt="banner image" style={style.image} />
				</Grid>
			</Grid>
		</div>
	)
}

export default Slider
