import QRCode from 'react-qr-code'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import logo from '../../assets/images/logo.png'
import { getAllDataFromPath } from '../../firebase'

const ShowTicketPage = () => {
  const pathName = window.location.pathname
  const id = pathName.split('/')[2].replace('%20', ' ')
  const [data, setData] = useState(null)
  const user = LocalStorageHelper.get('user')
  const today = new Date()
  useEffect(() => {
    getAllDataFromPath('tournament').then((res) => {
      if (res) {
        res.forEach((el) => {
          if (el.id === id) {
            setData(el.data)
          }
        })
      }
    })
  }, [])
  useEffect(() => {
    if (data) {
      document.querySelector('.Navigation').style.display = 'none'
      const link = pathName.replace('/showTicket', '')
      // window.print()
      // window.open(link, '_self')
    }
  }, [data])
  return (
    <Box sx={{
      padding: '20px',
      position: 'relative',
      left: '-40px',
      top: '20px',
      boxShadow: '2px 2px 5px 2px rgba(0,0,0,.15)',
      maxWidth: '1024px'
    }}>
      <Grid container spacing={4}>
        <Grid item md={2} xs={12}>
          <img src={logo} alt="logo" style={{ padding: '20px', width: '100%' }} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            sx={{
              fontSize: '45px',
              fontWeight: '900',
              lineHeight: '1.0',
              textTransform: 'uppercase',
              color: '#191B56FF',
            }}
          >
            Planszówki Koszalin
          </Typography>
          <Typography
            sx={{
              fontSize: '26px',
              marginBottom: '20px'
            }}
          >
            {data.name}, bilet uczestnictwa
          </Typography>
          <Typography sx={{ fontSize: '26px', color: '#191B56FF' }}><b>Imię i nazwisko:</b> {user?.name}</Typography>
          <Typography sx={{ fontSize: '26px', color: '#191B56FF' }}><b>Email:</b> {user?.email}</Typography>
          <Typography sx={{ fontSize: '26px', color: '#191B56FF' }}><b>Telefon:</b> {user?.phone}</Typography>
          <Typography sx={{ fontSize: '26px', color: '#191B56FF' }}><b>Data druku:</b> {`${today.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}-${(today.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}-${today.getFullYear()}`}</Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <QRCode
            fgColor="#191B56FF"
            value={pathName.replace('/showTicket', '').replace('previewEvent', 'beOnTournament') + '/' + LocalStorageHelper.get('user').email}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ShowTicketPage
