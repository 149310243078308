import React, { useEffect, useState } from 'react'
import { Grid, useTheme } from '@mui/material'
import {
  CasinoOutlined,
  CastleOutlined,
  DateRange,
  EmojiPeopleOutlined,
  Error,
  EventSeat,
  HomeWork,
  LocalLibraryOutlined,
  Person,
  RedeemOutlined,
  SecurityOutlined,
  TerrainOutlined,
  TourOutlined
} from '@mui/icons-material'
import Default from '../../../assets/images/events/background.png'
import PropTypes from 'prop-types'
import DateHelper from '../../../helpers/DateHelper/DateHelper'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import AddressHelper from '../../../helpers/AdressHelper/AddressHelper'
import { getAllDataFromPath } from '../../../firebase'

const defaultLibraryDesc = 'Każde wydarzenie w Bibliotece Głównej w Koszalinie jest rozpoczynane w wybranej sali jednej z trzech dostępnych. Przy wejściu możecie w Mediatece dowiedzieć się jak trafić do wybranej sali. Każda osoba przychodząca na wydarzenie dostanie pieczątkę na rękę oraz zostanie sprawdzona, czy znajduje się na liście grających.'

const Event = (props) => {
  const t = LocalStorageHelper.get('translation')
  const {
    item,
    name,
    type,
    street,
    whoCreated,
    house,
    eventVIP,
    apartment,
    tournament,
    background,
    child,
    themedEvent,
    setCurrentEvent,
    description = defaultLibraryDesc,
    seat,
    maxSeat,
    address,
    hours,
    date,
    day,
    users,
    id,
    open = null
  } = props
  const title = name
  const isEventsPage = window.location.pathname.search('events') > 0
  const theme = useTheme()
  const [author, setAuthor] = useState(null)
  const style = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '2px 2px 5px 2px rgba(0,0,0,.15)',
      padding: '50px 20px 20px 20px',
      color: theme.palette.primary.main,
      position: 'relative',
      background: `url(${background || Default})`,
      backgroundSize: '100%',
      justifyContent: 'space-between',
      width: 'calc(100% - 40px)',
      cursor: 'pointer',
    },
    title: {
      fontWeight: '700',
      fontSize: '25px',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    titleText: {
      marginTop: '20px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    dateAndPlace: {
      fontWeight: '400',
      fontSize: '18px',
      display: 'flex',
      marginBottom: '5px',
    },
    time: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    icon: {
      position: 'relative',
      top: '0px',
      color: theme.palette.primary.main,
      marginRight: '12px',
    },
    iconImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      width: '100%',
      maxWidth: '200px',
    },
    child: {
      background: theme.palette.secondary.second,
      color: 'white',
      position: 'absolute',
      top: '10px',
      right: '10px',
      padding: '10px 15px',
      fontWeight: '700',
      textTransform: 'uppercase',
    },
    future: {
      background: theme.palette.primary.main,
      color: 'white',
      position: 'absolute',
      top: '10px',
      left: '10px',
      padding: '10px 15px',
      fontWeight: '700',
      textTransform: 'uppercase',
    },
    old: {
      background: theme.palette.toasts.error,
      color: 'white',
      position: 'absolute',
      top: '10px',
      left: '10px',
      padding: '10px 15px',
      fontWeight: '700',
      textTransform: 'uppercase',
      zIndex: '999',
      opacity: '.6',
    },
    themedEvent: {
      background: theme.palette.secondary.main,
      color: 'white',
      position: 'absolute',
      top: '10px',
      right: '10px',
      padding: '10px 15px',
      fontWeight: '700',
      textTransform: 'uppercase',
    },
    eventVIP: {
      background: theme.palette.primary.main,
      color: 'gold',
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      padding: '10px 15px',
      fontWeight: '700',
      textTransform: 'uppercase',
    },
    full: {
      background: 'rgba(243,243,243,0.8)',
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'calc(100% - 30px)',
      height: 'calc(100% - 20px)',
      position: 'absolute',
      top: '0',
      right: '0',
      padding: '10px 15px',
      fontWeight: '700',
      fontSize: '30px',
      textTransform: 'uppercase',
      flexDirection: 'column',
    },
    error: {
      marginBottom: '10px',
    },
  }
  const handleOpen = () => {
    const data = {
      type,
      title,
      description,
      seat,
      maxSeat,
      address: type === 'library' ? 'Koszalińska Biblioteka Publiczna, Plac Polonii 1, Koszalin' : address,
      hours,
      date,
      day,
      users,
      id,
      street: street || '',
      house,
      apartment,
      tournament: tournament || false,
      image: Default,
    }
    setCurrentEvent(data)
  }
  const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD/MM/YYYY')
  const dayToEvent = DateHelper.getNumberOfDays(`${dateToday.split('/')[1]}/${dateToday.split('/')[0]}/${dateToday.split('/')[2]}`, `${date.split('.')[1]}/${date.split('.')[0]}/${date.split('.')[2]}`)
  const handleTime = (startHour, endHour) => {
    let startHourTemp = startHour
    let endHourTemp = endHour
    if (startHourTemp === '00:00') startHourTemp = '24:00'
    if (endHourTemp === '00:00') endHourTemp = '24:00'
    const startMin = Number(startHourTemp?.split(':')[1])
    const endMin = Number(endHourTemp?.split(':')[1])
    const startHr = Number(startHourTemp?.split(':')[0])
    const endHr = Number(endHourTemp?.split(':')[0])
    const countHours = endHr > startHr ? endHr - startHr : 24 - startHr + endHr
    const countMin = startMin === endMin ? 0 : 60 - (startMin + endMin)
    return `${countHours}h ${countMin !== 0 ? `${countMin}min` : ''}`
  }
  const handleSeatText = (seat) => {
    if (seat === 1) return t.globals.components.event.freePlaces
    if (seat < 5) {
      return t.globals.components.event.freePlaces
    } else {
      return t.globals.components.event.freePlacesOther
    }
  }
  const handleMonth = (month) => t.globals.components.event.months['month' + month]
  useEffect(() => {
    if (whoCreated) {
      getAllDataFromPath('users').then((res) => {
        if (res) {
          res.forEach((el) => {
            const data = el.data
            if (data.email === whoCreated) {
              setAuthor(data)
            }
          })
        }
      })
    }
  }, [whoCreated])
  return (
    <>
      <div style={style.root} className="event"
           onClick={() => open ? (dayToEvent >= 0 && window.open(`/previewEvent/${id}${tournament ? '/tournament' : ''}`, '_self')) : (dayToEvent >= 0 && seat !== maxSeat && handleOpen())}>
        <div style={{ flex: 1 }}>
          <div style={style.title}>
            <div style={style.titleText}>
              {
                item?.userEvent && (
                  <EmojiPeopleOutlined />
                )
              }
              {
                item?.simpleEvent && (
                  <CasinoOutlined />
                )
              }
              {
                themedEvent && (
                  <RedeemOutlined />
                )
              }
              {
                item?.library && (
                  <LocalLibraryOutlined />
                )
              }
              {
                item?.terrainEvent && (
                  <TerrainOutlined />
                )
              }
              {
                item?.castleEvent && (
                  <CastleOutlined />
                )
              }
              {
                eventVIP && (
                  <SecurityOutlined />
                )
              }
              {
                tournament && (
                  <TourOutlined />
                )
              }
              <span style={{ marginLeft: '10px' }}>
                {title}
              </span>
            </div>
          </div>
          <div style={style.dateAndPlace}>
            <Person style={style.icon}/>
            <div style={style.time}>
              {author?.name}
            </div>
          </div>
          <Grid container style={style.dateAndPlace}>
            <Grid item xs={12} style={style.time}>
              <DateRange style={style.icon}/>
              <div>
                <div>
                  {`${date.split('.')[0]} ${handleMonth(Number(date.split('.')[1]))} (${day.toLowerCase()})`}
                  <br/>
                  {`${t.globals.components.event.start} ${hours?.start}, ${t.globals.components.event.allCountTime}: ${handleTime(hours?.start, hours?.end)}`}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} style={style.time}>
              <HomeWork style={style.icon}/>
              <div>
                {AddressHelper.shortVersion(type, address, street)}
              </div>
            </Grid>
          </Grid>
          {(!open || isEventsPage) && (
            <Grid container style={style.dateAndPlace}>
              <Grid item md={6} xs={12} style={style.time}>
                <EventSeat style={style.icon}/>
                <div>
                  <div>
                    {maxSeat - seat === 0 ? 'Brak wolnych miejsc' : `${maxSeat - seat} ${handleSeatText(maxSeat - seat)}`}
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
        {
          child && (
            <div style={style.child}>
              {t?.globals?.child}
            </div>
          )
        }
        {
          eventVIP && (
            <div style={style.eventVIP}>
              Spotkanie VIP
            </div>
          )
        }
        <div style={dayToEvent < 0 ? style.old : style.future}>
          {
            dayToEvent === 0 && t.globals.components.event.today
          }
          {
            dayToEvent > 0 && `${t.globals.components.event.behind} ${dayToEvent} ${t.globals.components.event.days}`
          }
          {
            dayToEvent < 0 && t.globals.components.event.archive
          }
        </div>
        {
          ((seat === maxSeat && (!open || isEventsPage)) || dayToEvent < 0) && (
            <div
              style={style.full}
              onClick={() => dayToEvent < 0 && open && props?.location?.history?.push(`/gallery/${id}`)}
            >
              {
                dayToEvent >= 0 && (
                  <>
                    <Error style={style.error}/>
                    <div style={{ textAlign: 'center' }}>
                      {t.globals.components.event.noPlaces}
                    </div>
                  </>
                )
              }
            </div>
          )
        }
      </div>
    </>
  )
}

Event.defaultProps = {
  type: 'home',
}

Event.propTypes = {
  type: PropTypes.string,
}

export default Event
