import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import {
  deleteEvent,
  deleteEventTournament,
  editEvent,
  editTournament,
  getAllEvents,
  getAllTournaments
} from '../../firebase'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import Dialog from '../../components/atoms/Dialog/Dialog'
import ListGamesHelper from '../../helpers/ListGamesHelper/ListGamesHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useSnackbar } from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import LocationHelper from '../../helpers/LocationHelper/LocationHelper'
import ShowEvent from '../../components/organisms/ShowEvent/ShowEvent'
import Box from '@mui/material/Box'

const PreviewEventPage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const tournament = props?.location?.history?.location?.pathname?.search('tournament') >= 0
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(null)
  const [games, setGames] = useState([])
  const [events, setEvents] = useState(null)
  const id = window?.location?.pathname?.split('/')[2]?.replace('%20', ' ')
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const updateEvents = async () => {
    let allElementsEvents = null
    if (tournament) {
      allElementsEvents = getAllTournaments(LocalStorageHelper.get('user').email)
    } else {
      allElementsEvents = getAllEvents(LocalStorageHelper.get('user').email)
    }
    await allElementsEvents?.then((res) => {
		  if (res) {
			  const id = window.location.pathname.split('/')[2].replace('%20', ' ')
			  res?.map(el => {
				  if (el?.id === id) {
					  setEvents(el)
					  setMessages(el?.data?.messages)
				  }
				  return true
			  })
		  }
	  })
  }
  const renderAddEvent = () => {
    const element = events?.data
    const formData = { elements: [] }
    formData.elements.push(
      {
        name: 'child',
        type: 'checkbox',
        label: t.globals.inputs.child.label,
        defaultValue: element?.child,
        validationType: 'string',
      },
      {
        name: 'name',
        type: 'text',
        label: t.globals.inputs.nameEvent.label,
        value: element?.name,
        validationType: 'string',
      },
      {
        name: 'date',
        type: 'date',
        disabled: true,
        label: t.globals.inputs.date.label,
        value: `${element?.date.split('.')[2]}-${element?.date.split('.')[1]}-${element?.date.split('.')[0]}`,
        validationType: 'date',
      },
      {
        name: 'start',
        type: 'hour',
        value: element?.hours?.start,
        label: t.globals.inputs.start.label,
        validationType: 'string',
      },
      {
        name: 'end',
        type: 'hour',
        value: element?.hours?.end,
        label: t.globals.inputs.end.label,
        validationType: 'string',
      },
      {
        name: 'maxSeat',
        type: 'number',
        value: element?.maxSeat,
        label: t.globals.inputs.maxSeat.label,
        min: element?.users?.length || 0,
        validationType: 'string',
      },
      {
        name: 'description',
        type: 'editor',
        value: element?.description,
        label: t.globals.inputs.editor.label,
        validationType: 'string',
      },
      {
        type: 'button',
        value: t.previewEventPage.buttons.saveEvent,
      },
    )
    const onSubmit = (e) => {
      const { child, date, description, end, maxSeat, name, start } = e
      const sendData = {
        child,
        description,
        maxSeat,
        hours: {
          end,
          start,
        },
        name,
        date: `${date.split('-')[2]}.${date.split('-')[1]}.${date.split('-')[0]}`,
      }
      if (tournament) {
        editTournament(sendData, id, events?.data).then((res) => {
          if (res) {
            setOpen(false)
            enqueueSnackbar(t.previewEventPage.toasts.createSuccess, { variant: 'success' })
            updateEvents().then(() => {})
          } else {
            setOpen(false)
            enqueueSnackbar(t.previewEventPage.toasts.createError, { variant: 'error' })
          }
        })
      } else {
        editEvent(sendData, id, events?.data).then((res) => {
          if (res) {
            setOpen(false)
            enqueueSnackbar(t.previewEventPage.toasts.createSuccess, { variant: 'success' })
            updateEvents().then(() => {})
          } else {
            setOpen(false)
            enqueueSnackbar(t.previewEventPage.toasts.createError, { variant: 'error' })
          }
        })
      }
    }
    return <FormGenerator submit={(e) => onSubmit(e)} data={formData} {...props} />
  }
  const handleDelete = (id) => {
    if (tournament) deleteEventTournament(id)
    else deleteEvent(id)
  }
  const checkAddToEvent = () => {
	  const path = window?.location?.pathname
	  if (path.search('addToEvent') > 0) {
		  enqueueSnackbar(t.previewEventPage.toasts.success, { variant: 'success' })
	  }
  }
  useEffect(() => {
    updateEvents().then(() => true)
    const reRender = setInterval(() => updateEvents(), 60000)
	  LocationHelper.getLocation()
	  checkAddToEvent()
	  const loading = setTimeout(() => setIsLoading(false), 1500)
	  return () => {
      clearTimeout(loading)
      clearInterval(reRender)
    }
  }, [])
  useEffect(() => {
    if (events) {
      ListGamesHelper.getEventGames(LocalStorageHelper.get('gameList'), events?.data?.users, events?.data).then((res) => {
        if (res) setGames(res)
      })
    }
  }, [events])
  return (
		<Grid container>
      <Grid item md={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
          }}
        >
          <ShowEvent
            events={events}
            games={games}
            messages={messages}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            tournament={tournament}
            handleDelete={() => handleDelete(id)}
            handleEdit={() => setOpen(true)}
            id={id}
            updateEvents={() => updateEvents()}
            {...props}
          />
        </Box>
        {
          open && (
            <Dialog open={open} title={t.previewEventPage.editEvent} content={renderAddEvent()} onClose={() => setOpen(false)} />
          )
        }
      </Grid>
		</Grid>
  )
}

export default PreviewEventPage
