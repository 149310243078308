import { useEffect } from 'react'
import Navigation from '../../components/molecules/Navigation/Navigation'
import PropTypes from 'prop-types'
import './MainLayout.scss'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { refreshToken } from '../../firebase'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import LangHelper from '../../helpers/LangHelper/LangHelper'
import Chat from '../../components/organisms/Chat/Chat'

const MainLayout = (props) => {
  const Component = props.component
  const { location } = props
  const defaultLang = LocalStorageHelper.get('user')?.defaultLang
  const style = {
    root: {
      display: 'flex',
      overflow: 'hidden',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      background: '#f8f8fb',
      marginTop: BreakpointsHelper.isMobile() && '50px',
      marginLeft: BreakpointsHelper.isMobile() ? 0 : 70,
      position: 'relative',
      width: '100%',
      minHeight: '100vh',
    },
  }
  useEffect(() => {
    refreshToken()
    if (defaultLang) {
      LangHelper.changeLang(defaultLang)
    }
  }, [])
  return (
		<div style={style.root}>
			<Navigation location={location} defaultLang={defaultLang} />
			<div style={style.main}>
				<Component location={location} />
        <Chat {...props}/>
      </div>
		</div>
  )
}

MainLayout.defaultProps = {
  location: false,
}

MainLayout.propsType = {
  location: PropTypes.object,
}

export default MainLayout
