import { useEffect, useState } from 'react'
import PartnersEnum from '../../../enums/PartnersEnum/PartnersEnum'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const Cooperators = () => {
  const [globalCompanies, setGlobalCompanies] = useState(null)
  const [localCompanies, setLocalCompanies] = useState(null)
  const [mediaPartners, setMediaPartners] = useState(null)
  const [publisherHouses, setPublisherHouses] = useState(null)
  const t = LocalStorageHelper.get('translation')
  const style = {
    box: {
      boxShadow: '2px 2px 5px 2px rgba(0,0,0,.15)',
      padding: '20px',
      width: 'calc(100% - 40px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: 'calc(100% - 40px)',
      position: 'relative',
    },
    heading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '42px',
      color: 'rgb(18, 26, 68)',
      marginBottom: '30px',
      fontWeight: '700',
    },
  }
  const generateTemplatePartner = (objectData) => {
    return (
      <>
        {
          objectData?.map((el, index) => {
            return (
              <Grid item md={4} xs={12} key={index}>
                <Box sx={style.box}>
                  <Box>
                    <Typography sx={{ fontWeight: 600, fontSize: '20px' }}>{el.company}</Typography>
                    <Typography>{el.url}</Typography>
                  </Box>
                  {
                    el?.contacts?.map((con, indexCon) => (
                      <Box key={indexCon} sx={{ marginTop: '20px' }}>
                        {
                          con.name && (
                            <Typography><b>Osoba do kontaktu: </b>{con.name}</Typography>
                          )
                        }
                        {
                          con.position && (
                            <Typography><b>Stanowisko: </b>{con.position}</Typography>
                          )
                        }
                        {
                          con.email && (
                            <Typography><b>Email: </b> <a href={`mailto:${con.email}`} target="_blank" rel="noreferrer">{con.email}</a></Typography>
                          )
                        }
                        {
                          con.phone && (
                            <Typography><b>Telefon: </b><a href={`tel:+48${con.phone.replace(' ', '').replace(' ', '').replace(' ', '')}`} target="_blank" rel="noreferrer">{con.phone}</a></Typography>
                          )
                        }
                      </Box>
                    ))
                  }
                  {
                    el.logo && (
                      <img src={el.logo} alt={el.company} style={{ position: 'absolute', top: '20px', right: '20px', width: '80px', height: 'auto' }} />
                    )
                  }
                </Box>
              </Grid>
            )
          })
        }
      </>
    )
  }
  useEffect(() => {
    setPublisherHouses(PartnersEnum.publisherHouses)
    setMediaPartners(PartnersEnum.mediaPartners)
    setLocalCompanies(PartnersEnum.localCompanies)
    setGlobalCompanies(PartnersEnum.globalCompanies)
  }, [])
  return (
   <>
     <Grid item xs={12}>
       <Typography sx={style.heading}>
         {t.globals.publisherHouses}
       </Typography>
     </Grid>
     { publisherHouses?.length > 0 && generateTemplatePartner(publisherHouses) }
     <Grid item xs={12}>
       <Typography sx={style.heading}>
         {t.globals.mediaPartners}
       </Typography>
     </Grid>
     { mediaPartners?.length > 0 && generateTemplatePartner(mediaPartners) }
     <Grid item xs={12}>
       <Typography sx={style.heading}>
         {t.globals.globalCompanies}
       </Typography>
     </Grid>
     { globalCompanies?.length > 0 && generateTemplatePartner(globalCompanies) }
     <Grid item xs={12}>
       <Typography sx={style.heading}>
         {t.globals.localCompanies}
       </Typography>
     </Grid>
     { localCompanies?.length > 0 && generateTemplatePartner(localCompanies) }
   </>
  )
}

export default Cooperators
