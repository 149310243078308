import { useEffect, useState } from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import PartnersEnum from '../../../enums/PartnersEnum/PartnersEnum'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Partners = () => {
	const [globalCompanies, setGlobalCompanies] = useState(null)
	const [localCompanies, setLocalCompanies] = useState(null)
	const [mediaPartners, setMediaPartners] = useState(null)
	const [publisherHouses, setPublisherHouses] = useState(null)
	const t = LocalStorageHelper.get('translation')
	const style = {
		root: {
			padding: BreakpointsHelper.isMobile() ? '15px' : '150px 50px',
			width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 100px)',
		},
		a: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		image: {
			display: 'flex',
			objectFit: 'contains',
			width: BreakpointsHelper.isMobile() ? '50%' : '100%',
			cursor: 'pointer',
			alignItems: 'center',
			maxWidth: BreakpointsHelper.isMobile() ? 'none' : '130px',
			justifyContent: BreakpointsHelper.isMobile() && 'center',
		},
		heading: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
			fontSize: BreakpointsHelper.isMobile() ? '24px' : '42px',
			color: 'rgb(18, 26, 68)',
			marginBottom: '30px',
			fontWeight: '700',
		},
		heading2: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
			fontSize: BreakpointsHelper.isMobile() ? '24px' : '42px',
			color: 'rgb(18, 26, 68)',
			marginBottom: '30px',
			fontWeight: '700',
			marginTop: '50px',
		},
		imageMobile: {
			display: 'flex',
			objectFit: 'contains',
			width: '70%',
			cursor: 'pointer',
			alignItems: 'center',
			justifyContent: BreakpointsHelper.isMobile() && 'center',
		},
		box: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			width: '100%',
		}
	}
	const generateTemplateLogos = (objectData) => {
		return (
			<>
				{
					objectData?.map((partner, index) => (
						<Grid key={index} item md={2} xs={6}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: 'column',
									height: '100%',
								}}
							>
								{
									partner?.logo && (
										<a href={partner?.url} target="_blank" style={style.a} rel="referrer noreferrer">
											<img src={partner.logo} alt={partner?.name || ''} style={style.image}/>
										</a>
									)
								}
							</Box>
						</Grid>
					))
				}
			</>
		)
	}
	useEffect(() => {
		setPublisherHouses(PartnersEnum.publisherHouses)
		setMediaPartners(PartnersEnum.mediaPartners)
		setLocalCompanies(PartnersEnum.localCompanies)
		setGlobalCompanies(PartnersEnum.globalCompanies)
	}, [])
	return (
		<div style={style.root} id="partners">
			<Grid container spacing={16}>
				<Grid item md={6} xs={12}>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<Typography sx={style.heading}>
								{t.globals.localCompanies}
							</Typography>
						</Grid>
						{ localCompanies?.length > 0 && generateTemplateLogos(localCompanies) }
					</Grid>
				</Grid>
				<Grid item md={6} xs={12}>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<Typography sx={style.heading}>
								{t.globals.publisherHouses}
							</Typography>
						</Grid>
						{ publisherHouses?.length > 0 && generateTemplateLogos(publisherHouses) }
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<Typography sx={style.heading2}>
								{t.globals.mediaPartners}
							</Typography>
						</Grid>
						{ mediaPartners?.length > 0 && generateTemplateLogos(mediaPartners) }
					</Grid>
					<Grid container spacing={8}>
						<Grid item xs={12}>
							<Typography sx={style.heading2}>
								{t.globals.globalCompanies}
							</Typography>
						</Grid>
						{ globalCompanies?.length > 0 && generateTemplateLogos(globalCompanies) }
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}

export default Partners
