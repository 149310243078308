import ArrayHelper from '../ArrayHelper/ArrayHelper'
import { getAllGames } from '../../firebase'
import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper'

export default {
  getCategories: (data = null, notFirst = false) => {
    let listCategory = []
	  if (!notFirst) {
		  listCategory = [{
			  value: 'wszystkie',
			  label: 'wszystkie',
		  }]
	  }
    const tempCategoryList = []
		if (!data) {
			getAllGames().then((res) => {
				res?.map(el => {
					if (!tempCategoryList.find(cat => cat === el)) {
						if (el.category !== '') {
							tempCategoryList.push(el.category)
						}
					}
					return true
				})
				ArrayHelper?.deleteDuplicate(tempCategoryList)?.map(el => listCategory.push({ value: el, label: el }))
			})
		} else {
			data?.map(el => {
				if (!tempCategoryList.find(cat => cat === el)) {
					if (el.category !== '') {
						tempCategoryList.push(el.category)
					}
				}
				return true
			})
			ArrayHelper?.deleteDuplicate(tempCategoryList)?.map(el => listCategory.push({ value: el, label: el }))
		}
    return listCategory
  },
  getYourGames: async (name) => {
		const tempGames = []
		return await getAllGames().then(data => {
			data?.map(el => {
				const persons = el?.who
				if (persons?.length > 0) {
					persons.map(person => {
						if (name === 'Planszówki Koszalin') {
							if (person === name || person === 'Jan Szymański') {
								tempGames.push(el)
							}
						} else {
							if (person === name) {
								tempGames.push(el)
							}
						}
						return true
					})
				}
				return true
			})
			return tempGames
		})
	},
	getGames: () => {
		return LocalStorageHelper.get('gameList')
	},
  getEventGames: async (data, users, eventData) => {
	  const listNames = []
	  const games = []
	  await users?.map(el => el?.name?.trim()?.length > 0 && listNames.push(el?.name?.trim()))
	  if (eventData?.type !== 'library') {
		  await data?.map(game => {
			  if (game?.minPerson <= listNames?.length && game?.maxPerson >= listNames?.length) {
				  game?.who?.map(person => {
					  users?.map(user => {
						  if (user?.name === person) {
							  games.push(game)
						  } else {
							  game?.who?.map(el => {
								  if (el === 'Koszalińska Biblioteka Publiczna' && eventData?.type === 'library') games.push(game)
								  return true
							  })
						  }
						  return true
					  })
					  return true
				  })
			  }
			  return true
		  })
	  } else {
		  await data?.map(game => {
			  game?.who?.map(person => {
				  users?.map(user => {
					  if (user?.name?.trim() === person) {
						  games.push(game)
					  }
					  return true
				  })
				  return true
			  })
			  return true
		  })
	  }
	  return ArrayHelper.deleteDuplicate(games)
  },
}
