import { Divider, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { ShoppingCartOutlined } from '@mui/icons-material'
import Button from '@mui/material/Button'
import ProductHelper from '../../helpers/ProductHelper/ProductHelper'
import useStore from '../../Zustand'
import { useHistory } from 'react-router'

const PreviewProductPage = () => {
  const addToCart = useStore((state) => state.AddToCart)
  const history = useHistory()
  const data = {
    id: 123213123,
    name: 'Kingdom Rush: Elemental Uprising',
    smallText: 'Dalszy ciąg Kingdom Rush: Rift in Time',
    description: '<p><strong><em>Produkt dostępny w przedsprzedaży. Przewidywana dostawa: w pierwszej połowie I kwartału 2024.</em></strong></p> <p>Kingdom Rush: Elemental Uprising to samodzielna gra, dostępna w języku angielskim. Kingdom Rush: Rift in Time nie jest potrzebna go rozgrywki.</p> <p><strong>Kingdom Rush: Elemental Uprising</strong> is the standalone sequel to Kingdom Rush: Rift in Time. It is scenario-based and can be played as individual replayable scenarios or as a steadily evolving campaign leading up to big boss fights, hero challenges, and more. The game offers simple to learn rules with a high level of tactics for experienced gamers. Upon starting each scenario, players will be able to choose from a range of difficulty levels or even take on the dreaded Iron Challenges which require near-perfect strategies to overcome.</p> <p>&nbsp;</p>',
    publisher: {
      name: 'Lucky Duck Games',
      image: 'https://website.cdn77.luckyduckgames.com/img/luckyduck-logo-new.png',
    },
    image: 'https://website.cdn77.luckyduckgames.com/shop-products/December2023/Kingdom-Rush-Elemental-Uprising1-medium.png',
    priceData: {
      price: '299.99',
      count: 'zł',
    },
    news: false,
    preSale: true,
    possibleToBuy: true,
  }
  const style = {
    root: {
      padding: '80px',
    },
    name: {
      fontSize: '32px',
      fontWeight: '300',
    },
    desc: {
      paddingRight: BreakpointsHelper.isMobile() ? '100%' : '100px',
      fontSize: '18px',
      textAlign: 'left',
      marginTop: '10px',
    },
    descBox: {
      fontSize: '16px',
      textAlign: 'left',
      marginTop: '10px',
    },
    delivery: {
      display: 'flex',
      fontSize: '18px',
    },
    heading: {
      fontWeight: '900',
      color: '#121a44',
      display: 'flex',
      fontSize: '24px',
    },
    boxRoot: {
      display: 'flex',
      flexDirection: 'column',
      padding: '30px',
      background: '#f9f9fb',
      position: 'sticky',
      top: '150px',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: '20px',
    },
    img: {
      maxWidth: '100%',
      width: 'auto',
      marginBottom: '10px',
      maxHeight: '200px',
    },
    price: {
      textAlign: 'center',
      fontSize: '38px',
      fontWeight: '900',
      color: '#121a44',
      marginBottom: '30px',
      lineHeight: 1.0,
    },
    priceNumber: {
      fontWeight: '400',
    },
    priceSmall: {
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: '600',
      color: '#121a44',
      lineHeight: 1.0,
      textDecoration: 'line-through',
      marginTop: '5px',
      marginBottom: '10px',
    },
  }
  return (
    <Box sx={style.root}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Typography variant="h1" sx={style.name}>{data?.name}</Typography>
        </Grid>
        <Grid item md={9} xs={12}>
          <Typography sx={style.desc} dangerouslySetInnerHTML={{ __html: data?.description }}/>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box sx={style.boxRoot}>
            <Box sx={style.box}>
              <Typography sx={style.price}>Cena: <span style={style.priceNumber}>{data?.priceData?.price} {data?.priceData?.count}</span></Typography>
              <img onClick={() => ProductHelper.openPreviewProduct(data?.id, history)} src={data?.image} alt={data?.name} style={style.img}/>
              <Button onClick={() => addToCart(data)} sx={{
                marginTop: '15px',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '30px',
                width: '250px'
              }} variant="contained">
                <Typography>Dodaj do koszyka</Typography>
                <ShoppingCartOutlined/>
              </Button>
            </Box>
            <Box>
              <Divider sx={{ marginBottom: '30px' }}/>
              <Typography sx={style.descBox}>
                <b>Kategoria</b> <br/>
                Brak
              </Typography>
              <Typography sx={style.descBox}>
                <b>Średni czas gry</b> <br/>
                Brak
              </Typography>
              <Typography sx={style.descBox}>
                <b>Wiek</b> <br/>
                Brak
              </Typography>
              <Typography sx={style.descBox}>
                <b>Wydawnictwo</b> <br/>
                {data?.publisher?.name}
              </Typography>
              <Typography sx={style.descBox}>
                <b>Dostawa</b> <br/>
                Bezpłatna dostawa od 400 zł
              </Typography>
              <Typography sx={style.descBox}>
                <b>Zwroty</b> <br/>
                Możliwość zwrotu zamówienia na koszt kupującego do 14 dni
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PreviewProductPage
