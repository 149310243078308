import * as React from 'react'
import { useEffect, useState } from 'react'
import Button from '../Forms/Button/Button'
import Dialog from '../Dialog/Dialog'
import { useTheme } from '@mui/material/styles'
import FormGenerator from '../../organisms/FormGenerator/FormGenerator'
import { updateUsers } from '../../../firebase'
import { useSnackbar } from 'notistack'
import { TableFooter, TablePagination, TableHead, TableRow, Paper, IconButton, Table, TableBody, TableCell, TableContainer, Box } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight, FirstPage, LastPage, Close, Check } from '@mui/icons-material'

const TablePaginationActions = (props) => {
	const theme = useTheme()
	const { count, page, rowsPerPage, onPageChange } = props
	const handleFirstPageButtonClick = (event) => onPageChange(event, 0)
	const handleBackButtonClick = (event) => onPageChange(event, page - 1)
	const handleNextButtonClick = (event) => onPageChange(event, page + 1)
	const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
			</IconButton>
		</Box>
	)
}

const BasicTable = (props) => {
	const { data, collection, callback = null } = props
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(50)
	const { enqueueSnackbar } = useSnackbar()
	const [open, setOpen] = useState(false)
	const [columns, setColumns] = useState(null)
	const [currentData, setCurrentData] = useState(null)
	const getColumns = () => {
		const columnsTemp = []
		const firstElement = data[0]
		Object.keys(firstElement).map(el => columnsTemp.push(el))
		setColumns(columnsTemp)
	}
	const handleOpen = (el) => {
		setCurrentData(el)
		setOpen(true)
	}
	const handleColumnName = (name) => {
		switch (name) {
			case 'address':
				return 'Adres'
			case 'date':
				return 'Data'
			case 'description':
				return 'Opis'
			case 'seat':
				return 'Miejsca zajęte'
			case 'maxSeat':
				return 'Maksymalna liczba miejsc'
			case 'end':
				return 'Godz. końcowa'
			case 'start':
				return 'Godz. startowa'
			case 'email':
				return 'Adres email'
			case 'uid':
				return 'Id użytkownika'
			case 'name':
				return 'Imię i nazwisko'
			case 'phone':
				return 'Numer telefonu'
			case 'role':
				return 'Rola'
			case 'day':
				return 'Dzień tygodnia'
			case 'child':
				return 'Przyjście z dzieckiem'
			default:
				return name
		}
	}
	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}
	const formData = () => {
		const dataForm = { elements: [] }
		const handleType = (typ) => {
			switch (typ) {
				case 'child':
					return 'checkbox'
				case 'description':
					return 'editor'
				case 'phone':
					return 'phone'
				default:
					return 'text'
			}
		}
		const handleLabel = (col) => {
			switch (col) {
				case 'child':
					return 'Wydarzenie z dziećmi'
				default:
					return col
			}
		}
		columns?.map(col => {
			dataForm?.elements?.push({
				name: col,
				type: handleType(col),
				label: handleLabel(col),
				disabled: col === 'email' || col === 'uid' || col === 'role',
				validationType: 'string',
				value: currentData[col],
			})
			return true
		})
		dataForm?.elements?.push({
			type: 'button',
			value: 'Zapisz dane',
		})
		const onSubmit = (e) => {
			if (collection === 'users') {
				updateUsers(e).then((res) => {
					if (res) enqueueSnackbar('Zapisano dane poprawnie.', { variant: 'success' })
					else enqueueSnackbar('Problem z edycją danych.', { variant: 'error' })
				})
			}
			setOpen(false)
			if (callback) callback()
			return true
		}
		return (
			<div>
				<FormGenerator submit={(e) => onSubmit(e)} data={dataForm} {...props} />
			</div>
		)
	}
	useEffect(() => {
		getColumns()
	}, [])
	return (
		<TableContainer component={Paper}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell>Akcja</TableCell>
						{
							columns?.map((el, index) => (
								<TableCell sx={{ minWidth: '150px', paddingRight: '30px' }} key={index}>{handleColumnName(el)}</TableCell>
							))
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map((row, index) => (
							<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row">
									<Button
										sx={{ minWidth: '100px' }}
										onClick={() => handleOpen(row)}
									>
										Edycja
									</Button>
								</TableCell>
								{
									columns?.map((col, indexCol) => (
										<TableCell key={indexCol}>
											{
												col === 'description'
													? <Box
															sx={{
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																width: 160,
																maxHeight: '60px',
																lineHeight: '1em',
																whiteSpace: 'nowrap',
															}}
															key={indexCol} dangerouslySetInnerHTML={{ __html: row[col] }}
														/>
													: col === 'child'
													? (
														<div>
															{ row[col] ? <Check sx={{ color: 'green' }} /> : <Close sx={{ color: 'red' }} /> }
														</div>
													)
													: (
													<>
														{row[col]}
													</>
												)
											}
										</TableCell>
									))
								}
							</TableRow>
						))
					}
				</TableBody>
				<TableFooter className="footerTable">
						<TablePagination
							rowsPerPageOptions={[50, 100, 200, { label: 'All', value: -1 }]}
							colSpan={3}
							count={data?.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									'aria-label': 'rows per page',
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
				</TableFooter>
			</Table>
			{
				open && (
					<Dialog open={open} title="Edycja danych" content={columns && formData()} onClose={() => setOpen(false)} />
				)
			}
		</TableContainer>
	)
}

export default BasicTable
