import useStore from '../../Zustand'
import Typography from '@mui/material/Typography'
import { Grid, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import Stepper from '../../components/atoms/Stepper/Stepper'
import Button from '@mui/material/Button'
import { useSnackbar } from 'notistack'

const OrderPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [groupCart, setGroupCart] = useState([])
  const cart = useStore((state) => state?.cart)
  const RemoveFromCart = useStore((state) => state?.RemoveFromCart)
  const ClearCart = useStore((state) => state?.ClearCart)
  const style = {
    root: {
      padding: '50px',
      display: 'flex',
      width: 'calc(100% - 100px)',
    },
    titlePage: {
      fontSize: '40px',
      fontWeight: '900',
    },
    title: {
      fontSize: '30px',
      fontWeight: '600',
    },
    smallText: {
      fontSize: '22px',
      fontWeight: '400',
    },
    boxImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    item: {
      marginBottom: '20px',
    },
    image: {
      maxWidth: '100%',
      width: 'auto',
      maxHeight: '150px',
    },
    count: {
      marginTop: '10px',
    }
  }
  const handleDelete = (e, el) => {
    e.preventDefault()
    RemoveFromCart(el?.id)
    enqueueSnackbar('Usunięto produkt poprawnie.', { variant: 'success' })
  }
  useEffect(() => {
    if (cart) {
      const temp = {}
      cart?.forEach((el) => {
        if (temp[el.id]) {
          temp[el.id].number++
        } else {
          temp[el.id] = { ...el, number: 1 }
        }
      })
      const tempArray = Object.values(temp)
      setGroupCart(tempArray)
    }
  }, [cart])
  return (
      <Box sx={style.root}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            {
              groupCart?.length > 0
              ? (
                <Stepper
                  reset={false}
                  lastStep={() => ClearCart()}
                  steps={[
                    {
                      label: 'Koszyk zakupowy',
                      render: (
                        <Grid item md={12} xs={12}>
                          {
                            groupCart?.map((el, index) => {
                              const value = el?.number
                              return (
                                <Box sx={style.item} key={index}>
                                  <Grid container spacing={4}>
                                    <Grid item md={2} xs={12}>
                                      <Box sx={style.boxImage}>
                                        <img src={el?.image} alt={el?.name} style={style.image}/>
                                      </Box>
                                    </Grid>
                                    <Grid item md={10} xs={12}>
                                      <Typography sx={style.title}>{el?.name}</Typography>
                                      <Typography sx={style.smallText}>{el?.smallText}</Typography>
                                      <TextField sx={style.count} label="Ilość sztuk produktu" value={value} type="number" />
                                      <Button
                                        sx={{ background: '#b72121', marginTop: '18px', marginLeft: '20px' }}
                                        onClick={(e) => handleDelete(e, el)}
                                        variant="contained"
                                      >
                                        Usuń produkt
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              )
                            })
                          }
                        </Grid>
                      )
                    },
                    {
                      label: 'Wybór adresu dostawy',
                      render: (
                        <Grid item md={12} xs={12}>
                          Wybór adresu dostawy
                        </Grid>
                      )
                    },
                    {
                      label: 'Wybór przesyłki',
                      render: (
                        <Grid item md={12} xs={12}>
                          Rodzaje przesyłek
                        </Grid>
                      )
                    },
                    {
                      label: 'Podsumowanie zamówienia',
                      render: (
                        <Grid item md={12} xs={12}>
                          Podsumowanie zamówienia
                        </Grid>
                      )
                    },
                    {
                      label: 'Płatność',
                      render: (
                        <Grid item md={12} xs={12}>
                          Rodzaje płatności
                        </Grid>
                      )
                    }
                  ]}
                />
              )
: (
                <Typography>Nie ma produktów w koszyku.</Typography>
              )
            }
          </Grid>
        </Grid>
      </Box>
  )
}

export default OrderPage
