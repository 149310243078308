import { Box, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'

const RoomChat = (props) => {
  const { onClick, currentId } = props
  const { name, background, date } = props.data.data
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '10px',
      background: props.data.id === currentId && 'rgb(230,235,245)',
    },
    image: {
      marginRight: '20px',
      width: '50px',
      height: '50px',
      borderRadius: '50%',
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
    },
    titleFirst: {
      fontSize: '18px',
      fontWeight: '500',
      lineHeight: '16px',
    },
  }
  return (
    <Grid container sx={style.root} className="chatRoom" onClick={(e) => onClick(e)}>
      <Grid item>
        <img src={background} alt={name} style={style.image} />
      </Grid>
      <Grid item>
        <Box sx={style.text}>
          <Typography sx={style.titleFirst}>
            {name}
          </Typography>
          {date}
        </Box>
      </Grid>
    </Grid>
  )
}

export default RoomChat
