import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import oswiadczenie from '../../../assets/pdf/oświadczenie.pdf'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Accordion = styled((props) => (
	// eslint-disable-next-line react/no-children-prop
	<MuiAccordion disableGutters elevation={0} square {...props} children={props.children} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
  backgroundColor:
		theme.palette.mode === 'dark'
		  ? 'rgba(255, 255, 255, .05)'
		  : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const GroupAccordion = ({ elements }) => {
  const t = LocalStorageHelper.get('translation')
  const [expanded, setExpanded] = useState('panel1')
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  return (
	  <div>
		  {
				elements?.map((element, index) => {
				  return (
						<Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
              <AccordionSummary
                sx={{
                  background: 'rgb(18, 26, 68)',
                  color: '#ffffff',
                  display: 'flex',
                  alignItems: 'center'
                }}
                className="accord"
              >
                <span style={{ marginRight: '5px', height: '30px', display: 'flex', alignItems: 'center' }}>{element?.icon || ''}</span>
                <Typography sx={{
                  fontSize: '20px',
                  display: 'flex'
                }}>
                  {element.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    textAlign: 'justify',
                    fontSize: '20px',
                  }}
                  dangerouslySetInnerHTML={{ __html: element.text }}
                />
							</AccordionDetails>
						</Accordion>
				  )
				})
		  }
	  </div>
  )
}

export default GroupAccordion
