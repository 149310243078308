import { getAllEvents, getAllGames, getAllTournaments } from '../../../firebase'
import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import SkeletonLoader from '../../atoms/SkeletionLoader/SkeletonLoader'
import ArrayHelper from '../../../helpers/ArrayHelper/ArrayHelper'
import { Divider, Grid } from '@mui/material'
import bronze from '../../../assets/images/stats/bronze.webp'
import silver from '../../../assets/images/stats/silver.webp'
import gold from '../../../assets/images/stats/gold.webp'
import platinum from '../../../assets/images/stats/platinum.webp'
import caffeinated from '../../../assets/images/stats/caffeinated.webp'
import games2 from '../../../assets/images/stats/games2.webp'
import games10 from '../../../assets/images/stats/games10.webp'
import games20 from '../../../assets/images/stats/games20.webp'
import games250 from '../../../assets/images/stats/games250.webp'
import talk1 from '../../../assets/images/stats/talk1.webp'
import talk10 from '../../../assets/images/stats/talk10.webp'
import talk50 from '../../../assets/images/stats/talk50.webp'
import tournament from '../../../assets/images/stats/tournament.webp'
import sms from '../../../assets/images/stats/sms.webp'
import address from '../../../assets/images/stats/address.webp'
import games100 from '../../../assets/images/stats/games100.webp'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const StatisticData = () => {
  const [dataUsers, setDataUsers] = useState([])
  const [countEvents, setCountEvents] = useState(0)
  const [yourGames, setYourGames] = useState(0)
  const [yourMessages, setYourMessages] = useState(0)
  const [tournamentExist, setTournamentExist] = useState(false)
  const generateStat = (img, title, desc, isOrNot = false) => {
    const style = {
      stat: {
        background: 'white',
        padding: '50px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '15px',
        position: 'relative',
      },
      title: {
        fontWeight: '600',
        marginTop: '30px',
        fontSize: '20px',
      },
      smallImg: {
        position: 'absolute',
        left: '20px',
        top: '20px',
        width: '30px',
        filter: !isOrNot ? 'grayscale(1)' : 'none',
      }
    }
    return (
      <Grid item md={3} xs={12}>
        <Box sx={style.stat}>
          <img src={img} alt="bronze" style={{ filter: !isOrNot ? 'grayscale(1)' : 'none' }} />
          <Typography sx={style.title}>{title}</Typography>
          <Typography sx={{ fontSize: '20px', textAlign: 'center' }}>{desc}</Typography>
          <Divider sx={{ width: '100%', background: '#121a44', marginTop: '30px' }} />
          {
            isOrNot
              ? <Typography sx={{ fontSize: '20px', marginTop: '30px', fontWeight: '600', color: 'green' }}>ODBLOKOWANE</Typography>
              : <Typography sx={{ fontSize: '20px', marginTop: '30px', fontWeight: '600', color: 'grey' }}>ZABLOKOWANE</Typography>
          }
          <img src={img} alt="bronze" style={style.smallImg} />
        </Box>
      </Grid>
    )
  }
  useEffect(() => {
    getAllGames().then((res) => {
      if (res) {
        const tempYourGames = []
        const user = LocalStorageHelper.get('user')
        res.forEach((game) => {
          game?.who?.forEach(el => {
            if (el === user?.name) {
              tempYourGames.push(el)
            }
          })
        })
        setYourGames(tempYourGames)
      }
    })
    getAllTournaments().then(async (res) => {
      if (res) {
         res?.forEach(element => {
           const data = element?.data
           if (data?.tournament) {
             data?.users?.forEach((el) => {
               if (el.name === LocalStorageHelper.get('user')?.name) {
                 setTournamentExist(true)
               }
             })
           }
         })
      }
    })
    getAllEvents().then(async (res) => {
      if (res) {
        const tempDataUsers = []
        const tempDataMessages = []
        await res?.forEach(element => {
          const data = element.data
          const usersEvent = data.users
          usersEvent?.forEach(el => {
            if (el?.name) {
              if (el?.name === LocalStorageHelper.get('user')?.name) {
                const dataEl = element?.data
                dataEl?.messages?.forEach((userEl) => {
                  if (userEl?.name === LocalStorageHelper.get('user')?.name) {
                    tempDataMessages.push(element)
                  }
                })
              }
              tempDataUsers.push(el?.name?.trim())
            }
          })
        })
        const dataToSave = []
        const dataNoDuplicate = ArrayHelper.deleteDuplicate(tempDataUsers)
        dataNoDuplicate.forEach(name => {
          let count = 1
          tempDataUsers.forEach(el => {
            if (el === name) count++
          })
          dataToSave.push({ name, count })
        })
        dataToSave.sort((x, y) => x.count - y.count).reverse()
        dataToSave?.forEach((el) => {
          if (el.name === LocalStorageHelper.get('user')?.name) {
            setCountEvents(el?.count)
          }
        })
        setDataUsers(dataToSave)
        setYourMessages(tempDataMessages)
      }
    })
  }, [])
  return (
    <SkeletonLoader count={20} variable={dataUsers?.length}>
      <Box sx={{ marginBottom: '50px', marginTop: '50px' }}>
        <Grid container spacing={4}>
          {generateStat(bronze, 'Brąz', 'Min. 2 razy na wydarzeniu', countEvents >= 2)}
          {generateStat(silver, 'Srebrny', 'Min. 5 razy na wydarzeniu', countEvents >= 5)}
          {generateStat(gold, 'Złoty', 'Min. 10 razy na wydarzeniu', countEvents >= 10)}
          {generateStat(platinum, 'Platynowy', 'Min. 20 razy na wydarzeniu', countEvents >= 20)}
          {generateStat(caffeinated, 'Konfeinowy gracz', 'Min. 50 razy na wydarzeniu', countEvents >= 50)}
          {generateStat(games2, 'Rozgrzewka gracza', 'Min. 2 gry w swojej kolekcji', yourGames?.length >= 2)}
          {generateStat(games10, 'Zaawanosowany gracz', 'Min. 10 gier w swojej kolekcji', yourGames?.length >= 10)}
          {generateStat(games20, 'Zbieracz gier', 'Min. 20 gier w swojej kolekcji', yourGames?.length >= 20)}
          {generateStat(games100, 'Maniak giercowy', 'Min. 100 gier w swojej kolekcji', yourGames?.length >= 100)}
          {generateStat(games250, 'Sklepikarz', 'Min. 250 gier w swojej kolekcji', yourGames?.length >= 250)}
          {generateStat(talk1, 'Rozmowny introwertyk', 'Min. 1 raz rozmawiaj w wydarzeniu', yourMessages?.length >= 1)}
          {generateStat(talk10, 'Rozgadany gracz', 'Min. 10 razy rozmawiaj w wydarzeniach', yourMessages?.length >= 10)}
          {generateStat(talk50, 'Ekstrawertyczny gracz', 'Min. 50 razy rozmawiaj w wydarzeniach', yourMessages?.length >= 50)}
          {generateStat(tournament, 'Turniejowy gracz', 'Udało ci się być na naszym turnieju', tournamentExist)}
          {generateStat(sms, 'Powiadamiacz', 'Masz włączone powiadomienia sms', !LocalStorageHelper.get('user')?.notSend)}
          {generateStat(address, 'Adresacz', 'Masz dodany adres do swojego konta', LocalStorageHelper.get('user')?.street && LocalStorageHelper.get('user')?.house)}
        </Grid>
      </Box>
    </SkeletonLoader>
  )
}

export default StatisticData
