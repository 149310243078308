const Camera = () => {
  const update = (stream) => {
    document.querySelector('video').src = stream?.url
    return () => {}
  }
  return (
    <div>
      <device type="media" onChange={update(this?.data)}></device>
      <video autoPlay></video>
    </div>
  )
}

export default Camera
