import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper'
import LocationHelper from './LocationHelper'

export default {
  showPosition: (position) => {
    const location = {
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
      accuracy: position?.coords?.accuracy,
    }
    LocalStorageHelper.set('currentLocation', location)
  },
  error: (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`)
  },
  getLocation: () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }
    if (navigator.geolocation) navigator.geolocation.getCurrentPosition(LocationHelper.showPosition, LocationHelper.error, options)
  },
}
