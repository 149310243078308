import ApiHelper from '../../helpers/ApiHelper/ApiHelper'

export default {
  getLuckyDuckGames: () => {
    return ApiHelper.get('https://api.your-site.pl/publishings/luckyduckGames/index.php').then((res) => {
      if (res) {
        return res
      }
    })
  },
  getMudukoGames: () => {
    return ApiHelper.get('https://api.your-site.pl/publishings/mudukoGames/index.php').then((res) => {
      if (res) {
        return res
      }
    })
  }
}
