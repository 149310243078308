import { getAllEvents } from '../../firebase'
import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper'
import DateHelper from '../DateHelper/DateHelper'

export default {
  getYourActualEvent: (setEvents) => {
    const allElementsEvents = getAllEvents(LocalStorageHelper.get('user')?.email)
    allElementsEvents?.then(async (res) => {
      if (res) {
        const actualEvents = []
        await res.map(el => {
          const date = el?.data?.date
          const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD.MM.YYYY')
          const days = DateHelper.getDaysBetweenTwoDates(new Date(date.split('.')[2], date.split('.')[1], date.split('.')[0]), new Date(dateToday.split('.')[2], dateToday.split('.')[1], dateToday.split('.')[0]))
          const dayToEvent = Number(days) / 60 / 60 / 24 / 1000
          if (dayToEvent >= 0) {
            actualEvents.push(el)
          }
          actualEvents?.map((ev) => {
            const date = ev?.data?.date
            const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD.MM.YYYY')
            const days = DateHelper.getDaysBetweenTwoDates(new Date(date.split('.')[2], date.split('.')[1], date.split('.')[0]), new Date(dateToday.split('.')[2], dateToday.split('.')[1], dateToday.split('.')[0]))
            ev.dayToEvent = Number(days) / 60 / 60 / 24 / 1000
            return true
          })
          actualEvents.sort((x, y) => x.dayToEvent - y.dayToEvent)
          return true
        })
        setEvents(actualEvents)
      }
    })
  }
}
