import {
  createUser,
  getAllGames,
  getDataFromPath,
  loginUser,
  resetPassword,
  signOutFirebase
} from '../../firebase'
import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper'

export default {
  login: (e) => loginUser(e.email, e.password).then(async (res) => {
    await getAllGames()
    return {
      res: res,
      database: await getDataFromPath('users', e.email),
    }
  }),
  remember: (e) => resetPassword(e.email),
  register: (e) => createUser(e).then((res) => res).catch(res => res),
  logout: () => {
    signOutFirebase().then(async () => {
      await LocalStorageHelper.clear()
      window.open('/login', '_self')
    })
  }
}
