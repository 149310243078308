import create from 'zustand'
import { devtools } from 'zustand/middleware'
import LocalStorageHelper from './helpers/LocalStorageHelper/LocalStorageHelper'

const createActions = (set, get) => ({
  AddToCart: (el) => set((state) => {
    LocalStorageHelper.set('cart', [...state.cart, el])
    return { cart: [...state.cart, el] }
  }),
  RemoveFromCart: (id) => set((state) => {
    if (!state.cart || !id) {
      return state
    }
    const index = state.cart.findIndex(item => item.id === id)
    if (index === -1) {
      return state
    }
    const updatedCart = [...state.cart]
    updatedCart.splice(index, 1)
    if (state.cart.length === 1) {
      return { cart: [] }
    }
    return { cart: updatedCart }
  }),
  ClearCart: () => set((state) => {
    LocalStorageHelper.set('cart', [])
    return { cart: [] }
  })
})

const useStore = create(
  devtools((set, get) => ({ cart: [], ...createActions(set, get) }))
)

export default useStore
