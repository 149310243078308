import { useEffect } from 'react'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import Box from '@mui/material/Box'

const ListYourGamesPage = () => {
  const games = LocalStorageHelper.get('yourGames')
  useEffect(() => {
    document.querySelector('.Navigation').style.display = 'none'
    document.querySelector('.chat').style.display = 'none'
    window.print()
    window.open('/profile', '_self')
  }, [])
  return (
    <Box sx={{ marginTop: '50px' }}>
      {
        games?.map((game, index) => {
          return (
            <Box key={index} sx={{ fontSize: '30px', fontWeight: '600' }}>
              • {game?.label}
            </Box>
          )
        })
      }
    </Box>
  )
}

export default ListYourGamesPage
