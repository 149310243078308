import { useEffect, useState } from 'react'
import { pl, enUS, ru } from 'date-fns/esm/locale'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import { getAllCalendar } from '../../../firebase'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import './Calendar.scss'
import Dialog from '../../atoms/Dialog/Dialog'
import Box from '@mui/material/Box'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import { Typography } from '@mui/material'

const Calendar = () => {
  const lang = LocalStorageHelper.get('lang')
  const [data, setData] = useState(null)
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState(null)
  const renderDialog = () => {
    const def = current._def
    let currentData = null
    data?.forEach((el) => {
      if (el?.id === def?.publicId) {
        currentData = el
      }
    })
    return (
      <Box sx={{ minWidth: '320px', width: BreakpointsHelper.isMobile() ? '100%' : 'auto' }}>
        <Typography>
          Podgląd wydarzenia w budowie ...
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: def.extendedProps.description }}/>
      </Box>
    )
  }
  const closeEvent = () => {
    setOpen(false)
    setCurrent(null)
  }
  const getData = () => {
    getAllCalendar().then((res) => {
      if (res) {
        const tempElements = []
        res?.map((element, index) => {
          const el = element?.data
          const day = el?.date?.split('.')[0]
          const month = el?.date?.split('.')[1] - 1
          const year = el?.date?.split('.')[2]
          const startHours = el?.hours?.start?.split(':')[0]
          const startMinutes = el?.hours?.start?.split(':')[1]
          const endHours = el?.hours?.end?.split(':')[0]
          const endMinutes = el?.hours?.end?.split(':')[1]
          if (el?.hours?.start && el?.hours?.end) {
            tempElements.push({
              event_id: index + 1,
              title: el?.name?.toUpperCase() || 'SPOTKANIE PLANSZÓWKOWE',
              start: new Date(year, month, day, startHours, startMinutes),
              end: new Date(year, month, day, endHours, endMinutes),
              description: el?.description,
              type: el?.type,
              id: element?.id,
              backgroundColor: el?.type === 'library' ? '#9ca7e1' : el?.type === 'thematic' ? '#8dfa08' : '#121a44',
            })
          }
          return true
        })
        setData(tempElements)
      }
    })
  }
  const handleClick = (e) => {
    const dataElement = e.event
    setCurrent(dataElement)
    setOpen(true)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="calendar" style={{ marginTop: !BreakpointsHelper.isMobile() && '80px', padding: '50px', borderRadius: '25px', boxShadow: '6px 21px 20px 4px rgba(0,0,0,.15)' }}>
      {
        data?.length > 0 && (
          <FullCalendar
            plugins={[dayGridPlugin]}
            firstDay={1}
            locale={lang === 'pl' ? pl : lang === 'en' ? enUS : lang === 'ua' ? ru : pl}
            events={data}
            eventClick={(e) => handleClick(e)}
          />
        )
      }
      {
        open && (
          <Dialog
            small
            open={open}
            title={current?.title}
            content={renderDialog()}
            onClose={closeEvent}
          />
        )
      }
    </div>
  )
}

export default Calendar
