import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Box from '@mui/material/Box'
import GamesList from '../../components/organisms/GamesList/GamesList'

const GamesPage = () => {
  const style = {
    root: {
      padding: BreakpointsHelper.isMobile() ? '50px 15px' : '50px 50px',
    },
  }
  return (
    <Box sx={style.root}>
      <GamesList/>
    </Box>
  )
}

export default GamesPage
