import Box from '@mui/material/Box'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { ShoppingCartOutlined } from '@mui/icons-material'
import ProductHelper from '../../../helpers/ProductHelper/ProductHelper'
import useStore from '../../../Zustand'
import { useHistory } from 'react-router'
import { useSnackbar } from 'notistack'

const Product = ({ data }) => {
  const AddToCart = useStore((state) => state.AddToCart)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const style = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '2px 2px 20px 5px rgba(0,0,0,0.18)',
      padding: '30px 15px 15px 15px',
      position: 'relative',
    },
    img: {
      height: '200px',
      objectFit: 'contain',
    },
    name: {
      marginTop: '18px',
      fontSize: '18px',
      marginBottom: '10px',
      fontWeight: '400',
    },
    priceName: {
      fontSize: '24px',
      fontWeight: '900',
    },
    priceNameDiscount: {
      fontSize: '24px',
      fontWeight: '900',
      color: '#e51919',
    },
    priceNameTitle: {
      fontSize: '16px',
    },
    priceNameCut: {
      fontSize: '16px',
      textDecoration: 'line-through',
    },
    box: {
      display: 'flex',
      justifyContent: ' space-between',
    },
    news: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      fontWeight: '600',
      display: 'flex',
      marginLeft: '10px'
    },
    publisher: {
      position: 'absolute',
      top: '15px',
      left: '15px',
      display: 'flex',
      marginLeft: '10px',
      height: '50px',
    }
  }
  const handleAdd = (data) => {
    AddToCart(data)
    enqueueSnackbar('Dodano produkt poprawnie do koszyka.', { variant: 'success' })
  }
  return (
    <Grid item lg={3} md={4} xs={12} className="product">
      <Box sx={style.root}>
        <img onClick={() => ProductHelper.openPreviewProduct(data?.id, history)} src={data?.image} alt={data?.name} style={style.img}/>
        <Typography variant="h3" sx={style.name}>{data?.name}</Typography>
        <Box sx={style.box}>
          <Typography variant="h3" sx={style.priceName}>Cena</Typography>
          <Typography variant="h3" sx={data?.priceData?.specialPrice ? style.priceNameDiscount : style.priceName}>{(data?.priceData?.specialPrice || data?.priceData?.price) + ' ' + data?.priceData?.count}</Typography>
        </Box>
        <Box sx={style.box}>
          <Typography variant="h3" sx={style.priceNameTitle}>Średnia cena z 30 dni</Typography>
          <Typography variant="h3" sx={style.priceNameCut}>{data?.priceData?.price + ' ' + data?.priceData?.count}</Typography>
        </Box>
        <Button onClick={() => handleAdd(data)} sx={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }} variant="contained">
          <Typography>Dodaj do koszyka</Typography>
          <ShoppingCartOutlined />
        </Button>
        {
          data?.publisher?.image && data?.publisher?.name && (
            <Box sx={style.publisher}>
              <img src={data?.publisher?.image} alt={data?.publisher?.name} />
            </Box>
          )
        }
        {
          (data?.news || data?.preSale) && (
            <Box sx={style.news}>
              {
                data?.news && (
                  <Box sx={{ background: '#0675f4', padding: '5px 10px', color: 'white', marginRight: data?.preSale ? '10px' : '' }}>
                    Nowość
                  </Box>
                )
              }
              {
                data?.preSale && (
                  <Box sx={{ background: '#6506f4', padding: '5px 10px', color: 'white' }}>
                    Przedsprzedaż
                  </Box>
                )
              }
            </Box>
          )
        }
      </Box>
    </Grid>
  )
}

export default Product
