import moment from 'moment'
import DateHelper from './DateHelper'

export default {
  getNameDay: (date) => {
    const days = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota']
    return days[date?.getDay()]
  },
  getFormattedDateByFormat: (date, format) => moment(date).format(format),
  getDaysBetweenTwoDates: (dateStart, dateEnd) => {
    const start = dateStart ? moment(dateStart) : moment()
    const end = dateEnd ? moment(dateEnd) : moment()
    return start.diff(end)
  },
  getNumberOfDays: (start, end) => {
    const date1 = new Date(start)
    const date2 = new Date(end)
    const oneDay = 1000 * 60 * 60 * 24
    const diffInTime = date2.getTime() - date1.getTime()
    return Math.round(diffInTime / oneDay)
  },
  getDaysToEvent: (el) => {
    const date = el.data.date
    const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD/MM/YYYY')
    return DateHelper.getNumberOfDays(`${dateToday.split('/')[1]}/${dateToday.split('/')[0]}/${dateToday.split('/')[2]}`, `${date.split('.')[1]}/${date.split('.')[0]}/${date.split('.')[2]}`)
  }
}
