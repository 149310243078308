import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import { useHistory } from 'react-router'

export const PrivateRoute = (props) => {
  const { component: Component, layout: Layout } = props
  const history = useHistory()
  useEffect(() => {
    const user = LocalStorageHelper.get('user')
    if (!user) history.push('/login')
  }, [])
  return <MagicLoader time={2000}><Route render={(e) => <Layout component={Component} location={e} />} /></MagicLoader>
}
