import StatisticData from '../../components/molecules/StatisticData/StatisticData'
import React from 'react'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'

const StatisticPage = () => {
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 170px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px',
    },
  }
  return (
    <div style={style.root}>
      <StatisticData />
    </div>
  )
}

export default StatisticPage
