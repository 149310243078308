import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import Event from '../../components/molecules/Event/Event'
import { changeDataUser, getAllDataFromPath, getAllEvents } from '../../firebase'
import { useSnackbar } from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import ListGamesHelper from '../../helpers/ListGamesHelper/ListGamesHelper'
import Tabs from '../../components/molecules/Tabs/Tabs'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import Discount from '../../components/atoms/Discount/Discount'
import EventHelper from '../../helpers/EventHelper/EventHelper'
import GamesList from '../../components/organisms/GamesList/GamesList'
import PartnersEnum from '../../enums/PartnersEnum/PartnersEnum'
import ArrayHelper from '../../helpers/ArrayHelper/ArrayHelper'

const ProfilePage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const [globalCompanies, setGlobalCompanies] = useState(null)
  const [localCompanies, setLocalCompanies] = useState(null)
  const [publisherHouses, setPublisherHouses] = useState(null)
  const user = LocalStorageHelper.get('user')
  const [events, setEvents] = useState(null)
  const [games, setGames] = useState([])
  const [yourEvents, setYourEvents] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(true)
  const style = {
    root: {
      display: 'flex',
      marginTop: 10,
      padding: BreakpointsHelper.isMobile() ? '15px' : '15px 50px',
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '500px',
    },
    heading: {
      display: 'flex',
      justifyContent: 'flex-start',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '24px',
      color: 'rgb(18, 26, 68)',
      marginTop: '30px',
      marginBottom: '0',
      marginLeft: '30px',
      fontWeight: '700',
    },
  }
  const formData = {
    elements: [
      {
        name: 'name',
        type: 'text',
        label: t.globals.inputs.name.label,
        disabled: true,
        value: user?.name || '',
        validationType: 'string',
      },
      {
        name: 'email',
        type: 'email',
        label: t.globals.inputs.email.label,
        disabled: true,
        value: user?.email || '',
        validationType: 'string',
      },
      {
        name: 'password',
        type: 'password',
        label: t.globals.inputs.password.label,
        helperText: t.globals.inputs.password.helperText,
        validationType: 'string',
      },
      {
        name: 'phone',
        type: 'phone',
        disabled: true,
        label: t.globals.inputs.phone.label,
        value: user?.phone || '',
        validationType: 'string',
      },
      {
        name: 'street',
        type: 'text',
        label: t.globals.inputs.street.label,
        value: user?.street || '',
        validationType: 'string',
        required: false,
      },
      {
        name: 'house',
        type: 'number',
        label: t.globals.inputs.house.label,
        value: user?.house || '',
        validationType: 'string',
        required: false,
      },
      {
        name: 'apartment',
        type: 'string',
        label: t.globals.inputs.apartment.label,
        validationType: 'string',
        value: user?.apartment || '',
        required: false,
      },
      {
        name: 'notSend',
        type: 'checkbox',
        label: 'Powiadomienia SMS',
        validationType: 'string',
        value: !user?.notSend,
        defaultValue: !user?.notSend,
        required: false,
      },
      {
        name: 'defaultLang',
        type: 'select',
        label: t.globals.inputs.defaultLang.label,
        value: user?.defaultLang || '',
        items: [{
          value: 'pl',
          name: t.globals.components.ChangeLang.pl
        }, {
          value: 'en',
          name: t.globals.components.ChangeLang.en
        }, {
          value: 'ua',
          name: t.globals.components.ChangeLang.ua
        }],
        validationType: 'string',
        required: false,
      },
      {
        type: 'button',
        value: t.globals.save,
      }
    ],
  }
  const handleChangeDataSubmit = (e) => {
    const data = {
      name: e.name,
      phone: e.phone,
      street: e.street,
      apartment: e.apartment,
      house: e.house,
      notSend: e.notSend,
      defaultLang: e.defaultLang,
    }
    const email = e.email
    changeDataUser(email, data).then((res) => {
      if (res) {
        enqueueSnackbar('Zmieniono dane poprawnie.', { variant: 'success' })
      } else {
        enqueueSnackbar('Nastąpił problem ze zmianą danych.', { variant: 'error' })
      }
    })
  }
  const handleChangeData = () => {
    return (
      <>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Typography
              sx={{
                fontSize: '17px',
              }}
            >
              {t.profilePage.descriptionData}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormGenerator data={formData} submit={(e) => handleChangeDataSubmit(e)} {...props}/>
          </Grid>
        </Grid>
      </>
    )
  }
  const updateEvents = () => EventHelper.getYourActualEvent(setEvents)
  const [dataTabs, setDataTabs] = useState(null)
  const generateTabs = () => {
    if (games) {
      const allUsersInMyEvents = []
      yourEvents?.forEach(el => {
        const data = el?.data
        data?.users?.forEach(user => {
          const name = user?.name
          if (name !== (LocalStorageHelper.get('user')?.name === 'Planszówki Koszalin' ? 'Jan Szymański' : LocalStorageHelper.get('user')?.name)) {
            let elementExist = false
            allUsersInMyEvents.forEach((elExist, elExistIndex) => {
              if (elExist?.name === name) {
                allUsersInMyEvents[elExistIndex] = { name: name, count: allUsersInMyEvents[elExistIndex]?.count + 1 }
                elementExist = true
              }
            })
            if (!elementExist) {
              allUsersInMyEvents.push({ name: name, count: 1 })
            }
          }
        })
      })
      ArrayHelper.deleteDuplicate(allUsersInMyEvents)
      allUsersInMyEvents.sort((a, b) => b.count - a.count)
      setDataTabs([
        {
          name: t.profilePage.yourEvents,
          value: () => {
            return (
              <Grid item md={12} xs={12}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    marginBottom: '30px',
                  }}
                >
                  {t.profilePage.description}
                </Typography>
                {
                  isLoading
                    ? (
                      <div style={style.loader}>
                        <CircularProgress/>
                      </div>
                    )
                    : (
                      <>
                        <Grid container spacing={4}>
                          {
                            events?.length > 0
                              ? events?.map((ev, index) => {
                                const item = ev?.data
                                return (
                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    xs={12}
                                    key={index}
                                    sx={{
                                      display: 'flex',
                                      width: '100%',
                                    }}
                                  >
                                    <Event
                                      open
                                      item={item}
                                      id={ev?.id}
                                      whoCreated={item?.whoCreated}
                                      street={item?.street}
                                      house={item?.house}
                                      apartment={item?.apartment}
                                      background={item?.background}
                                      name={item?.name}
                                      themedEvent={item?.themedEvent}
                                      type={item?.type}
                                      child={item?.child}
                                      date={item?.date}
                                      day={item?.day}
                                      hours={item?.hours}
                                      seat={item?.seat}
                                      gamers={item?.users}
                                      address={item?.address}
                                      maxSeat={item?.maxSeat}
                                      description={item?.description}
                                      categories={item?.categories}
                                    />
                                  </Grid>
                                )
                              })
                              : (
                                <Grid item lg={6} md={12} xs={12}>
                                  <Typography
                                    sx={{
                                      marginTop: '20px',
                                      marginBottom: '20px',
                                    }}
                                  >
                                    {t.eventsPage.noEvents}
                                  </Typography>
                                </Grid>
                              )
                          }
                        </Grid>
                      </>
                    )
                }
                <Typography
                  sx={{
                    fontSize: '16px',
                    marginTop: '30px',
                    marginBottom: '30px',
                  }}
                >
                  Lista osób z którym najczęściej grałeś (20 osób)
                </Typography>
                {
                  allUsersInMyEvents?.map((el, index) => {
                    if (index < 19) {
                      return (
                        <Box key={index}>
                          <Typography sx={{ fontWeight: '300', fontSize: '24px', marginBottom: '10px', paddingBottom: '5px', borderBottom: '1px solid rgba(0,0,0,0.3)' }}><b>{el?.name}</b>, {el?.count} wydarzeń</Typography>
                        </Box>
                      )
                    } else {
                      return ''
                    }
                  })
                }
              </Grid>
            )
          }
        },
        {
          name: t.profilePage.yourGames,
          value: () => {
            return (
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Typography sx={{ fontSize: '17px' }}>
                    {t.profilePage.descriptionGames}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'rows',
                      width: '100%',
                    }}
                  >
                    {
                      games?.length > 0 && (
                        <Button
                          sx={{
                            marginBottom: BreakpointsHelper.isMobile() ? '10px' : '20px',
                            marginRight: BreakpointsHelper.isMobile() ? '0' : '20px',
                            width: BreakpointsHelper.isMobile() ? '100%' : 'auto'
                          }}
                          variant="contained"
                          onClick={() => props.location.history.push('/yourStatisticGames')}
                        >
                          {t.profilePage.yourStatisticGames}
                        </Button>
                      )
                    }
                    <Button
                      sx={{
                        marginBottom: '20px',
                        width: BreakpointsHelper.isMobile() ? '100%' : 'auto'
                      }}
                      variant="contained"
                      onClick={() => props.location.history.push('/addGame')}
                    >
                      {t.profilePage.addYourGame}
                    </Button>
                  </Box>
                  {
                    games?.length > 0 && (
                      <GamesList games={games}/>
                    )
                  }
                </Grid>
              </Grid>
            )
          },
        },
        {
          name: t.profilePage.discountsAvailable,
          value: () => (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography sx={style.heading}>
                  {t.globals.publisherHouses}
                </Typography>
              </Grid>
              {publisherHouses?.length > 0 && publisherHouses.map((el, index) => el?.visibleDiscountUsers &&
                <Discount key={index} data={el}/>)}
              <Grid item xs={12}>
                <Typography sx={style.heading}>
                  {t.globals.globalCompanies}
                </Typography>
              </Grid>
              {globalCompanies?.length > 0 && globalCompanies.map((el, index) => el?.visibleDiscountUsers &&
                <Discount key={index} data={el}/>)}
              <Grid item xs={12}>
                <Typography sx={style.heading}>
                  {t.globals.localCompanies}
                </Typography>
              </Grid>
              {localCompanies?.length > 0 && localCompanies.map((el, index) => el?.visibleDiscountUsers &&
                <Discount key={index} data={el}/>)}
            </Grid>
          ),
        },
        {
          name: t.profilePage.changeData,
          value: () => handleChangeData(),
        },
      ])
    }
  }
  useEffect(() => {
    updateEvents()
    getAllEvents(LocalStorageHelper.get('user').email).then((res) => {
      if (res) {
        setYourEvents(res)
      }
    })
    ListGamesHelper.getYourGames(LocalStorageHelper.get('user')?.name === 'Planszówki Koszalin' ? 'Jan Szymański' : LocalStorageHelper.get('user')?.name).then((res) => {
      if (res) {
        setGames(res)
        setIsLoading(false)
      }
    })
    setIsLoading(false)
    const pathname = window.location.pathname.search('deleteAccount')
    const children = window.location.pathname.search('children')
    if (pathname > 0) {
      if (children > 0) {
        enqueueSnackbar(t.profilePage.toasts.success2, { variant: 'success' })
      } else {
        enqueueSnackbar(t.profilePage.toasts.success, { variant: 'success' })
      }
    }
    return () => clearTimeout()
  }, [])
  useEffect(() => {
    setPublisherHouses(PartnersEnum.publisherHouses)
    setLocalCompanies(PartnersEnum.localCompanies)
    setGlobalCompanies(PartnersEnum.globalCompanies)
  }, [])
  useEffect(() => {
    generateTabs()
  }, [games])
  useEffect(() => {
    getAllDataFromPath('events').then((res) => {
      if (res) {
        const tempYourEvents = []
        res?.forEach(el => {
          const data = el.data
          data?.users?.forEach(us => {
            if (us?.email === LocalStorageHelper.get('user')?.email) {
              tempYourEvents.push(el)
            }
          })
        })
        // const tempUsers = []
        // const temp = []
        // tempYourEvents?.forEach((el) => {
        //   el?.data?.users?.forEach(user => {
        //     tempUsers.push(user?.name)
        //     if (tempUsers?.find(el => el?.))
        //   })
        // })
        // const noDuplicateUsers = ArrayHelper.deleteDuplicate(tempUsers)
      }
    })
  }, [])
  return (
    <>
      <div style={style.root}>
        <Tabs data={dataTabs}/>
      </div>
    </>
  )
}

export default ProfilePage
