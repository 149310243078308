import { Box, Grid } from '@mui/material'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import Button from '@mui/material/Button'
import PublishingHouseService from '../../services/PublishingHouseService/PublishingHouseService'
import React, { useEffect, useState } from 'react'
import PartnersEnum from '../../enums/PartnersEnum/PartnersEnum'
import Typography from '@mui/material/Typography'
import SkeletonLoader from '../../components/atoms/SkeletionLoader/SkeletonLoader'
import { updateOrCreateDocument } from '../../firebase'
import { useSnackbar } from 'notistack'

const AdminShopPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState(null)
  const [upper, setUpper] = useState(70)
  const [discount, setDiscount] = useState(null)
  const [games, setGames] = useState([])
  const [formData, setFormData] = useState({ elements: [] })
  const [publishItems, setPublishItems] = useState([])
  const style = {
    root: {
      padding: '50px',
    }
  }
  const handleData = (e) => {
    setData(e)
  }
  const handleLucky = async () => {
    const tempArray = games
    const data = await PublishingHouseService.getLuckyDuckGames()
    data?.forEach(el => {
      tempArray.push(el)
    })
    setGames(tempArray)
  }
  const handleDiscount = (e) => {
    setDiscount(e)
    setUpper(Number(e * 100))
  }
  useEffect(() => {
    if (publishItems) {
      setFormData({
        columns: 2,
        elements: [
          {
            name: 'publishingHouse',
            type: 'select',
            items: publishItems,
            label: 'Wydawnictwo',
            onChange: (e) => handleDiscount(e),
          },
          {
            name: 'regularPrice',
            type: 'number',
            defaultValue: 300,
            label: 'Cena regularna brutto',
          },
          {
            name: 'discount',
            type: 'text',
            defaultValue: discount * 100,
            label: 'Wysokość zniżki w %',
          },
          {
            type: 'button',
            value: 'Sprawdź produkt przed dodaniem',
          },
        ]
      })
    }
  }, [publishItems, discount])
  useEffect(() => {
    const temp = []
    PartnersEnum.publisherHouses.forEach(el => {
      if (el.discount?.length > 0 && el.company !== 'Unpluged Games') {
        temp.push({ value: el.discountNumber, name: el.company })
      }
    })
    setPublishItems(temp)
  }, [PartnersEnum])
  const bruttoPrice = data?.regularPrice.toFixed(2)
  const netPrice = (bruttoPrice / 1.23).toFixed(2)
  const netChipPrice = (netPrice - (netPrice * (data?.discount / 100))).toFixed(2)
  const bruttoChipPrice = (bruttoPrice - (bruttoPrice * (data?.discount / 100))).toFixed(2)
  const netDeliveryPrice = 9.13
  const price = Number((Number(bruttoChipPrice) + Number(bruttoChipPrice) * upper / 100 + netDeliveryPrice * 1.23).toFixed(2))
  const pricePayU = Number((price + netDeliveryPrice * 1.23) * 0.025).toFixed(2)
  const allCosts = (Number(bruttoChipPrice) + (Number(netDeliveryPrice) * 1.23) + Number((price * 0.12).toFixed(2)) + Number((price - (price / 1.23) + Number(pricePayU)).toFixed(2))).toFixed(2)
  const payToMe = Number((price - allCosts).toFixed(2))
  // useEffect(() => {
  //   if (payToMe <= 0) {
  //     setUpper(upper + 20)
  //   }
  // }, [payToMe])
  return (
    <Box sx={style.root}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Button variant="contained" onClick={() => handleLucky()}>Pobierz gry (Lucky Duck Games)</Button>
          <SkeletonLoader variable={formData.elements}>
            <FormGenerator data={formData} submit={(e) => handleData(e)} />
          </SkeletonLoader>
          {
            data && (
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Cena gry netto</Typography></Grid>
                <Grid item md={6} xs={12}>{netPrice} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Cena gry brutto</Typography></Grid>
                <Grid item md={6} xs={12}>{bruttoPrice} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Zniżka</Typography></Grid>
                <Grid item md={6} xs={12}>{(discount * 100).toFixed(2)}%</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Cena gry (po zniżce) netto</Typography></Grid>
                <Grid item md={6} xs={12}>{netChipPrice} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Cena gry (po zniżce) brutto (koszt)</Typography></Grid>
                <Grid item md={6} xs={12}>{bruttoChipPrice} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Przesyłka max. koszt netto</Typography></Grid>
                <Grid item md={6} xs={12}>{netDeliveryPrice} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Przesyłka max. koszt brutto (koszt)</Typography></Grid>
                <Grid item md={6} xs={12}>{netDeliveryPrice * 1.23} zł</Grid>
              </Grid>
            )
          }
          {
            data && (
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Wysokość marży</Typography></Grid>
                <Grid item md={6} xs={12}>{upper}%</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Sprzedaż gry (netto)</Typography></Grid>
                <Grid item md={6} xs={12}>{(Number(netChipPrice) + Number(netChipPrice) * upper / 100).toFixed(2)} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Sprzedaż gry (brutto) (zysk)</Typography></Grid>
                <Grid item md={6} xs={12}>{price} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Podatek ryczałtowany (koszt)</Typography></Grid>
                <Grid item md={6} xs={12}>{(price * 0.12).toFixed(2)} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Podatek VAT (koszt)</Typography></Grid>
                <Grid item md={6} xs={12}>{(price - (price / 1.23)).toFixed(2)} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Koszt PayU (koszt)</Typography></Grid>
                <Grid item md={6} xs={12}>{pricePayU} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '600' }}>Całkowity koszt</Typography></Grid>
                <Grid item md={6} xs={12}>{allCosts} zł</Grid>
                <Grid item md={12} xs={12}>
                  <hr/>
                </Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '900' }}>Całkowity zysk</Typography></Grid>
                <Grid item md={6} xs={12}>{payToMe} zł</Grid>
                <Grid item md={6} xs={12}><Typography sx={{ fontWeight: '900' }}>Cena względem wydawnictwa</Typography></Grid>
                <Grid item md={6} xs={12}>{(price - Number(bruttoPrice)).toFixed(2)} zł</Grid>
              </Grid>
            )
          }
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Dodawanie produktu</Typography>
          <FormGenerator
            data={{
              elements: [
                {
                  name: 'name',
                  type: 'text',
                  label: 'Nazwa gry',
                },
                {
                  name: 'smallText',
                  type: 'editor',
                  label: 'Krótki opis',
                },
                {
                  name: 'publisherName',
                  type: 'text',
                  label: 'Nazwa wydawnictwa',
                },
                {
                  name: 'description',
                  type: 'editor',
                  label: 'Opis',
                },
                {
                  name: 'image',
                  type: 'files',
                  ext: 'images',
                  pathname: 'files',
                  label: 'Zdjęcie',
                },
                {
                  name: 'price',
                  type: 'number',
                  label: 'Cena regularna',
                },
                {
                  name: 'specialPrice',
                  type: 'number',
                  label: 'Cena specjalna',
                  required: false,
                },
                {
                  name: 'news',
                  type: 'checkbox',
                  label: 'Nowy produkt',
                  defaultValue: true,
                  disabled: true,
                },
                {
                  name: 'preSale',
                  type: 'checkbox',
                  label: 'Przedsprzedaż',
                },
                {
                  name: 'possibleToBuy',
                  type: 'checkbox',
                  label: 'Czy jest możliwa do kupna?',
                },
                {
                  name: 'date',
                  type: 'date',
                  label: 'Data dodania',
                  value: '2024-04-23',
                  disabled: true,
                },
                {
                  type: 'button',
                  value: 'Dodaj grę do sprzedaży',
                },
              ]
            }}
            submit={(e) => {
              const data = {
                  name: e?.name,
                  smallText: e?.smallText,
                  publisher: {
                    name: e?.publisherName,
                    image: 'https://website.cdn77.luckyduckgames.com/img/luckyduck-logo-new.png',
                  },
                  description: e?.description,
                  image: e?.image,
                  priceData: {
                    price: e?.price,
                    specialPrice: e?.specialPrice,
                    count: 'zł',
                  },
                  news: e?.news,
                  preSale: e?.preSale,
                  possibleToBuy: e?.possibleToBuy,
                  date: e?.date,
                }
                console.log(data)
                updateOrCreateDocument('products', null, data).then((res) => {
                  if (res) {
                    enqueueSnackbar('Dodano produkt do sklepu poprawnie.', { variant: 'success' })
                  }
                })
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default AdminShopPage
