import React from 'react'
import PropTypes from 'prop-types'
import NavigationPreview from '../../components/molecules/NavigationPreview/NavigationPreview'

const PreviewLayout = (props) => {
  const Component = props.component
  const { location } = props
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      flexDirection: 'column',
      padding: '120px 0 0 0',
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
    },
  }
  return (
		<div style={style.root}>
			<NavigationPreview location={location} />
			<div style={style.content}>
				<Component location={location} />
			</div>
		</div>
  )
}

PreviewLayout.defaultProps = {
  location: false,
}

PreviewLayout.propsType = {
  location: PropTypes.object,
}

export default PreviewLayout
