import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import defaultImage from '../../../assets/images/no-data.jpg'
import GameBox from '../../molecules/GameBox/GameBox'
import Button from '../../atoms/Forms/Button/Button'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Dialog from '../../atoms/Dialog/Dialog'

const QuestionnaireGames = ({ games, callback, noOpen }) => {
	const [text, setText] = useState(null)
	const [title, setTitle] = useState('')
	const [open, setOpen] = useState('')
	// const handleAddLike = (e, name) => {
	// 	e.preventDefault()
	// 	const id = window?.location?.pathname?.split('/')[2]?.replace('%20', ' ')
	// 	// addLikeInGameEvent()
	// }
	const handleOpen = async (el) => {
		if (callback) callback()
		await setTitle(el.name)
		setOpen(true)
		const styleRender = {
			root: {
				display: 'flex',
				flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row',
			},
			data: {
				display: 'flex',
				flexDirection: 'column',
				marginTop: BreakpointsHelper.isMobile() && '15px',
			},
			images: {
				width: BreakpointsHelper.isMobile() ? '100%' : '250px',
				marginRight: BreakpointsHelper.isMobile() ? '0' : '25px',
			},
			el: {
				marginBottom: '5px',
			},
		}
		const renderModal = () => {
			return (
				<div style={styleRender.root}>
					<div>
						<img src={el.image || defaultImage} style={styleRender.images} alt={'preview'} />
					</div>
					<div style={styleRender.data}>
						{
							el?.category && (
								<>
									<div>
										<b>{('Kategoria').toUpperCase()}</b>
									</div>
									<div style={styleRender.el}>
										{el.category}
									</div>
								</>
							)
						}
						{
							el?.minPerson && el?.maxPerson && (
								<>
									<div>
										<b>{('Liczba graczy').toUpperCase()}</b>
									</div>
									<div style={styleRender.el}>
										<b>Min.</b> {el.minPerson} | <b>Max.</b> {el.maxPerson}
									</div>
								</>
							)
						}
						{
							el?.who && el?.who?.length >= 1 && (
								<>
									<div>
										<b>{('Właściciele gry').toUpperCase()}</b>
									</div>
									<div style={styleRender.el}>
										{ el.who.map((element, index) => index === el.who.length - 1 ? element : element + ' | ') }
									</div>
								</>
							)
						}
						{
							el?.description && (
								<>
									<div>
										<b>{('Opis gry').toUpperCase()}</b>
									</div>
									<div style={styleRender.el}>
										{el.description}
									</div>
								</>
							)
						}
						{
							el?.tutorial && (
								<Button
									sx={{
										marginTop: '20px',
										maxWidth: '320px',
									}}
									onClick={() => window.open(el.tutorial, '_blank')}
								>
									Link do tutoriala
								</Button>
							)
						}
					</div>
				</div>
			)
		}
		await setText(renderModal())
	}
	const handleCloseDialog = () => {
		setOpen(false)
	}
  return (
		<>
			<Grid container>
				<Grid item md={12}>
					<Typography
						sx={{
						  marginBottom: '20px',
						}}
					>
						Planszówki poniżej ograniczone są do właścicieli gier znajdujących się w wydarzeniu oraz przy wydarzeniach domowych ograniczone są również do ilości osób w wydarzeniu względem minimalnej i maksymalnej ilości osób mogących zagrać w daną grę.
						{/* Dodaj gwiazdkę na takiej grze w jaką chciałbyś zagrać na tym wydarzeniu, ułatwi to dobór gier organizatorom. */}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				{
					games?.map((el, index) => {
					    return (
								<Grid item md={3} xs={12} key={index}>
									<GameBox
										publishingHouse={el.publishingHouse}
										noOpen={noOpen}
										name={el.name}
										image={el.image}
										min={el.minPerson}
										max={el.maxPerson}
										category={el.category}
										who={el.who}
										onClick={() => !noOpen && handleOpen(el)}
										// addLike={(e) => handleAddLike(e, el)}
									/>
								</Grid>
					    )
					  })
				}
				{
					open && (
						<Dialog open={open} title={title} content={text} onClose={handleCloseDialog} />
					)
				}
			</Grid>
		</>
  )
}

export default QuestionnaireGames
