import Box from '@mui/material/Box'
import { ChatOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import Dialog from '../../atoms/Dialog/Dialog'
import RealtimeChat from '../RealtimeChat/RealtimeChat'
import EventHelper from '../../../helpers/EventHelper/EventHelper'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Chat = () => {
  const [open, setOpen] = useState(false)
  const [events, setEvents] = useState(null)
  const [notReadMessages, setNotReadMessages] = useState(0)
  const style = {
    icon: {
      position: 'fixed',
      zIndex: '999',
      borderRadius: '50%',
      bottom: '20px',
      right: '20px',
      background: '#2e3179',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      cursor: 'pointer',
    },
  }
  useEffect(() => {
    if (events?.length > 0) {
      let tempNotRead = 0
      setNotReadMessages(0)
      events?.map(el => {
        el.data.messages.map(message => {
          const dataPersonIsExist = message?.seePost?.find(person => person.email === LocalStorageHelper.get('user').email)
          if (!dataPersonIsExist) {
            tempNotRead++
          }
          return true
        })
        return true
      })
      setNotReadMessages(tempNotRead)
    }
  }, [events])
  useEffect(() => {
    EventHelper.getYourActualEvent(setEvents)
    const updateEvent = setInterval(() => {
      EventHelper.getYourActualEvent(setEvents)
    }, 30000)
    return () => clearInterval(updateEvent)
  }, [open])
  return (
    <>
      <Box className="chat" sx={style.icon} onClick={() => setOpen(!open)}>
        <ChatOutlined onClick={() => setOpen(!open)} />
        {
          notReadMessages !== 0 && (
            <Box sx={{ position: 'absolute', background: 'red', borderRadius: '50%', top: '-5px', right: '-5px', width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {notReadMessages}
            </Box>
          )
        }
      </Box>
      { open && <Dialog noPadding open={open} title="Chat w wydarzeniach, w których uczestniczysz" content={(<RealtimeChat setNotReadMessages={setNotReadMessages} />)}/> }
    </>
  )
}

export default Chat
