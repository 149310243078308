import { Grid } from '@mui/material'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const Gamers = ({ users }) => {
  const style = {
    root: {
      marginBottom: '20px',
      marginTop: '20px',
    },
    name: {
      fontSize: BreakpointsHelper.isMobile() ? '14px' : '20px',
      background: '#f3f3f3',
      padding: '10px 20px',
      marginRight: '10px',
      borderRadius: '20px',
      marginBottom: '10px',
    },
    nameChild: {
      fontSize: BreakpointsHelper.isMobile() ? '14px' : '20px',
      background: 'rgb(216,223,250)',
      padding: '10px 20px',
      marginRight: '10px',
      borderRadius: '20px',
      marginBottom: '10px',
    },
  }
  return (
    <div style={style.root}>
      <Grid container>
        {
          users?.map((el, index) => {
            if (!el?.childs?.length > 0) {
              return (
                <Grid item key={index}>
                  <div style={style.name}>
                    {el.name}
                  </div>
                </Grid>
              )
            } else {
              const tab = []
              tab.push(el)
              el?.childs?.forEach(ch => tab.push({ name: ch }))
              return tab?.map((r, indexR) => {
                if (indexR === 0) {
                  return (
                    <Grid item key={indexR}>
                      <div style={style.name}>
                        {r.name}
                      </div>
                    </Grid>
                  )
                } else {
                  return (
                    <Grid item key={indexR}>
                      <div style={style.nameChild}>
                        {r.name} <span style={{ fontSize: '12px', marginTop: '-10px', position: 'relative' }}>({tab[0].name})</span>
                      </div>
                    </Grid>
                  )
                }
              })
            }
          })
        }
      </Grid>
    </div>
  )
}

export default Gamers
