import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import React, { useEffect, useState } from 'react'
import ListGamesHelper from '../../helpers/ListGamesHelper/ListGamesHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'
import { Box, Grid, Typography } from '@mui/material'
import { getAllGames } from '../../firebase'
import GamesList from '../../components/organisms/GamesList/GamesList'

// eslint-disable-next-line jest/require-hook
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

const StatisticGamePage = () => {
  const [games, setGames] = useState(null)
  const [categoryMost, setCategoryMost] = useState(null)
  const [possibleToAdd, setPossibleToAdd] = useState(null)
  const [possibleToAddGames, setPossibleToAddGames] = useState([])
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 100px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px',
    },
  }
  const categories = [
    { name: 'Strategiczne', value: 'Strategiczne', count: 0 },
    { name: 'RPG', value: 'RPG', count: 0 },
    { name: 'Rodzinne / Familijne', value: 'Rodzinne / Familijne', count: 0 },
    { name: 'Fabularne / Przygodowe', value: 'Fabularne / Przygodowe', count: 0 },
    { name: 'Paragrafowe', value: 'Paragrafowe', count: 0 },
    { name: 'Karciane', value: 'Karciane', count: 0 },
    { name: 'Kościane', value: 'Kościane', count: 0 },
    { name: 'Imprezowe / Towarzyskie', value: 'Imprezowe / Towarzyskie', count: 0 },
    { name: 'Kooperacyjne', value: 'Kooperacyjne', count: 0 },
    { name: 'Ekonomiczne', value: 'Ekonomiczne', count: 0 },
    { name: 'Dla dzieci / Edukacyjne', value: 'Dla dzieci / Edukacyjne', count: 0 },
    { name: 'Dodatki', value: 'Dodatki', count: 0 },
    { name: 'Inne', value: 'Inne', count: 0 },
  ]
  const [data, setData] = useState(null)
  const generateGames = async () => {
    const tempGames = await getAllGames()
    const gamesPossibleToAdd = []
    tempGames?.forEach(el => {
      if (el?.name) {
        const persons = el?.who
        let yourPersonExist = false
        persons?.forEach(person => {
          if (person === LocalStorageHelper.get('user').name.trim()) {
            yourPersonExist = true
          }
        })
        if (!persons || persons?.length === 0 || !yourPersonExist) {
          gamesPossibleToAdd.push({ value: el?.id, label: el?.name })
        }
      }
    })
    setPossibleToAdd(gamesPossibleToAdd)
  }
  const generatorProfileText = () => {
    if (categoryMost) {
      let text1 = ''
      let text2 = ''
      let text3 = ''
      if (categoryMost === 'Strategiczne') {
        text1 = 'Osoba, która gra w gry strategiczne, zazwyczaj jest graczem, który lubi myśleć o swoich ruchach i planować swoje posunięcia z wyprzedzeniem. Tacy gracze mają skłonność do analizowania sytuacji i podejmowania decyzji na podstawie szerszego kontekstu, zamiast reagować impulsywnie na sytuację.'
        text2 = 'Gracze, którzy cenią sobie gry strategiczne, są zwykle bardzo skoncentrowani na celach i dążą do osiągnięcia ich za wszelką cenę. Są oni zwykle bardziej skłonni do rywalizacji niż do współpracy, a ich priorytetem jest często dominacja na planszy.'
        text3 = 'Osoby, które lubią gry strategiczne, zazwyczaj mają dobre umiejętności analityczne, zdolność do planowania i przewidywania przyszłych ruchów. Są to osoby, które potrafią podejmować trudne decyzje i radzą sobie z presją czasu. Jednakże, ponieważ gry strategiczne wymagają czasami długiego planowania, ci gracze często cenią sobie również umiejętności takie jak cierpliwość i wytrwałość.'
      }
      if (categoryMost === 'RPG') {
        text1 = 'Osoba, która gra w gry RPG (role-playing games), zazwyczaj jest graczem, który ceni sobie fabułę i interakcję ze światem gry. Tacy gracze często angażują się w rozwijanie postaci, co oznacza, że lubią eksplorować postaci i rozwijać ich historię w grze.'
        text2 = 'Gracze RPG są zwykle bardzo kreatywni i potrafią myśleć poza schematami. Cenią sobie tworzenie własnych opowieści i rozwijanie alternatywnych scenariuszy. Często pracują w grupach i wymieniają się pomysłami, aby stworzyć ciekawą historię. Gracze, którzy lubią gry RPG, są zazwyczaj otwarci na dialog i interakcję z innymi graczami. Są to osoby, które cenią sobie współpracę i pomysłowe rozwiązania problemów. Często mają też wyobraźnię i potrafią wprowadzać innowacyjne pomysły do gry.'
        text3 = 'Ponieważ gry RPG często wymagają długich sesji gry, ci gracze cenią sobie również umiejętności takie jak cierpliwość i wytrwałość. Wreszcie, ponieważ gry RPG często angażują emocjonalnie graczy, ci gracze są zwykle otwarci na refleksję i dyskusję na temat tematów dotyczących gry, takich jak moralność i etyka.'
      }
      if (categoryMost === 'Rodzinne / Familijne') {
        text1 = 'Osoba, która gra w gry rodzinne lub familijne, zazwyczaj jest graczem, który ceni sobie interakcję i zabawę w gronie rodziny lub przyjaciół. Tacy gracze często angażują się w rozgrywkę, aby spędzić czas z bliskimi i stworzyć wspomnienia.'
        text2 = 'Gracze gier rodzinnych są zwykle bardzo towarzyscy i łatwo nawiązują kontakty z innymi graczami. Cenią sobie współpracę i mają skłonność do podejmowania działań w zespole, co pozwala na osiągnięcie wspólnych celów. Osoby, które lubią gry rodzinne, często mają poczucie humoru i łatwo się śmieją, co dodaje zabawy i pozytywnego nastroju podczas gry. Są to osoby, które cenią sobie proste zasady gry i łatwo przyswajają nowe umiejętności.'
        text3 = 'Ponieważ gry rodzinne są zwykle łatwe do opanowania, ci gracze często cenią sobie łatwość i dostępność gier. Są to również osoby, które zwykle nie lubią zbyt złożonych lub wymagających gier, ponieważ chcą się po prostu dobrze bawić w gronie rodziny lub przyjaciół.'
      }
      if (categoryMost === 'Fabularne / Przygodowe') {
        text1 = 'Osoba, która gra w gry fabularne lub przygodowe, zazwyczaj jest graczem, który ceni sobie rozwijanie własnej wyobraźni i kreatywności. Tacy gracze często angażują się w grę, aby doświadczyć emocji związanych z wcieleniem się w postać i przeżyciem przygody w wirtualnym świecie. Gracze gier fabularnych są zwykle bardzo ciekawi świata i chętnie zgłębiają wiedzę na różne tematy, takie jak kultura, historia, nauka i fantastyka. Cenią sobie skomplikowane fabuły, które dają im dużo swobody w podejmowaniu decyzji i kreowaniu własnej historii.'
        text2 = 'Osoby, które lubią gry fabularne, często są bardzo cierpliwe i skłonne do poświęceń, ponieważ gra ta wymaga czasu i zaangażowania. Są to osoby, które cenią sobie refleksję i dyskusję na temat głębszych tematów, takich jak moralność, etyka i filozofia. Gracze gier fabularnych często pracują w grupach i wymieniają się pomysłami, aby stworzyć ciekawą historię. Są to osoby, które lubią dialog i interakcję z innymi graczami, ale jednocześnie są w stanie działać samodzielnie i podejmować decyzje.'
        text3 = 'Ponieważ gry fabularne często angażują emocjonalnie graczy, ci gracze są zwykle otwarci na refleksję i dyskusję na temat swoich postaci i wydarzeń w grze. Są to osoby, które cenią sobie tworzenie i rozwijanie swojej postaci oraz ich historii w grze.'
      }
      if (categoryMost === 'Paragrafowe') {
        text1 = 'Osoba, która gra w gry paragrafowe, zazwyczaj jest graczem, który ceni sobie łamigłówki i interakcję z tekstem. Tacy gracze często angażują się w grę, aby rozwijać swoje zdolności poznawcze i rozwijać logiczne myślenie. Gracze gier paragrafowych są zwykle bardzo skupieni i cierpliwi, ponieważ wymagają one od gracza uważnego przeczytania tekstu i podejmowania decyzji na podstawie zawartych informacji. Cenią sobie również kreatywność i umiejętność znajdowania rozwiązań w trudnych sytuacjach.'
        text2 = 'Osoba, która gra w gry paragrafowe, zazwyczaj jest graczem, który ceni sobie łamigłówki i interakcję z tekstem. Tacy gracze często angażują się w grę, aby rozwijać swoje zdolności poznawcze i rozwijać logiczne myślenie. Gracze gier paragrafowych są zwykle bardzo skupieni i cierpliwi, ponieważ wymagają one od gracza uważnego przeczytania tekstu i podejmowania decyzji na podstawie zawartych informacji. Cenią sobie również kreatywność i umiejętność znajdowania rozwiązań w trudnych sytuacjach. Osoby, które lubią gry paragrafowe, są zwykle bardzo ciekawe świata i chętnie zgłębiają różne tematy, takie jak kultura, historia i fantastyka. Cenią sobie skomplikowane opowieści, które dają im dużo swobody w podejmowaniu decyzji i eksplorowaniu różnych dróg fabularnych. Gracze gier paragrafowych często pracują samodzielnie i cenią sobie indywidualne podejście do rozwiązywania problemów. Są to osoby, które potrafią skupić się na jednym zadaniu i poświęcić mu czas i uwagę, co często prowadzi do osiągnięcia satysfakcjonującego wyniku.'
        text3 = 'Ponieważ gry paragrafowe często angażują emocjonalnie graczy, ci gracze są zwykle otwarci na refleksję i dyskusję na temat swoich wyborów i decyzji. Są to osoby, które cenią sobie możliwość samodzielnego podejmowania decyzji i eksplorowania różnych ścieżek fabularnych w grze.'
      }
      if (categoryMost === 'Karciane') {
        text1 = 'Osoba, która gra w gry karciane, zazwyczaj jest graczem, który ceni sobie umiejętności takie jak strategia, taktyka i szybka reakcja. Tacy gracze często angażują się w grę, aby rozwijać swoje zdolności poznawcze i umiejętności analityczne. Gracze gier karcianych są zwykle bardzo skupieni i koncentrują się na grze, ponieważ wymaga ona od gracza szybkich reakcji i podejmowania trafnych decyzji w krótkim czasie. Cenią sobie również umiejętność przewidywania ruchów przeciwnika i dostosowywania swojej strategii w trakcie gry.'
        text2 = 'Osoby, które lubią gry karciane, są zwykle bardzo ciekawe świata i chętnie zgłębiają różne strategie i taktyki w grze. Cenią sobie skomplikowane mechaniki gry, które dają im dużo swobody w podejmowaniu decyzji i wykorzystywaniu swojej kreatywności w trakcie gry. Gracze gier karcianych często pracują samodzielnie lub w małych grupach, ale jednocześnie cenią sobie rywalizację i interakcję z innymi graczami. Są to osoby, które lubią podejmować wyzwania i rywalizować z innymi, ale jednocześnie są w stanie zachować kulturalną postawę i szanować swoich przeciwników.'
        text3 = 'Ponieważ gry karciane często angażują emocjonalnie graczy, ci gracze są zwykle otwarci na refleksję i dyskusję na temat swoich decyzji i strategii w grze. Są to osoby, które cenią sobie możliwość ciągłego rozwoju swoich umiejętności i doskonalenia swojej gry w kolejnych rozgrywkach.'
      }
      if (categoryMost === 'Kościane') {
        text1 = 'Osoby, które grają w gry kościane, zwykle są graczami, którzy lubią ryzyko i nie boją się podejmować decyzji opartych na przypadku. Są to gracze, którzy cenią sobie emocje towarzyszące grze, a także chęć doświadczania szczęścia. Gracze gier kościanych często angażują się w grę, aby zrelaksować się i odprężyć, ponieważ gra ta nie wymaga od nich skomplikowanej strategii ani umiejętności. Chętnie dołączają do gry w większych grupach lub rodzinie, ponieważ kościana gra często jest grą towarzyską.'
        text2 = 'Gracze gier kościanych często cenią sobie prostotę i łatwość gry, co pozwala im na szybkie i niezobowiązujące rozgrywki. Lubią rywalizację z innymi graczami, ale jednocześnie są w stanie zachować celną postawę i szanować swoich przeciwników. Ponieważ gry kościane opierają się na przypadku, gracze często są otwarci na nieprzewidywalne zdarzenia i niespodziewane zwroty akcji w grze. To sprawia, że gracze są bardziej elastyczni i kreatywni w podejmowaniu decyzji w grze.'
        text3 = 'W końcu, gracze gier kościanych często są pozytywni i pełni energii, ponieważ chętnie doświadczają pozytywnych emocji towarzyszących wygrywaniu w grze. Są to osoby, które cenią sobie możliwość dobrej zabawy i spędzania czasu z przyjaciółmi i rodziną.'
      }
      if (categoryMost === 'Imprezowe / Towarzyskie') {
        text1 = 'Osoby, które grają w gry imprezowe lub towarzyskie, zwykle są graczami, którzy cenią sobie dobrej jakości czas z przyjaciółmi i rodziną. Są to osoby, które lubią towarzystwo innych ludzi i chętnie angażują się w interakcje społeczne. Gracze gier imprezowych lub towarzyskich są zwykle bardzo ekspresywni i kreatywni, ponieważ wiele z tych gier wymaga od graczy spontanicznych działań i pomysłowości. Są to osoby, które lubią rywalizację z innymi graczami, ale jednocześnie są gotowi do współpracy i dzielenia się wiedzą z innymi.'
        text2 = 'Gracze gier imprezowych lub towarzyskich są zwykle otwarci na nowe doświadczenia i lubią eksperymentować, co sprawia, że ​​są bardziej elastyczni i adaptowalni w różnych sytuacjach. Cenią sobie także możliwość dobrej zabawy i śmiechu, a wiele z tych gier jest zaprojektowanych tak, aby wywoływać uśmiech na twarzy graczy. Wiele gier imprezowych lub towarzyskich skupia się na interakcjach społecznych i wymaga od graczy, aby rozmawiali ze sobą i poznawali się nawzajem. Gracze tacy są często bardzo towarzyscy i lubią nawiązywać kontakty z innymi ludźmi. Jednocześnie cenią sobie szacunek dla innych graczy i są gotowi do współpracy i wspierania innych w grze.'
        text3 = 'W końcu, gracze gier imprezowych lub towarzyskich często są bardzo otwarci i komunikatywni, co sprawia, że ​​są dobrymi partnerami do rozmów i interakcji społecznych. Są to osoby, które cenią sobie czas spędzony z ludźmi i chętnie uczestniczą w różnego rodzaju imprezach i spotkaniach towarzyskich.'
      }
      if (categoryMost === 'Kooperacyjne') {
        text1 = 'Osoby, które grają w gry kooperacyjne, zwykle są graczami, którzy cenią sobie współpracę i działanie w grupie. Są to osoby, które lubią pracować razem z innymi i szukają rozwiązań problemów poprzez współpracę zespołową. Gracze gier kooperacyjnych zwykle są bardzo empatyczni i wrażliwi na potrzeby innych graczy. Cenią sobie możliwość pomagania innym i udzielania wsparcia w sytuacjach, gdzie to jest potrzebne. Są to osoby, które zazwyczaj nie lubią rywalizacji i preferują wspólne podejmowanie wyzwań.'
        text2 = 'Gracze gier kooperacyjnych są zwykle bardzo skupieni na celu i zawsze stawiają go na pierwszym miejscu. Są to osoby, które lubią wyzwania i chętnie podejmują się trudnych zadań, ale jednocześnie wiedzą, że osiągnięcie celu wymaga pracy w grupie i wzajemnego wsparcia. Gracze gier kooperacyjnych są zwykle bardzo komunikatywni i lubią wymieniać się pomysłami z innymi graczami. Są to osoby, które potrafią słuchać innych i doceniają różnorodne perspektywy i pomysły. Jednocześnie, zwykle nie boją się wyrażać swojego zdania i chętnie dzielą się własnymi pomysłami.'
        text3 = 'Wiele gier kooperacyjnych wymaga od graczy wspólnego podejmowania decyzji i rozwiązywania problemów. Gracze tacy są zwykle bardzo kreatywni i pomysłowi, ponieważ muszą ciągle wymyślać nowe strategie i podejścia, aby osiągnąć cel. Są to osoby, które lubią myśleć poza schematami i szukać nietypowych rozwiązań. W końcu, gracze gier kooperacyjnych są zwykle bardzo lojalni wobec innych graczy i zawsze stawiają dobro grupy na pierwszym miejscu. Są to osoby, które cenią sobie silne relacje między ludźmi i chętnie angażują się w działania, które przyczyniają się do budowania pozytywnych relacji w grupie.'
      }
      if (categoryMost === 'Ekonomiczne') {
        text1 = 'Osoby, które grają w gry ekonomiczne, zazwyczaj są graczami, którzy cenią sobie strategię i planowanie. Są to osoby, które lubią myśleć o przyszłości i szukać sposobów na osiągnięcie swoich celów. Gracze gier ekonomicznych są zwykle bardzo skupieni na celu i potrafią zrobić wiele, aby go osiągnąć. Gracze gier ekonomicznych zwykle są bardzo cierpliwi i umieją przewidywać rynek. Są to osoby, które potrafią przewidzieć zmiany w trendach rynkowych i dostosować swoje strategie, aby zwiększyć swoje szanse na sukces. Są to osoby, które potrafią być elastyczne i dostosowywać się do zmieniających się warunków.'
        text2 = 'Gracze gier ekonomicznych zwykle są bardzo uważni na szczegóły i lubią analizować dane. Są to osoby, które potrafią spojrzeć na dane w sposób logiczny i wykorzystać je do podejmowania decyzji. Gracze tacy są zwykle bardzo dobrzy w obliczeniach i potrafią dokładnie ocenić ryzyko związane z podejmowaniem decyzji biznesowych. Gracze gier ekonomicznych zwykle są bardzo ambicjonalni i skoncentrowani na sukcesie. Są to osoby, które lubią wyzwania i są gotowe do rywalizacji z innymi graczami. Jednocześnie, zwykle potrafią zachować spokój i koncentrację, nawet w trudnych sytuacjach.'
        text3 = 'Gracze gier ekonomicznych zwykle są bardzo zorientowani na finanse i cenią sobie oszczędność. Są to osoby, które potrafią skutecznie zarządzać swoimi finansami i wiedzą, jak wykorzystać swoje środki w sposób efektywny. Jednocześnie, zwykle są one bardzo ostrożne i zawsze starają się minimalizować ryzyko finansowe. Wiele gier ekonomicznych wymaga od graczy negocjacji i podejmowania decyzji biznesowych w sytuacjach stresowych. Gracze tacy są zwykle bardzo zdeterminowani i potrafią zrobić wiele, aby osiągnąć swoje cele. Są to osoby, które potrafią skutecznie przekonywać innych i wykorzystywać swoje umiejętności interpersonalne w celu osiągnięcia sukcesu.'
      }
      if (categoryMost === 'Dla dzieci / Edukacyjne') {
        text1 = 'Osoby, które grają w gry dla dzieci/edukacyjne, zazwyczaj są rodzicami, opiekunami lub nauczycielami, którzy chcą rozwijać umiejętności poznawcze, społeczne i emocjonalne dzieci. Są to osoby, które cenią sobie edukację i chcą wykorzystać gry jako narzędzie do nauki i rozwoju dziecka. Osoby te często są cierpliwe, empatyczne i lubią pracować z dziećmi. Są one gotowe do poświęceń, aby wspierać rozwój dziecka i pomóc mu osiągnąć cele. Są to osoby, które potrafią zwracać uwagę na potrzeby dziecka i dostosować swoje podejście do jego indywidualnych potrzeb.'
        text2 = 'Gracze gier dla dzieci/edukacyjnych zwykle są kreatywni i elastyczni w podejściu do rozwiązywania problemów. Są to osoby, które potrafią myśleć poza schematami i wykorzystywać swoją wyobraźnię, aby pomóc dziecku w zrozumieniu trudnych koncepcji. Są to osoby, które potrafią stosować różnorodne strategie i podejścia, aby dostosować się do stylu uczenia się dziecka. Gracze gier dla dzieci/edukacyjnych zwykle są bardzo cierpliwi i potrafią pracować z dziećmi w różnym wieku. Są to osoby, które potrafią zwracać uwagę na szczegóły i są gotowe do powtarzania tych samych czynności, aby dziecko lepiej zrozumiało koncepcje. Są to osoby, które potrafią zachęcać dziecko do aktywnego uczestnictwa w grze i do podejmowania własnych decyzji.'
        text3 = 'Gracze gier dla dzieci/edukacyjnych zwykle są bardzo zorientowani na rozwój dziecka i jego potrzeby. Są to osoby, które potrafią stworzyć przyjazną i bezpieczną atmosferę dla dziecka, aby mogło czuć się swobodnie i cieszyć się grą. Są to osoby, które potrafią dostosować trudność gry do umiejętności dziecka, aby zwiększyć jego zaangażowanie i motywację. W gry dla dzieci/edukacyjne zazwyczaj gra się w grupie, co wymaga od graczy umiejętności pracy zespołowej i komunikacji. Gracze tacy zwykle są otwarci i gotowi do współpracy z innymi, aby pomóc dziecku w zrozumieniu koncepcji i osiągnięciu celów. Są to osoby, które potrafią wykorzystać swoje umiejętności interpersonalne, aby pomóc dziecku w rozwoju społecznym i emocjonalnym.'
      }
      if (categoryMost === 'Inne') {
        text1 = '"Gry Inne" to bardzo szeroka kategoria, w której znajdują się różne gry o różnej tematyce i zasadach. Jednakże, zazwyczaj osoby grające w gry z tej kategorii nie są związane z jednym konkretnym typem gracza. Mogą to być zarówno osoby lubiące strategiczne myślenie, jak i osoby preferujące luźną zabawę bez zbytniego skupiania się na zasadach.'
        text2 = 'Gracze grający w gry z tej kategorii mogą być otwarci na nowe doświadczenia, eksperymentowanie z różnymi typami gier i zasadami, a także mogą być bardziej zrelaksowani i nastawieni na dobrą zabawę niż na zwycięstwo. Oczywiście, są to tylko ogólne tendencje i każda osoba grająca w gry z kategorii "Gry Inne" może mieć zupełnie inny styl gry i podejście.'
      }
      return (
        <Box>
          <Typography sx={{ marginBottom: '10px', fontSize: '17px' }}>
            Kategoria gier, którą lubisz najbardziej, to gry: <b>{categoryMost}</b>
          </Typography>
          {
            text1?.length > 0 && (
              <Typography sx={{ marginBottom: '10px', fontSize: '17px' }}>
                {text1}
              </Typography>
            )
          }
          {
            text2?.length > 0 && (
              <Typography sx={{ marginBottom: '10px', fontSize: '17px' }}>
                {text2}
              </Typography>
            )
          }
          {
            text3?.length > 0 && (
              <Typography sx={{ marginBottom: '20px' }}>
                {text3}
              </Typography>
            )
          }
        </Box>
      )
    }
  }
  useEffect(() => {
    if (games?.length > 0) {
      let countsNumber = 0
      let countsEl = 0
      games.forEach(el => {
        const categoriesGame = el.category
        categoriesGame?.forEach(cat => {
          categories?.forEach(el => {
            if (el.value === cat) {
              el.count = el.count + 1
            }
          })
        })
      })
      const tempLabels = []
      const tempValues = []
      categories?.forEach(el => {
        if (el?.count > countsNumber) {
          countsNumber = el?.count
          countsEl = el
        }
        tempLabels.push(el?.value)
        tempValues.push(Number(el?.count))
      })
      setCategoryMost(countsEl?.name)
      setData({
        labels: tempLabels,
        datasets: [
          {
            label: 'Jesteś tym w co grasz',
            data: tempValues,
            backgroundColor: 'rgba(46, 49, 121, .12)',
            borderColor: '#2e3179',
            borderWidth: 1,
          },
        ],
      })
    }
  }, [games])
  useEffect(() => {
    ListGamesHelper.getYourGames(LocalStorageHelper.get('user')?.name).then((res) => {
      if (res) setGames(res)
    })
    generateGames()
  }, [])
  useEffect(() => {
    if (possibleToAdd?.length > 0) {
      getAllGames().then(allGames => {
        if (allGames) {
          const temp = []
          possibleToAdd?.forEach((elPossible) => {
            allGames?.forEach((el) => {
              const isExist = el?.category?.find(el => el === categoryMost)
              if (el?.name === elPossible?.label && isExist?.length > 0) {
                temp.push(el)
              }
            })
          })
          setPossibleToAddGames(temp)
        }
      })
    }
  }, [possibleToAdd])
  return (
    <div style={style.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: '17px', marginBottom: '10px' }}>
            Z pewnością! Ta funkcja pozwala Ci zobaczyć, jakie kategorie gier przeważają w Twoim profilu gracza, na podstawie gier, które dodałeś. Diagram kategorii pozwala na szybkie i łatwe zobaczenie, jakie typy gier lubisz najbardziej i na ile są one reprezentatywne dla Twojego stylu gry.
          </Typography>
          <Typography sx={{ fontSize: '17px', marginBottom: '10px' }}>
            Dodatkowo, opis gracza, który możesz zobaczyć na podstawie swoich gier, daje podgląd na to, kim jesteś jako gracz. Jest to krótki opis, który odzwierciedla Twoje preferencje i styl gry na podstawie kategorii gier, które dodajesz do swojego profilu.
          </Typography>
          <Typography sx={{ fontSize: '17px', marginBottom: '10px' }}>
            Dzięki tym funkcjom, możesz lepiej poznać swoje preferencje w grach i zrozumieć, co sprawia Ci największą przyjemność w grach.
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          { data && <Radar data={data} /> }
        </Grid>
        <Grid item md={4} xs={12}>
          {generatorProfileText()}
        </Grid>
        <Grid item md={12}>
          <Typography sx={{ fontWeight: 900, fontSize: '25px', marginBottom: '30px', textTransform: 'uppercase' }}>
            Proponowane gry, których nie masz na podstawie lubianej kategorii
          </Typography>
          {
            possibleToAddGames?.length > 0 && (
              <GamesList games={possibleToAddGames}/>
            )
          }
        </Grid>
      </Grid>
    </div>
  )
}

export default StatisticGamePage
