import Error from '../../assets/images/error/404.gif'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const ErrorsPage = () => {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <img src={Error} alt="404" />
      <Typography
        sx={{
          position: 'absolute',
          top: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          width: '100%',
          fontSize: '24px',
        }}
      >
        Error 404 - wszedłeś w miejsce, gdzie cie nie powinno być.
      </Typography>
    </Box>
  )
}

export default ErrorsPage
