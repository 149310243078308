import { getAllDataFromPath, getAllEvents } from '../../firebase'
import NotificationService from './NotificationService'

export default {
  set: (number, message) => {
    const blackList = []
    if (!blackList.includes(number)) {
      return fetch(`https://api.mobitex.pl/sms.php?user=${'jasieksz1992'}&pass=${'b29326b90390c3696ce39b0b83027b28'}&type=${'unicode'}&number=${`48${number?.replace(' ', '')}`}&text=${message?.replace(' ', '+')}`, {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Access-control-allow-origin': '*',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      })
    }
  },
  sendToUsersFromEvent: async (e, numbers) => {
    return await getAllDataFromPath('users').then((res) => {
      if (res) {
        numbers?.map((el) => {
          if (e?.notificationText) {
            NotificationService.set(el, e.notificationText)
          }
          return true
        })
      }
    })
  },
  sendToAllUsers: async (message) => {
    return await getAllDataFromPath('users').then((res) => {
      if (res) {
        const numbers = []
        res?.map(el => {
          if (el?.data?.phone) {
            if (!el?.data?.notSend) {
              const withNoSpaces = el?.data?.phone.toString().trim().replace(' ', '')
              if (withNoSpaces.length >= 9) {
                numbers.push(withNoSpaces)
              }
            }
          }
          return true
        })
        numbers?.map((el) => {
          NotificationService.set(el, message)
          return true
        })
      }
    })
  }
}
