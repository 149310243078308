import React from 'react'
import { Grid } from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import GroupAccordion from '../../components/molecules/GroupAccordion/GroupAccordion'
import oswiadczenie from '../../assets/pdf/oświadczenie.pdf'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'

const RulePage = () => {
	const t = LocalStorageHelper.get('translation')
  const style = {
    root: {
      padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
    },
  }
	const elements = [
		{
			title: t.globals.components.GroupAccordion.events,
			text: t.globals.components.GroupAccordion.eventsData,
		},
		{
			title: t.globals.components.GroupAccordion.price,
			text: t.globals.components.GroupAccordion.priceData,
		},
		{
			title: t.globals.components.GroupAccordion.images,
			text: t.globals.components.GroupAccordion.imagesData,
		},
		{
			title: t.globals.components.GroupAccordion.register,
			text: t.globals.components.GroupAccordion.registerData,
		},
		{
			title: t.globals.components.GroupAccordion.sick,
			text: t.globals.components.GroupAccordion.sickData,
		},
		{
			title: t.globals.components.GroupAccordion.talk,
			text: t.globals.components.GroupAccordion.talkData,
		},
		{
			title: t.globals.components.GroupAccordion.rules,
			text: t.globals.components.GroupAccordion.rulesData,
		},
		{
			title: t.globals.components.GroupAccordion.eat,
			text: t.globals.components.GroupAccordion.eatData,
		},
		{
			title: t.globals.components.GroupAccordion.child,
			text: t.globals.components.GroupAccordion.child + ' <a href="' + oswiadczenie + '" target="blank" rel="noreferrer">' + t.globals.components.GroupAccordion.childDataLink + '</a>. ' + t.globals.components.GroupAccordion.childData2,
		},
	]
  return (
	  <>
		  <div style={style.root}>
			  <Grid container spacing={4}>
				  <Grid item md={12} xs={12}>
					  <GroupAccordion elements={elements} />
				  </Grid>
			  </Grid>
		  </div>
	  </>
  )
}

export default RulePage
