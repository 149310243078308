import { Autocomplete, CircularProgress, Grid, Input, Slider, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { AppsOutlined, MenuOutlined } from '@mui/icons-material'
import GameBox from '../../molecules/GameBox/GameBox'
import React, { useEffect, useState } from 'react'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import { useHistory } from 'react-router'
import { getAllGames } from '../../../firebase'
import ArrayHelper from '../../../helpers/ArrayHelper/ArrayHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import StringHelper from '../../../helpers/StringHelper/StringHelper'
import { styled } from '@mui/material/styles'

const SliderDot = styled(Slider)({
  color: '#edac2d',
  height: 10,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#edac2d',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})

const GamesList = ({ games }) => {
  const t = LocalStorageHelper.get('translation')
  const [data, setData] = useState([])
  const [defaultData, setDefaultData] = useState([])
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [variant, setVariant] = useState('tiles')
  const [age, setAge] = useState([5, 18])
  const [players, setPlayers] = useState([1, 20])
  const [time, setTime] = useState([0, 300])
  const [search, setSearch] = useState('')
  const [categories, setCategories] = useState(null)
  const [publishingHouse, setPublishingHouse] = useState(null)
  const [publishingHouseOptions, setPublishingHouseOptions] = useState([])
  const optionsCategories = [
    { name: 'Strategiczne', label: 'Strategiczne' },
    { name: 'RPG', label: 'RPG' },
    { name: 'Rodzinne / Familijne', label: 'Rodzinne / Familijne' },
    { name: 'Fabularne / Przygodowe', label: 'Fabularne / Przygodowe' },
    { name: 'Paragrafowe', label: 'Paragrafowe' },
    { name: 'Karciane', label: 'Karciane' },
    { name: 'Kościane', label: 'Kościane' },
    { name: 'Imprezowe / Towarzyskie', label: 'Imprezowe / Towarzyskie' },
    { name: 'Kooperacyjne', label: 'Kooperacyjne' },
    { name: 'Ekonomiczne', label: 'Ekonomiczne' },
    { name: 'Dla dzieci / Edukacyjne', label: 'Dla dzieci / Edukacyjne' },
    { name: 'Dodatki', label: 'Dodatki' },
    { name: 'Inne', label: 'Inne' },
  ]
  const getAllPublishHouses = () => {
    const dataOptions = []
    if (!games) {
      getAllGames().then((res) => {
        if (res) {
          const allPublishHouses = []
          res?.map(el => {
            if (el?.publishingHouse?.length > 0) allPublishHouses.push(el?.publishingHouse)
            return true
          })
          ArrayHelper.deleteDuplicate(allPublishHouses).map(el => dataOptions.push({ value: el, label: el }))
        }
      })
    } else {
      const allPublishHouses = []
      games?.map(el => {
        if (el?.publishingHouse?.length > 0) allPublishHouses.push(el?.publishingHouse)
        return true
      })
      ArrayHelper.deleteDuplicate(allPublishHouses).map(el => dataOptions.push({ value: el, label: el }))
    }
    setPublishingHouseOptions(dataOptions)
  }
  const markTime = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 30,
      label: '30',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 90,
      label: '90',
    },
    {
      value: 120,
      label: '120',
    },
    {
      value: 150,
      label: '150',
    },
    {
      value: 200,
      label: '200',
    },
  ]
  const markPlayers = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 20,
      label: '20',
    },
  ]
  const markAges = [
    {
      value: 5,
      label: '5 lat',
    },
    {
      value: 12,
      label: '12 lat',
    },
    {
      value: 18,
      label: '18 lat',
    },
  ]
  const style = {
    box: {
      display: 'flex',
      background: '#20225e',
      borderRadius: '10px',
      flexDirection: 'column',
      marginBottom: '16px',
    },
    boxTitle: {
      fontWeight: '900',
      fontSize: '20px',
      textTransform: 'uppercase',
      background: '#20225e',
      display: 'flex',
      color: 'white',
      alignItems: 'center',
      padding: '10px 20px',
      borderRadius: '10px',
    },
    descriptionBox: {
      color: 'white',
      padding: '20px',
      borderRadius: '10px',
      background: '#2e3179',
    },
    textInBox: {
      fontSize: '20px',
    },
    counts: {
      background: '#2e3179',
      display: 'flex',
      padding: '20px',
      color: 'white',
      width: 'calc(100% - 40px)',
      marginBottom: '16px',
      borderRadius: '10px',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'sticky',
      top: 0,
    },
    titleCounts: {
      fontSize: '22px',
      textTransform: 'uppercase',
    },
    notFound: {
      fontSize: BreakpointsHelper.isMobile() ? '15px' : '18px',
      color: '#121a44',
      fontWeight: '700',
      paddingLeft: '33px',
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filter: {
      display: 'flex',
      padding: BreakpointsHelper.isMobile() ? '0 15px' : '0 50px',
      flexDirection: BreakpointsHelper.isMobile() && 'column',
    },
    filterItem: {
      paddingBottom: 0,
      paddingTop: 0,
    },
  }
  const handleStart = () => {
    if (!games) {
      getAllGames().then((res) => {
        if (res) {
          setData(res)
          setDefaultData(res)
        }
      })
    } else {
      setData(games)
      setDefaultData(games)
    }
  }
  useEffect(() => {
    handleStart()
    getAllPublishHouses()
  }, [games])
  useEffect(() => {
    setLoading(true)
    const minPlayers = players[0]
    const maxPlayers = players[1]
    const minAge = age[0]
    const maxAge = age[1]
    const minTime = time[0]
    const maxTime = time[1]
    const tempData = []
    defaultData?.map(el => {
      if (Number(el?.minPerson) >= minPlayers && Number(el?.maxPerson) <= maxPlayers) {
        if (Number(el?.years) >= minAge && Number(el?.years) <= maxAge) {
          if (Number(el?.minTime) >= minTime && Number(el?.maxTime) <= maxTime) {
            if (search?.length > 0) {
              if (StringHelper.toDisableCharsPL(el?.name?.toLowerCase()).search(StringHelper.toDisableCharsPL(search?.toLowerCase())) >= 0) tempData.push(el)
            } else tempData.push(el)
          }
        }
      } else {
        setData(defaultData)
      }

      return true
    })
    if (categories?.length > 0) {
      const tempCategoriesData = []
      tempData?.forEach(el => el?.category?.forEach(cat => cat === categories && tempCategoriesData.push(el)))
      if (publishingHouse?.length > 0) {
        const tempPublishHouse = []
        tempCategoriesData?.forEach(el => el?.publishingHouse === publishingHouse && tempPublishHouse.push(el))
        setData(tempPublishHouse)
      } else setData(tempCategoriesData)
    } else {
      if (publishingHouse?.length > 0) {
        const tempPublishHouse = []
        tempData?.forEach(el => el?.publishingHouse === publishingHouse && tempPublishHouse.push(el))
        setData(tempPublishHouse)
      } else {
        if (tempData?.length > 0) {
          setData(tempData)
        } else {
          setData(games || defaultData)
        }
      }
    }
    setLoading(false)
  }, [players, age, time, categories, publishingHouse, search, games])
  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={3} xs={12}>
        <Box sx={style.box}>
          <Box sx={style.boxTitle}>
            Wyszukiwarka
          </Box>
          <Box sx={style.descriptionBox}>
            <Input
              sx={{ background: 'white', width: '100%', padding: '5px 15px', }}
              variant="outlined"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Wpisz tytuł gry"
            />
          </Box>
        </Box>
        <Box sx={style.box}>
          <Box sx={style.boxTitle}>
            Filtrowanie
          </Box>
          <Box sx={style.descriptionBox}>
            <Typography sx={style.textInBox}>Ilość graczy, od {players[0]} do {players[1]}</Typography>
            <Box sx={{ padding: '10px' }}>
              <SliderDot
                value={players}
                max={20}
                min={1}
                onChange={(e, value) => setPlayers(value)}
                valueLabelDisplay="auto"
                marks={markPlayers}
                step={1}
                getAriaValueText={(value) => `${value} lat`}
              />
            </Box>
            <Typography sx={style.textInBox}>Wiek, od {age[0]} do {age[1]}</Typography>
            <Box sx={{ padding: '10px' }}>
              <SliderDot
                sx={{ color: '#edac2d' }}
                value={age}
                max={18}
                min={5}
                onChange={(e, value) => setAge(value)}
                valueLabelDisplay="auto"
                step={1}
                marks={markAges}
                getAriaValueText={(value) => `${value} lat`}
              />
            </Box>
            <Typography sx={style.textInBox}>Czas gry, od {time[0]} min. do {time[1]} min.</Typography>
            <Box sx={{ padding: '10px' }}>
              <SliderDot
                sx={{ color: '#edac2d' }}
                value={time}
                max={200}
                min={0}
                onChange={(e, value) => setTime(value)}
                valueLabelDisplay="auto"
                step={30}
                marks={markTime}
                getAriaValueText={(value) => `${value} lat`}
              />
            </Box>
            <Typography sx={style.textInBox}>Kategorie{`${categories?.length > 0 ? ` ,${categories}` : ''}`}</Typography>
            <Box sx={{ padding: '10px 0' }}>
              <Autocomplete
                sx={{ background: 'white' }}
                options={optionsCategories?.map((option) => option?.label)}
                onChange={(e) => setCategories(e.target.value)}
                onKeyUp={(e) => setCategories(e.target.value)}
                onBlur={(e) => setCategories(e.target.value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={categories}
                    placeholder="Wybierz kategorie"
                  />
                )}
              />
            </Box>
            <Typography sx={style.textInBox}>Wydawnictwo{`${publishingHouse?.length > 0 ? ` ,${publishingHouse}` : ''}`}</Typography>
            <Box sx={{ padding: '10px 0' }}>
              <Autocomplete
                sx={{ background: 'white' }}
                options={publishingHouseOptions?.map((option) => option?.label)}
                onChange={(e) => setPublishingHouse(e.target.value)}
                onKeyUp={(e) => setPublishingHouse(e.target.value)}
                onBlur={(e) => setPublishingHouse(e.target.value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={publishingHouse}
                    placeholder="Wybierz wydawnictwo"
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={9} md={9} xs={12}>
        <Box sx={style.counts}>
          <Typography sx={style.titleCounts}>
            {data?.length} wyników
          </Typography>
          <Box>
            <Button onClick={() => setVariant('tiles')} variant="contained" sx={{ marginRight: '10px', background: variant === 'tiles' && '#edac2d' }}>
              <AppsOutlined />
            </Button>
            <Button onClick={() => setVariant('lines')} variant="contained" sx={{ background: variant === 'lines' && '#edac2d' }}>
              <MenuOutlined />
            </Button>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            {
              data?.map((el, index) => (
                <Grid item md={variant === 'tiles' ? 4 : 12} xs={12} key={index}>
                  <GameBox
                    name={el?.name}
                    image={el?.image}
                    line={variant !== 'tiles'}
                    publishingHouse={el.publishingHouse}
                    min={el?.minPerson}
                    max={el?.maxPerson}
                    who={el?.who}
                    onClick={() => history.push(`/previewGame?id=${el?.id}`)}
                  />
                </Grid>
              ))
            }
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default GamesList
