import React, { useEffect, useState } from 'react'
import { getAllGames } from '../../firebase'
import Box from '@mui/material/Box'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import bg from '../../assets/images/banner-coaching-bg.png'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import { AccessTimeOutlined, ArrowBackIosOutlined, ElderlyOutlined, PeopleOutlined } from '@mui/icons-material'
import { useHistory } from 'react-router'
import SkeletonLoader from '../../components/atoms/SkeletionLoader/SkeletonLoader'
import Dialog from '../../components/atoms/Dialog/Dialog'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { useSnackbar } from 'notistack'
import GameHelper from '../../helpers/GameHelper/GameHelper'
import Button from '@mui/material/Button'

const GamePreviewPage = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const t = LocalStorageHelper.get('translation')
  const profile = new URLSearchParams(props.location.history.location.search).get('profile')
  const idGame = new URLSearchParams(props.location.history.location.search).get('id')
  const history = useHistory()
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: '100%',
      minHeight: 'calc(100vh - 250px)',
    },
    header: {
      background: `url(${bg}) no-repeat`,
      backgroundSize: BreakpointsHelper.isMobile() ? 'auto 100%' : '250%',
      backgroundPosition: 'center center',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: BreakpointsHelper.isMobile() ? '80px 15px 30px 15px' : '50px',
    },
    title: {
      fontSize: BreakpointsHelper.isMobile() ? '35px' : '85px',
      lineHeight: 1.1,
      fontWeight: '900',
      color: 'white',
      marginTop: BreakpointsHelper.isMobile() ? '0' : '40px',
      textTransform: 'uppercase',
    },
    secondTitle: {
      fontSize: BreakpointsHelper.isMobile() ? '22px' : '40px',
      fontWeight: '400',
      color: 'white',
    },
    box: {
      position: 'relative',
      top: BreakpointsHelper.isMobile() ? '0' : '-100px',
      width: 'calc(100% - 60px)',
      padding: '30px',
      borderRadius: '20px',
      background: 'white',
    },
    imageBox: {
      height: 'auto',
      maxWidth: 'calc(90% - 20px)',
      margin: '0 auto auto 20px',
    },
    titleBox: {
      fontSize: BreakpointsHelper.isMobile() ? '26px' : '45px',
      lineHeight: 1.1,
      fontWeight: '900',
      color: '#2a3270',
      textTransform: 'uppercase',
    },
    publishingHouseBox: {
      fontSize: BreakpointsHelper.isMobile() ? '28px' : '45px',
      fontWeight: '400',
      color: '#2a3270',
    },
    categoryItem: {
      color: 'white',
      padding: '8px 15px',
      marginRight: BreakpointsHelper.isMobile() ? '0' : '10px',
      marginBottom: '10px',
      fontSize: '20px',
      fontWeight: '400',
      background: '#3d4899',
      borderRadius: '20px',
      width: BreakpointsHelper.isMobile() ? '100%' : 'auto',
    },
    who: {
      color: '#2a3270',
      marginRight: BreakpointsHelper.isMobile() ? '0' : '10px',
      marginBottom: '10px',
      fontSize: '24px',
      fontWeight: '400',
      width: BreakpointsHelper.isMobile() ? '100%' : 'auto',
    },
    whoTitle: {
      fontSize: BreakpointsHelper.isMobile() ? '22px' : '24px',
      fontWeight: '400',
      color: '#2a3270',
      marginRight: BreakpointsHelper.isMobile() ? '0' : '10px',
      marginBottom: '10px',
    },
    titleDescription: {
      fontSize: '40px',
      color: 'white',
      marginTop: '40px',
      textTransform: 'uppercase',
      fontWeight: '900',
    },
    text: {
      fontSize: '24px',
      background: 'transparent',
      color: 'rgb(170, 197, 238)',
    },
    tag: {
      background: 'white',
      color: '#2a3270',
      borderRadius: '15px',
      padding: '15px',
      fontSize: '24px',
      fontWeight: '900',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      color: '#aac5ee',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    icons: {
      marginRight: '20px',
      transform: 'scale(1.2)',
    },
    backLink: {
      background: 'transparent',
      cursor: 'pointer',
      position: 'absolute',
      color: 'white',
      top: BreakpointsHelper.isMobile() ? '30px' : '50px',
      left: BreakpointsHelper.isMobile() ? '15px' : '50px',
      fontSize: BreakpointsHelper.isMobile() ? '20px' : '24px',
      display: 'flex',
      alignItems: 'center',
    },
  }
  const addGame = async (e) => await GameHelper.addGameToYourStack(e, enqueueSnackbar)
  useEffect(() => {
    getAllGames().then((res) => {
      if (res) {
        res?.map(el => {
          if (el?.id === idGame) setData(el)
          return true
        })
      }
    })
  }, [])
  return (
    <Box sx={style.root}>
      <SkeletonLoader circle variable={data} {...props}>
        <Box
          sx={style.backLink}
          onClick={() => profile ? history.push('/profile') : history.push('/games')}
        >
          <ArrowBackIosOutlined sx={style.icons} />
          {profile ? 'Powrót do profilu gracza' : 'Powrót do listy gier'}
        </Box>
        <Box sx={style.header}>
          <Typography sx={style.title}>
            {data?.name}
          </Typography>
          <Typography sx={style.secondTitle}>
            Lista gier / Podgląd danych gry
          </Typography>
        </Box>
        <Box sx={{ padding: BreakpointsHelper.isMobile() ? '50px 15px' : '50px', background: '#1f2340', minHeight: 'calc(100vh - 250px)' }}>
          <Box sx={style.box}>
            <Grid container spacing={4}>
              <Grid item md={3} xs={12}>
                <img src={data?.image} alt={data?.name} style={style.imageBox} />
              </Grid>
              <Grid item md={9} xs={12}>
                <Box sx={{ width: '100%' }}>
                  <Typography sx={style.titleBox}>
                    {data?.name}
                  </Typography>
                  <Typography sx={style.publishingHouseBox}>
                    {data?.publishingHouse}
                  </Typography>
                  <hr style={{ background: 'white', width: '100%' }} />
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                    {
                      data?.category?.map((el, index) => {
                        return (
                          <Box key={index} sx={style.categoryItem}>
                            {el}
                          </Box>
                        )
                      })
                    }
                  </Box>
                  {
                    data?.who?.length > 0 && (
                      <Box sx={{ marginTop: BreakpointsHelper.isMobile() ? '20px' : '40px' }}>
                        <Typography sx={style.whoTitle}>
                          <b>Właściciele gier:</b> &nbsp;
                          { BreakpointsHelper.isMobile() && (<br/>) }
                          { data?.who?.map((el) => el).join(', ') }
                        </Typography>
                        <Button
                          sx={{
                            background: '#2a3270',
                            color: 'white',
                            height: '40px',
                            width: BreakpointsHelper.isMobile() ? '100%' : 'auto'
                          }}
                          variant="contained"
                          onClick={() => addGame({ name: data?.name })}
                        >
                          Dodaj grę do swojej kolekcji
                        </Button>
                      </Box>
                    )
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography sx={style.titleDescription}>
              Opis
            </Typography>
            <Box className="htmlCode" dangerouslySetInnerHTML={{ __html: data?.description }}/>
            <Box sx={{ margin: '50px 0' }}>
              <Grid container spacing={4}>
                {
                  data?.minPerson && data?.maxPerson && (
                    <Grid item md={4} xs={12}>
                      <Box sx={style.tag}>
                        <PeopleOutlined sx={style.icons} />
                        {data?.minPerson} - {data?.maxPerson} graczy
                      </Box>
                    </Grid>
                  )
                }
                {
                  data?.maxTime && data?.minTime && (
                    <Grid item md={4} xs={12}>
                      <Box sx={style.tag}>
                        <AccessTimeOutlined sx={style.icons} />
                        { data?.minTime === data?.maxTime ? `${data?.minTime} min.` : `${data?.minTime} - ${data?.maxTime} min.`}
                      </Box>
                    </Grid>
                  )
                }
                {
                  data?.years && (
                    <Grid item md={4} xs={12}>
                      <Box sx={style.tag}>
                        <ElderlyOutlined sx={style.icons} />
                        {data?.years} +
                      </Box>
                    </Grid>
                  )
                }
              </Grid>
            </Box>
            <Typography sx={style.titleDescription}>
              Poznaj zasady
            </Typography>
            <Box sx={style.text}>
              <a href={data?.tutorial} target="_blank" style={style.link} rel="noreferrer">Kliknij, aby poznać zasady gry.</a>
            </Box>
            <Typography sx={style.titleDescription}>
              Znajdź grę u naszych partnerów
            </Typography>
            <Box sx={style.text}>
              <a href={`https://cse.google.com/cse?cx=47b3c59253b014503#gsc.tab=0&gsc.q=${data?.name}&gsc.sort=&gsc.page=1`} target="_blank" style={style.link} rel="noreferrer">Kliknij, aby wyszukać grę.</a>
            </Box>
          </Box>
        </Box>
      </SkeletonLoader>
    </Box>
  )
}

export default GamePreviewPage
