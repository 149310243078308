import firebase from 'firebase/compat/app'
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { getDownloadURL, getStorage, listAll, ref, uploadBytes } from 'firebase/storage'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import LocalStorageHelper from './helpers/LocalStorageHelper/LocalStorageHelper'
import DateHelper from './helpers/DateHelper/DateHelper'
import ArrayHelper from './helpers/ArrayHelper/ArrayHelper'
import StringHelper from './helpers/StringHelper/StringHelper'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// eslint-disable-next-line jest/require-hook
const app = firebase.initializeApp(firebaseConfig)

const auth = getAuth()
const db = getFirestore()
const providerGoogle = new GoogleAuthProvider()
const providerFacebook = new FacebookAuthProvider()

export const loginWithGoogle = () => {
  return signInWithPopup(auth, providerGoogle)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result)
      const token = credential.accessToken
      const user = result.user
      addDocument(null, user)
      return { token, user }
    }).catch((error) => console.error(error.code, error.message, error.email, GoogleAuthProvider.credentialFromError(error)))
}

export const loginWithFacebook = async () => {
  return signInWithPopup(auth, providerFacebook)
    .then((result) => {
      const credential = FacebookAuthProvider.credentialFromResult(result)
      const token = credential.accessToken
      const user = result.user
      addDocument(null, user)
      return { token, user }
    }).catch((error) => {
      if (error.code === 'auth/account-exists-with-different-credential') return 'error-social'
      console.error(error.code, error.message, error.email, FacebookAuthProvider.credentialFromError(error))
    })
}

export const signOutFirebase = () => signOut(auth).then((res) => res).catch((error) => console.error(error.code, error.message))

export const getAllUsers = async () => await getAllDataFromPath('users').then((res) => res)

export const getAllGames = () => {
  return getAllDataFromPath('games').then((res) => {
    const gameListTemp = []
    res?.map(element => {
      const tempElement = element?.data
      tempElement.id = element?.id
      gameListTemp.push(tempElement)
      return true
    })
    return ArrayHelper.deleteDuplicate(gameListTemp)
  })
}

// export const listAllUsers = (nextPageToken) => {
//   return auth.listUsers(1000, nextPageToken).then(async (listUsersResult) => {
//     await listUsersResult.users.forEach((userRecord) => userRecord.toJSON())
//     if (listUsersResult?.pageToken) {
//       return listAllUsers(listUsersResult.pageToken)
//     }
//   }).catch((error) => console.error('Error listing users:', error))
// }

export const createUser = (data) => {
  return createUserWithEmailAndPassword(auth, data.email, data.password).then(async (userCredential) => {
    const user = await userCredential?.user
    if (user) {
      sendEmailVerified(user)
      return addDocument(data, user).then(res => res)
    }
  }).catch((error) => console.error(error.code, error.message))
}

export const changeDataUser = async (email, data) => {
  const docRef = doc(db, 'users', email)
  await updateDoc(docRef, {
    name: data?.name,
    phone: data?.phone,
    street: data?.street,
    apartment: data?.apartment,
    house: data?.house,
    notSend: data?.notSend,
    defaultLang: data?.defaultLang,
  })
  return true
}

export const sendEmailVerified = (userCredential) => {
  sendEmailVerification(userCredential).then(() => LocalStorageHelper.set('emailForSignIn', true)).catch((error) => console.error(error.code, error.message))
}

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email).then(() => true).catch((error) => console.error(error.code, error.message))
}

export const updateOrCreateDocument = async (path, pathSegment, data) => {
  if (pathSegment) {
    await setDoc(doc(db, path, pathSegment), data)
  } else {
    await addDoc(collection(db, path), data)
  }
  return data
}

export const getDataFromPath = async (path, pathSegments) => {
  const docRef = doc(db, path, pathSegments)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) return docSnap.data()
  return {}
}

export const addDocument = async (data, user) => {
  const sendData = {
    uid: user?.uid,
    email: data?.email || user?.email || '',
    phone: data?.phone || user?.phoneNumber || '',
    name: `${data?.firstName} ${data?.secondName}` || user?.name || '',
    role: 'user',
  }
  return await updateOrCreateDocument('users', data.email || user.email, sendData).then((res) => {
    if (res) {
      LocalStorageHelper.set('user', sendData)
      return sendData
    }
  })
}

export const updateUsers = async (data) => await updateOrCreateDocument('users', data?.email, data).then(res => res)

export const getAllDataFromPath = async (path) => {
  const allDocs = []
  const querySnapshot = await getDocs(collection(db, path))
  await querySnapshot.forEach((doc) => allDocs.push({ id: doc.id, data: doc.data() }))
  return allDocs
}

export const setAllPostVisible = async (idEvent) => {
  return await getAllDataFromPath('events').then(async (res) => {
    if (res) {
      let data = {}
      res?.map(el => {
        if (el.id === idEvent) data = el
        return true
      })
      const dataUser = data?.data
      let messagesTab = []
      messagesTab = dataUser?.messages
      messagesTab?.map(el => {
        let personAdd = false
        if (!el?.seePost) {
          el.seePost = []
          el.seePost?.push({
            email: LocalStorageHelper.get('user')?.email,
          })
        } else {
          el?.seePost?.map(person => {
            if (person?.email === LocalStorageHelper.get('user').email) {
              personAdd = true
            }
            return true
          })
          if (!personAdd) {
            el.seePost?.push({
              email: LocalStorageHelper.get('user').email,
            })
          }
        }
        return true
      })
      const docRef = doc(db, 'events', idEvent)
      if (docRef && messagesTab) {
        await updateDoc(docRef, { messages: messagesTab })
      }
      return true
    }
  })
}

export const deletePersonFromEvent = async (idEvent, name) => {
  const yourName = LocalStorageHelper.get('user').name
  if (name !== yourName) {
    return await getAllDataFromPath('events').then(async (res) => {
      if (res) {
        let data = null
        res.map(el => {
          if (el.id === idEvent) data = el
          return true
        })
        const dataUsers = data?.data?.users
        const seats = data?.data?.seat
        const docRef = doc(db, 'events', idEvent)
        await updateDoc(docRef, { users: ArrayHelper.deleteObjectFromArrayByValue(dataUsers, 'name', name), seat: seats - 1 })
      }
    })
  }
  return false
}

export const deletePersonFromTournament = async (idEvent, name) => {
  const yourName = LocalStorageHelper.get('user').name
  if (name !== yourName) {
    return await getAllDataFromPath('events').then(async (res) => {
      if (res) {
        let data = null
        res.map(el => {
          if (el.id === idEvent) data = el
          return true
        })
        const dataUsers = data?.data?.users
        const seats = data?.data?.seat
        const docRef = doc(db, 'tournament', idEvent)
        await updateDoc(docRef, { users: ArrayHelper.deleteObjectFromArrayByValue(dataUsers, 'name', name), seat: seats - 1 })
      }
    })
  }
  return false
}

export const deleteCommentEvent = async (idEvent, datePost, dateComment) => {
  return await getAllDataFromPath('events').then(async (res) => {
    if (res) {
      let data = null
      res.map(el => {
        if (el.id === idEvent) data = el
        return true
      })
      const dataUser = data?.data
      const messagesTab = []
      dataUser?.messages.map((el) => {
        if (el?.date !== datePost) {
          messagesTab.push(el)
        } else {
          const tempComments = []
          el?.comments.map(comment => {
            if (comment?.date !== dateComment) {
              tempComments.push(comment)
            }
            return true
          })
          const tempEl = el
          tempEl.comments = tempComments
          messagesTab.push(tempEl)
        }
        return true
      })
      const docRef = doc(db, 'events', idEvent)
      await updateDoc(docRef, { messages: messagesTab })
      return true
    }
  })
}

export const deletePostEvent = async (idEvent, date) => {
  return await getAllDataFromPath('events').then(async (res) => {
    if (res) {
      let data = null
      res.map(el => {
        if (el.id === idEvent) data = el
        return true
      })
      const dataUser = data?.data
      const messagesTab = []
      dataUser?.messages.map((el) => {
        if (el?.date !== date) {
          messagesTab.push(el)
        }
        return true
      })
      const docRef = doc(db, 'events', idEvent)
      await updateDoc(docRef, { messages: messagesTab })
      return true
    }
  })
}

export const addCommentToPost = async (idEvent, idPost, text) => {
  return await getAllDataFromPath('events').then(async (res) => {
    if (res) {
      let data = null
      res.map(el => {
        if (el.id === idEvent) data = el
        return true
      })
      const date = DateHelper.getFormattedDateByFormat(new Date(), 'HH:mm:ss, DD.MM.YYYY')
      const dataUser = data?.data
      let messagesTab = []
      messagesTab = dataUser.messages
      messagesTab.map(el => {
        if (el.date === idPost) {
          el.seePost = []
          el.seePost.push({
            email: LocalStorageHelper.get('user').email,
          })
          el.comments.push({
            date: date,
            email: LocalStorageHelper.get('user').email,
            name: LocalStorageHelper.get('user').name,
            text,
          })
        }
        return true
      })
      const docRef = doc(db, 'events', idEvent)
      await updateDoc(docRef, { messages: messagesTab })
      return true
    }
  })
}

export const deleteEvent = async (id) => {
  await deleteDoc(doc(db, 'events', id))
  await deleteDoc(doc(db, 'calendar', id))
  window.open('/events/deleteEvent', '_self')
}

export const deleteEventTournament = async (id) => {
  await deleteDoc(doc(db, 'tournament', id))
  await deleteDoc(doc(db, 'calendar', id))
  window.open('/events/deleteEvent', '_self')
}

export const deleteUserInEvent = async (id) => {
  return await getAllDataFromPath('events').then(async (res) => {
    if (res) {
      const user = LocalStorageHelper.get('user')
      let data = null
      res.map(el => {
        if (el.id === id) data = el
        return true
      })
      const dataUser = data.data
      let usersTab = []
      usersTab = dataUser.users
      const newTab = []
      let userToDelete = null
      usersTab.map(el => {
        if (el.email !== user.email) {
          newTab.push(el)
        } else {
          userToDelete = el
        }
        return true
      })
      const docRef = doc(db, 'events', id)
      let countSeat = 1
      if (userToDelete?.childs?.length > 0) {
        countSeat = countSeat + userToDelete?.childs?.length
      }
      await updateDoc(docRef, { users: newTab, seat: dataUser.seat - countSeat })
      window.open(`/profile/deleteAccount/${userToDelete?.childs?.length ? 'children' : ''}`, '_self')
    }
  })
}

export const deleteUserInTournament = async (id) => {
  return await getAllDataFromPath('tournament').then(async (res) => {
    if (res) {
      const user = LocalStorageHelper.get('user')
      let data = null
      res.map(el => {
        if (el.id === id) data = el
        return true
      })
      const dataUser = data.data
      let usersTab = []
      let userToDelete = null
      usersTab = dataUser.users
      const newTab = []
      usersTab.map(el => {
        if (el.email !== user.email) {
          newTab.push(el)
        } else {
          userToDelete = el
        }
        return true
      })
      const docRef = doc(db, 'tournament', id)
      let countSeat = 1
      if (userToDelete?.childs?.length > 0) {
        countSeat = countSeat + userToDelete?.childs?.length
      }
      await updateDoc(docRef, { users: newTab, seat: dataUser.seat - countSeat })
      window.open(`/profile/deleteAccount/${userToDelete?.childs?.length ? 'children' : ''}`, '_self')
    }
  })
}

export const addPostToEvent = async (id, text) => {
  return await getAllDataFromPath('events').then(async (res) => {
    if (res) {
      let data = null
      res.map(el => {
        if (el.id === id) data = el
        return true
      })
      const date = DateHelper.getFormattedDateByFormat(new Date(), 'HH:mm:ss, DD.MM.YYYY')
      const dataUser = data.data
      let messagesTab = []
      messagesTab = dataUser.messages
      messagesTab.push({
        date: date,
        email: LocalStorageHelper.get('user').email,
        name: LocalStorageHelper.get('user').name,
        text,
        seePost: [
          {
            email: LocalStorageHelper.get('user').email
          },
        ],
        comments: []
      })
      const docRef = doc(db, 'events', id)
      await updateDoc(docRef, { messages: messagesTab })
      return true
    }
  })
}

export const beOnEvent = async (e, id) => {
  await getAllDataFromPath('events').then(async (res) => {
    if (res) {
      let data = null
      res?.map(el => {
        if (el?.id === id) data = el
        return true
      })
      const users = data.data.users
      users.map(el => {
        if (el.name) {
          const checkName = StringHelper.toDisableCharsPL(el.name.toLowerCase().trim().replace(' ', ''))
          Object.keys(e).map((saveName) => {
            if (checkName === saveName) {
              const beOnEv = e[saveName]
              el.beOnEvent = beOnEv
            }
            return true
          })
        }
        return true
      })
      const docRef = doc(db, 'events', id)
      await updateDoc(docRef, { users: users })
      return true
    }
  })
  return true
}

export const addUserToEvent = async (id, user) => {
  return await getAllDataFromPath('events').then(async (res) => {
    if (res) {
      let data = null
      res?.map(el => {
        if (el?.id === id) data = el
        return true
      })
      const dataUser = data.data
      let usersTab = []
      if (dataUser?.seat < dataUser?.maxSeat) {
        usersTab = dataUser?.users
        let userNowExist = false
        usersTab.map(userExist => {
          if (userExist?.email === user?.email) userNowExist = true
          return true
        })
        if (!userNowExist) {
          usersTab?.push({
            email: user?.email,
            name: user?.name.trim(),
            phone: user?.phone,
            childs: user?.childs || false,
          })
        } else return false
      } else return false
      const docRef = doc(db, 'events', id)
      let countSeat = 1
      if (user?.childs?.length > 0) {
        countSeat = countSeat + user?.childs?.length
      }
      await updateDoc(docRef, { users: usersTab, seat: dataUser?.seat + countSeat })
      return true
    }
  })
}

export const addUserToTournament = async (id, user) => {
  return await getAllDataFromPath('tournament').then(async (res) => {
    if (res) {
      let data = null
      res?.map(el => {
        if (el?.id === id) data = el
        return true
      })
      const dataUser = data.data
      let usersTab = []
      if (dataUser?.seat < dataUser?.maxSeat) {
        usersTab = dataUser?.users
        let userNowExist = false
        usersTab.map(userExist => {
          if (userExist?.email === user?.email) userNowExist = true
          return true
        })
        if (!userNowExist) {
          usersTab?.push({
            email: user?.email,
            name: user?.name,
            phone: user?.phone,
            childs: user?.childs || false,
          })
        } else return false
      } else return false
      const docRef = doc(db, 'tournament', id)
      let countSeat = 1
      if (user?.childs?.length > 0) {
        countSeat = countSeat + user?.childs?.length
      }
      await updateDoc(docRef, { users: usersTab, seat: dataUser?.seat + countSeat })
      return true
    }
  })
}

export const addPromotionContact = async (e) => {
  const create = new Date()
  const sendData = {
    create,
    whoAdd: LocalStorageHelper.get('user').name.trim(),
    ...e,
    whoCreated: LocalStorageHelper.get('user').email,
  }
  return await updateOrCreateDocument('promotion', DateHelper.getFormattedDateByFormat(create, 'DD.MM.YYYY, HH:mm'), sendData).then((res) => res)
}

export const saveNewGame = async (sendData, id) => {
  return await updateOrCreateDocument('games', id, sendData).then((res) => res)
}

export const getBackgrounds = async () => {
  const storage = getStorage()
  const listRef = ref(storage, 'backgrounds')
  const images = []
  return listAll(listRef).then(async (res) => {
    return res?.items.map(async (item) => {
      await getDownloadURL(ref(storage, item._location.path_)).then((url) => {
        images.push(url)
      })
      return images
    })
  })
}

export const getAllFilesInGallery = async () => {
  const storage = getStorage()
  const listRef = ref(storage, 'gallery')
  const foldersWithImages = []
  await listAll(listRef).then((res) => {
    res.prefixes.forEach(async (folderRef) => {
      const folder = folderRef._location.path_
      foldersWithImages.push({ path: folder, images: [] })
      await listAll(folderRef).then(async (files) => {
        const images = files.items
        await images?.map(el => {
         getDownloadURL(ref(storage, el._location.path_)).then((url) => {
           foldersWithImages.map(fold => {
             if (fold.path === folder) fold.images.push(url)
             return true
           })
         })
        return true
        })
      })
    })
  })
  return foldersWithImages
}

export const uploadDataToStorage = (files, path, addMoreFiles = false) => {
  const file = !addMoreFiles && files[0]
  const storage = getStorage()
  const storageRef = ref(storage, `${path}/${file.name}`)
  return uploadBytes(storageRef, file, file?.type).then((snapshot) => getDownloadURL(snapshot?.ref).then((downloadURL) => downloadURL || ''))
}

export const uploadDataToUserStorage = (files) => {
  return uploadDataToStorage(files, `/users/${LocalStorageHelper.get('user').uid}`, null).then((res) => res)
}

export const getAllEvents = async (email = null) => {
  const data = []
  await getAllDataFromPath('events').then((res) => {
    if (res) {
      res.map(el => {
        if (el?.data) {
          if (email) el?.data?.users?.map(user => user?.email === email && data.push({ data: el?.data, id: el?.id }))
          else data.push({ data: el?.data, id: el?.id })
        } return true
      })
    }
  })
  return ArrayHelper.sortArrayBy(Object.values(data), 'id')
}

export const getAllTournaments = async (email = null) => {
  const data = []
  await getAllDataFromPath('tournament').then((res) => {
    if (res) {
      res.map(el => {
        if (el?.data) {
          if (email) el?.data?.users?.map(user => user?.email === email && data.push({ data: el?.data, id: el?.id }))
          else data.push({ data: el?.data, id: el?.id })
        } return true
      })
    }
  })
  return ArrayHelper.sortArrayBy(Object.values(data), 'id')
}

export const getAllCalendar = async (email = null) => {
  const data = []
  await getAllDataFromPath('calendar').then((res) => {
    if (res) {
      res.map(el => {
        if (el?.data) {
          if (email) el?.data?.users?.map(user => user?.email === email && data.push({ data: el?.data, id: el?.id }))
          else data.push({ data: el?.data, id: el?.id })
        } return true
      })
    }
  })
  return ArrayHelper.sortArrayBy(Object.values(data), 'id')
}

export const editEvent = async (data, id, event) => {
  const sendData = event
  sendData.child = data.child
  sendData.date = data.date
  sendData.description = data.description
  sendData.maxSeat = data.maxSeat
  sendData.name = data.name
  sendData.hours.end = data.hours.end
  sendData.hours.start = data.hours.start
  return await updateOrCreateDocument('events', id, sendData).then((res) => res)
}

export const editTournament = async (data, id, event) => {
  const sendData = event
  sendData.child = data.child
  sendData.date = data.date
  sendData.description = data.description
  sendData.maxSeat = data.maxSeat
  sendData.name = data.name
  sendData.hours.end = data.hours.end
  sendData.hours.start = data.hours.start
  return await updateOrCreateDocument('tournament', id, sendData).then((res) => res)
}

export const getDataFromEventsToCalendar = async () => {
  getAllEvents().then((res) => {
    if (res) {
      res.map(async el => {
        const data = el?.data
        const date = el?.data?.date?.split('.')
        const day = date[0]
        const month = date[1] - 1
        const year = date[2]
        const smallData = {
          name: data?.name,
          date: DateHelper.getFormattedDateByFormat(new Date(year, month, day), 'DD.MM.YYYY'),
          hours: {
            start: data?.hours?.start || '',
            end: data?.hours?.end || '',
          },
          type: data?.library ? 'library' : data?.type || 'home',
          child: data?.child || false,
          background: data?.background || false,
          description: data?.description || '',
        }
        setTimeout(async () => {
          await updateOrCreateDocument('calendar', el?.id, smallData).then((res) => res)
        }, 1000)
        return true
      })
    }
  })
  return true
}

const createEvent = async (data, path = 'events') => {
  const date = new Date(`${data?.date.split('-')[0]}, ${data?.date.split('-')[1]}, ${data?.date.split('-')[2]}`)
  const create = new Date()
  const address = `${data?.street} ${data?.house}${data?.apartment ? `/${data?.apartment}` : ''}`
  const sendData = {
    create,
    name: data?.name,
    background: data?.background || false,
    themedEvent: data?.themedEvent || false,
    type: data?.library ? 'library' : data?.type || 'home',
    date: DateHelper.getFormattedDateByFormat(date, 'DD.MM.YYYY'),
    day: DateHelper.getNameDay(date),
    hours: {
      start: data?.start || '',
      end: data?.end || '',
    },
    people: data?.people || false,
    child: data?.child || false,
    seat: 1,
    tournament: data?.tournament || false,
    address: data?.library ? 'Koszalińska Biblioteka Publiczna, Plac Polonii 1, Koszalin' : address,
    description: data?.description,
    maxSeat: data?.maxSeat,
    users: [
      {
        name: LocalStorageHelper.get('user').name,
        email: LocalStorageHelper.get('user').email,
        phone: LocalStorageHelper.get('user').phone,
      },
    ],
    messages: [],
    whoCreated: LocalStorageHelper.get('user').email,
  }
  const smallData = {
    create,
    name: data?.name,
    date: DateHelper.getFormattedDateByFormat(date, 'DD.MM.YYYY'),
    day: DateHelper.getNameDay(date),
    hours: {
      start: data?.start || '',
      end: data?.end || '',
    },
    type: data?.library ? 'library' : data?.type || 'home',
    child: data?.child || false,
    description: data?.description,
    tournament: data?.tournament || false,
    themedEvent: data?.themedEvent || false,
    background: data?.background || false,
  }
  await updateOrCreateDocument('calendar', DateHelper.getFormattedDateByFormat(create, 'DD.MM.YYYY, HH:mm'), smallData).then((res) => res)
  return await updateOrCreateDocument(path, DateHelper.getFormattedDateByFormat(create, 'DD.MM.YYYY, HH:mm'), sendData).then((res) => res)
}

export const createGame = async (data, path = 'games') => {
  const create = new Date()
  const sendData = {
    create,
    whoCreated: LocalStorageHelper.get('user').email,
    ...data,
  }
  return await updateOrCreateDocument(path, DateHelper.getFormattedDateByFormat(create, 'DD.MM.YYYY, HH:mm'), sendData).then((res) => res)
}

export const addTournament = async (data) => createEvent(data, 'tournament')

export const addEvents = async (data) => createEvent(data, 'events')

export const refreshToken = () => auth?.currentUser?.getIdToken()

export const loginUser = (email, password) => {
  const auth = getAuth()
  return signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
    return userCredential?.user
  }).catch((error) => console.error(error.code, error.message))
}

export default firebase
