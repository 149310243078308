import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import Event from '../../components/molecules/Event/Event'
import Dialog from '../../components/atoms/Dialog/Dialog'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {
  addEvents,
  addTournament,
  addUserToEvent,
  addUserToTournament, getAllDataFromPath,
  getAllEvents,
  getAllTournaments,
  getBackgrounds,
} from '../../firebase'
import { useSnackbar } from 'notistack'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import DateHelper from '../../helpers/DateHelper/DateHelper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrayHelper from '../../helpers/ArrayHelper/ArrayHelper'
import GTMSerivce from '../../services/GTMService/GTMSerivce'

const EventsPage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const [backgrounds, setBackgrounds] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const [openChild, setOpenChild] = useState(false)
  const [addEvent, setAddEvent] = useState(null)
  const [VIP, setVIP] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [events, setEvents] = useState(null)
  const [users, setUsers] = useState(null)
  const [tournaments, setTournaments] = useState(null)
  const [tournamentsEl, setTournamentsEl] = useState(null)
  const style = {
    root: {
      display: 'flex',
      marginTop: 10,
      padding: BreakpointsHelper.isMobile() ? '15px' : '15px 50px',
    },
    images: {
      width: '100%',
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '500px',
      width: '100%',
    },
  }
  const handleAdd = () => setAddEvent(true)
  const openEvent = (e) => {
    setCurrentEvent(e)
    setOpen(true)
  }
  const closeEvent = () => setOpen(false)
  const renderDialog = () => (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <Typography
          sx={{
            fontSize: '28px',
            textTransform: 'uppercase',
            fontWeight: '900',
          }}
        >
          Opis wydarzenia
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: currentEvent?.description }} />
        <Typography
          sx={{
            fontSize: '28px',
            textTransform: 'uppercase',
            fontWeight: '900',
          }}
        >
          Przyjdź z dzieckiem
        </Typography>
        <div>
          <p>
            Przyjście z dzieckiem wiąże się z tym, że rodzic (opiekun) zajmuje się w pełni dzieckiem, nie posiadamy opieki do dziecka. Zazwyczaj wydarzenia organizowane są dla dorosłych, lecz jeżeli dziecko da radę zagrać
            w trudniejsze tytuły to również poradzi sobie na spotkaniach. Dzieci w wieku 10+ zwykle radzą sobie na spotkaniach tematycznych.
          </p>
        </div>
      </Grid>
    </Grid>
  )
  const renderAddEvent = () => {
    const user = LocalStorageHelper.get('user')
    const formData = {
      elements: []
    }
    formData.elements.push({
      name: 'background',
      type: 'backgrounds',
      label: t.globals.inputs.backgrounds.label,
      data: backgrounds,
      value: backgrounds[0],
    })
    if (VIP?.length > 0) {
      formData.elements.push(
        {
          name: 'vipEvent',
          type: 'checkbox',
          defaultValue: true,
          label: 'Spotkanie VIP',
          validationType: 'string',
          disabled: user.role !== 'superadmin',
        },
      )
    } else {
      formData.elements.push(
        {
          name: 'userEvent',
          type: 'checkbox',
          defaultValue: true,
          label: 'Spotkanie prywatne',
          validationType: 'string',
          disabled: user.role !== 'superadmin',
        },
      )
    }
    if (user.role === 'admin' || user.role === 'superadmin') {
      formData.elements.push(
        {
          name: 'simpleEvent',
          type: 'checkbox',
          label: 'Spotkanie przy planszówkach',
          validationType: 'string',
        },
      )
      formData.elements.push(
        {
          name: 'themedEvent',
          type: 'checkbox',
          label: t.globals.inputs.thematic.label,
          validationType: 'string',
        },
      )
      formData.elements.push(
        {
          name: 'library',
          type: 'checkbox',
          label: t.globals.inputs.library.label,
          validationType: 'string',
        },
      )
      formData.elements.push(
        {
          name: 'castleEvent',
          type: 'checkbox',
          label: 'Pałacowe gry planszowe',
          validationType: 'string',
        },
      )
      formData.elements.push(
        {
          name: 'terrainEvent',
          type: 'checkbox',
          label: 'Gry terenowe',
          validationType: 'string',
        },
      )
      formData.elements.push(
        {
          name: 'tournament',
          type: 'checkbox',
          label: t.globals.inputs.tournament.label,
          validationType: 'string',
        },
      )
    }
    const usersItems = []
    users?.forEach(el => {
      const data = el?.data
      usersItems.push({ value: data?.email, label: data?.name })
    })
    ArrayHelper.deleteDuplicate(usersItems)
    console.log(usersItems)
    formData.elements.push(
      {
        name: 'child',
        type: 'checkbox',
        label: t.globals.inputs.child.label,
        validationType: 'string',
      },
      {
        name: 'name',
        type: 'text',
        label: t.globals.inputs.nameEvent.label,
        value: 'Spotkanie planszówkowe',
        validationType: 'string',
      },
      {
        name: 'date',
        type: 'date',
        value: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
        label: t.globals.inputs.date.label,
        validationType: 'string',
      },
      {
        name: 'start',
        type: 'hour',
        value: '09:00',
        label: t.globals.inputs.start.label,
        validationType: 'string',
      },
      {
        name: 'end',
        type: 'hour',
        value: '17:00',
        label: t.globals.inputs.end.label,
        validationType: 'string',
      },
      {
        name: 'street',
        type: 'text',
        label: t.globals.inputs.street.label,
        value: user?.street || '',
        validationType: 'string',
      },
      {
        name: 'house',
        type: 'text',
        label: t.globals.inputs.house.label,
        value: user?.house || '',
        validationType: 'string',
      },
      {
        name: 'apartment',
        type: 'text',
        label: t.globals.inputs.apartment.label,
        value: user?.apartment || '',
        validationType: 'string',
        required: false,
      },
      {
        name: 'maxSeat',
        type: 'number',
        label: t.globals.inputs.maxSeat.label,
        validationType: 'string',
      },
      {
        name: 'description',
        type: 'editor',
        label: t.globals.inputs.editor.label,
        validationType: 'string',
      },
      {
        name: 'people',
        type: 'autocomplete',
        multiple: true,
        label: 'Zaproś tylko wybrane osoby na wydarzenie',
        helperText: 'Zaproś min. 2 osoby',
        options: usersItems,
        onChange: (e) => setVIP(e),
        required: false,
      },
      {
        type: 'button',
        value: 'Zapisz nowe wydarzenie',
      },
    )
    const onSubmit = async (e) => {
      if (e?.tournament) {
        addTournament(e).then(async (res) => {
          if (res) {
            enqueueSnackbar(t.eventsPage.toasts.success, { variant: 'success' })
            setTimeout(() => props.location.history.push('/profile/addEvent'), 500)
          } else {
            enqueueSnackbar(t.eventsPage.toasts.error, { variant: 'error' })
            setAddEvent(false)
          }
        })
      } else {
        addEvents(e).then(async (res) => {
          if (res) {
            enqueueSnackbar(t.eventsPage.toasts.success, { variant: 'success' })
            setTimeout(() => props.location.history.push('/profile/addEvent'), 500)
          } else {
            enqueueSnackbar(t.eventsPage.toasts.error, { variant: 'error' })
            setAddEvent(false)
          }
        })
      }
    }
    return <FormGenerator submit={onSubmit} data={formData} {...props}/>
  }
  const addToEventWithGroup = () => {
    closeEvent()
    if (!openChild) {
      setOpenChild(true)
    }
  }
  const addToEvent = (childs = null) => {
    const idEvent = currentEvent?.id
    const user = LocalStorageHelper.get('user')
    setOpen(false)
    setIsLoading(true)
    if (currentEvent?.tournament) {
      const currentDate = currentEvent?.date
      getAllTournaments().then((res) => {
        let isExist = false
        res?.forEach(el => {
          if (el?.data?.date === currentDate) {
            el?.data?.users?.forEach(user => {
              if (user?.name.trim() === LocalStorageHelper.get('user')?.name.trim()) {
                isExist = true
              }
            })
          }
        })
        if (!isExist) {
          if (childs?.length > 0) {
            user.childs = childs
          }
          addUserToTournament(idEvent, user).then(async (res) => {
            if (res) {
              updateEvents()
              GTMSerivce.addToEvent(user)
              props.location.history.push(`/previewEvent/${idEvent}/tournament`)
            } else {
              await enqueueSnackbar(t.eventsPage.toasts.error, { variant: 'error' })
              updateEvents()
            }
          })
        } else {
          updateEvents()
          enqueueSnackbar('Już istniejesz w innym turnieju', { variant: 'error' })
        }
        setIsLoading(false)
      })
    } else {
      if (childs?.length > 0) {
        user.childs = childs
      }
      addUserToEvent(idEvent, user).then(async (res) => {
        if (res) {
          updateEvents()
          GTMSerivce.addToEvent(user)
          props.location.history.push(`/previewEvent/${idEvent}/addToEvent`)
        } else {
          await enqueueSnackbar(t.eventsPage.toasts.error, { variant: 'error' })
          updateEvents()
        }
        setIsLoading(false)
      })
    }
  }
  const updateEvents = () => {
    const allElementsEvents = getAllEvents()
    const allElementsTournaments = getAllTournaments()
    allElementsEvents.then((res) => {
      if (res) {
        res?.forEach((ev) => {
          const date = ev.data.date
          const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD/MM/YYYY')
          ev.dayToEvent = DateHelper.getNumberOfDays(`${dateToday.split('/')[1]}/${dateToday.split('/')[0]}/${dateToday.split('/')[2]}`, `${date.split('.')[1]}/${date.split('.')[0]}/${date.split('.')[2]}`)
        })
        res.sort((x, y) => x.dayToEvent - y.dayToEvent)
        setEvents(res)
        if (!allElementsTournaments) setIsLoading(false)
      }
    })
    allElementsTournaments.then((res) => {
      if (res) {
        res?.forEach((ev) => {
          const date = ev.data.date
          const dateToday = DateHelper.getFormattedDateByFormat(new Date(), 'DD/MM/YYYY')
          ev.dayToEvent = DateHelper.getNumberOfDays(`${dateToday.split('/')[1]}/${dateToday.split('/')[0]}/${dateToday.split('/')[2]}`, `${date.split('.')[1]}/${date.split('.')[0]}/${date.split('.')[2]}`)
        })
        res.sort((x, y) => x.dayToEvent - y.dayToEvent)
        setTournaments(res)
        let countTournaments = 0
        tournaments?.forEach((el, index) => {
          const dayToEvent = DateHelper.getDaysToEvent(el)
          if (dayToEvent >= 0) {
            countTournaments++
          }
        })
        setTournamentsEl(countTournaments)
        setIsLoading(false)
      }
    })
  }
  const updateUsers = () => {
    getAllDataFromPath('users').then((res) => {
      if (res) {
        setUsers(res)
      }
    })
  }
  const userExistInEvent = (e) => {
    if (e?.users?.length > 0) {
      let exist = false
      e?.users?.forEach(el => {
        if (el.email === LocalStorageHelper.get('user').email) exist = true
      })
      return exist
    }
  }
  const existUserInItem = (item) => {
    let returnData = false
    const name = LocalStorageHelper.get('user')?.name
    name && item?.users?.forEach(el => {
      if (el?.name?.toLowerCase() === name?.toLowerCase()) returnData = true
    })
    return returnData
  }
  const formData = {
    elements: [
      {
        type: 'autocomplete',
        name: 'childs',
        options: [],
        multiple: true,
        limitTags: 4,
        helperText: 'Wpisz imię i nazwisko i kliknij Enter, aby dodać dziecko. Możesz dodać wiele dzieci jednocześnie. Maksymalnie 4 dzieci na jednego dorosłego.',
        label: 'Wpisz imiona i nazwiska dzieci',
        validationType: 'array',
      },
      {
        type: 'button',
        value: 'Dodaj siebie z dziećmi',
      },
    ]
  }
  const addChildWithMe = (e) => {
    const childsArray = e.childs
    let errors = false
    if (childsArray?.length === 0) {
      enqueueSnackbar('Nie dodano wcale dzieci, dodaj następnie wybierz przycisk.', { variant: 'error' })
      errors = true
    } else {
      childsArray?.forEach(el => {
        if (!(el?.split(' ')?.length > 1)) {
          enqueueSnackbar(`Dziecko ${el} nie posiada podanego imienia i nazwiska.`, { variant: 'warning' })
          errors = true
        }
      })
      if (!errors) {
        const goAndSave = setTimeout(async () => {
          setOpenChild(false)
          await addToEvent(childsArray)
          props.location.history.push('/profile/addEvent')
        }, 1000)
        return () => clearTimeout(goAndSave)
      }
    }
  }
  useEffect(() => {
    updateEvents()
    if (window.location.pathname.search('deleteEvent') > 0) enqueueSnackbar('Poprawnie usunięto wydarzenie.', { variant: 'success' })
    getBackgrounds().then(res => {
      if (res?.length > 0) {
        res[0]?.then((response) => {
          if (response) setBackgrounds(response)
        })
      }
    })
    updateUsers()
  }, [])
  return (
      <>
        <Grid container style={style.root}>
          <Grid item md={12} xs={12}>
            <>
              {
                isLoading
                  ? (
                    <div style={style.loader}>
                      <CircularProgress />
                    </div>
                  )
                  : (
                    <>
                      <Grid container spacing={4}>
                        <Grid
                          item
                          md={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
                            width: '100%',
                            minHeight: '70px',
                            flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row',
                          }}
                        >
                          <>
                            <Typography sx={{ paddingRight: '50px', width: BreakpointsHelper.isMobile() ? '100%' : '80%' }}>
                              {t.eventsPage.descriptionArchive}
                            </Typography>
                            <Button variant="contained" sx={{ maxWidth: '320px', marginTop: BreakpointsHelper.isMobile() && '10px' }} onClick={() => handleAdd()}>{t.eventsPage.createEvent}</Button>
                          </>
                        </Grid>
                        {
                          tournaments && (
                            <>
                              <Grid item xs={12}>
                                {
                                  tournamentsEl !== 0 && (
                                    <Typography sx={{ marginBottom: '32px', fontWeight: '600', fontSize: '22px' }}>
                                      Turnieje planszówkowe
                                    </Typography>
                                  )
                                }
                                <Box sx={{ marginBottom: '20px' }}>
                                  <Grid container spacing={4}>
                                    {
                                      tournaments?.map((el, index) => {
                                        const dayToEvent = DateHelper.getDaysToEvent(el)
                                        if (dayToEvent >= 0) {
                                          const item = el?.data
                                          return (
                                            <Grid
                                              key={index}
                                              item
                                              lg={4}
                                              xs={12}
                                              sx={{
                                                display: 'flex',
                                                width: '100%',
                                              }}
                                            >
                                              <Event
                                                item={item}
                                                whoCreated={item?.whoCreated}
                                                open={existUserInItem(item)}
                                                id={el?.id}
                                                street={item?.street}
                                                house={item?.house}
                                                apartment={item?.apartment}
                                                background={item?.background}
                                                name={item?.name}
                                                themedEvent={item?.themedEvent}
                                                type={item?.type}
                                                child={item?.child}
                                                date={item?.date}
                                                tournament={item?.tournament}
                                                day={item?.day}
                                                hours={item?.hours}
                                                seat={item?.seat}
                                                users={item?.users}
                                                address={item?.address}
                                                maxSeat={item?.maxSeat}
                                                description={item?.description}
                                                categories={item?.categories}
                                                setCurrentEvent={(e) => !userExistInEvent(e) ? openEvent(e) : window.open(`/previewEvent/${e?.id}/tournament`, '_self')}
                                                {...props}
                                              />
                                            </Grid>
                                          )
                                        }
                                        return ''
                                      })
                                    }
                                  </Grid>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography sx={{ fontWeight: '600', fontSize: '22px' }}>
                                  Wydarzenia planszówkowe
                                </Typography>
                              </Grid>
                            </>
                          )
                        }
                        {
                          events?.length > 0
                            ? events?.map((el, index) => {
                              const dayToEvent = DateHelper.getDaysToEvent(el)
                              const item = el?.data
                              let showEvent = false
                              let eventVIP = false
                              if (dayToEvent >= 0) {
                                if (item?.people?.length > 0) {
                                  item?.people?.forEach(el => {
                                    if (el === LocalStorageHelper.get('user')?.name) {
                                      showEvent = true
                                      eventVIP = true
                                    }
                                  })
                                } else showEvent = true
                                if (LocalStorageHelper.get('user')?.role === 'superadmin' || LocalStorageHelper.get('user')?.admin) {
                                  showEvent = true
                                }
                                if ((item?.people?.length > 0 && showEvent) || showEvent) {
                                  return (
                                    <Grid
                                      key={index}
                                      item
                                      lg={4}
                                      xs={12}
                                      sx={{
                                        display: 'flex',
                                        width: '100%',
                                      }}
                                    >
                                      <Event
                                        item={item}
                                        whoCreated={item?.whoCreated}
                                        open={existUserInItem(item)}
                                        id={el?.id}
                                        eventVIP={eventVIP}
                                        street={item?.street}
                                        house={item?.house}
                                        apartment={item?.apartment}
                                        background={item?.background}
                                        name={item?.name}
                                        themedEvent={item?.themedEvent}
                                        type={item?.type}
                                        child={item?.child}
                                        users={item?.users}
                                        date={item?.date}
                                        day={item?.day}
                                        hours={item?.hours}
                                        seat={item?.seat}
                                        gamers={item?.users}
                                        address={item?.address}
                                        maxSeat={item?.maxSeat}
                                        description={item?.description}
                                        categories={item?.categories}
                                        setCurrentEvent={(e) => !userExistInEvent(e) ? openEvent(e) : window.open(`/previewEvent/${e?.id}`, '_self')}
                                        {...props}
                                      />
                                    </Grid>
                                  )
                                } else {
                                  return ''
                                }
                              }
                              return true
                            })
                            : (
                              <Grid item lg={6} md={12}>
                                <Typography
                                  sx={{
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                  }}
                                >
                                  {t.eventsPage.noEvents}
                                </Typography>
                              </Grid>
                            )
                        }
                      </Grid>
                    </>
                  )
              }
              {
                open && (
                  <Dialog
                    open={open}
                    title={t.eventsPage.chooseToAdd}
                    content={renderDialog()}
                    buttons={!userExistInEvent() && [
                      { link: () => addToEventWithGroup(), name: 'Przyjdź z dzieckiem / dziećmi' },
                      { link: () => addToEvent(), name: t.eventsPage.addOnes }
                    ]}
                    onClose={closeEvent}
                  />
                )
              }
              {
                openChild && formData && (
                  <Dialog
                    open={openChild}
                    title={t.eventsPage.chooseToAdd}
                    content={(
                      <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                          <FormGenerator data={formData} submit={(e) => addChildWithMe(e)} />
                        </Grid>
                      </Grid>
                    )}
                    onClose={() => setOpenChild(false)}
                  />
                )
              }
            </>
          </Grid>
        </Grid>
        {
          addEvent && VIP && (
            <Dialog
              open={addEvent}
              title={t.eventsPage.createEvent}
              content={users && VIP && renderAddEvent()}
              onClose={() => setAddEvent(false)}
            />
          )
        }
      </>
  )
}

export default EventsPage
